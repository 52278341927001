import React, { useState, useEffect } from 'react'
import 'firebase/firestore'
import Footer from '../layout/Footer'


export default function ReportPosting() {

  const [outerMargins, setOuterMargins] = useState('12')
  const [greenOutlineForm, setGreenOutlineForm] = useState('')
  const [postingId, setPostingId] = useState('')
  const [description, setDescription] = useState('')

  const handleSubmit = () => {
    
  }

  return (
    <div>
      <div style={{ width: `${100 - outerMargins*2}%`, marginTop: -50, paddingLeft: `${outerMargins}%`, paddingRight: `${outerMargins}%`, paddingTop: 40, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 20, textAlign: 'center', lineHeight: 1.5, maxWidth: 900, margin: '50px 0px 0px 0px' }}>Report Posting: Ensuring a Safe and Respectful Community at The Hourly Worker</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 20, textAlign: 'center', lineHeight: 1.5, maxWidth: 900, margin: '10px 0px 0px 0px' }}>Reporting Inappropriate Content for a Positive User Experience</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 18, lineHeight: 1.5, maxWidth: 900, margin: '30px 0px 0px 0px' }}>At The Hourly Worker, we are committed to maintaining a safe and respectful community for all our users. We understand that there may be instances where you come across postings that are inappropriate, offensive, or spammy. We highly appreciate your vigilance in helping us uphold our community standards by reporting such content. Your active participation in keeping our platform clean and professional is crucial in creating a positive user experience for everyone.</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 18, lineHeight: 1.5, maxWidth: 900, margin: '30px 0px 0px 0px' }}>To report a posting that violates our guidelines, please use the "Report" feature available on the posting page. When making a report, please provide as much detail as possible about the issue you're reporting, including any relevant screenshots or evidence. Our team will review your report promptly and take appropriate action to ensure that our platform remains a trusted and reliable place for job postings. We value your contribution in maintaining the integrity of our community, and we thank you for your efforts in making The Hourly Worker a safe and respectful platform for all users.</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, lineHeight: 1.5, maxWidth: 900, margin: '30px 0px 0px 0px' }}>Posting #</p>
        <input onChange={(e) => setPostingId(e.target.value)} onFocus={() => setGreenOutlineForm('id')} onBlur={() => setGreenOutlineForm('')} type="text" style={{ width: `${100 - outerMargins * 3}%`, margin: '10px 0px 0px 0px', height: 45, fontFamily: 'Inter', fontWeight: greenOutlineForm === 'id' ? 700 : 500, backgroundColor: 'white', borderRadius: 10, borderWidth: 2, borderColor: greenOutlineForm === 'id' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid', textAlign: 'center', justifyContent: 'center', alignItems: 'center', fontSize: 20 }} />
        <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, lineHeight: 1.5, maxWidth: 900, margin: '25px 0px 0px 0px' }}>Description</p>
        <textarea onChange={(e) => setDescription(e.target.value)} onFocus={() => setGreenOutlineForm('desc')} onBlur={() => setGreenOutlineForm('')} style={{ width: `${100 - outerMargins * 3}%`, margin: '10px 0px 0px 0px', height: 250, fontFamily: 'Inter', fontWeight: 400, fontSize: 20, backgroundColor: 'white', borderRadius: 10, padding: '10px 15px 0px 15px', borderWidth: 2, borderColor: greenOutlineForm === 'desc' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid' }} />
     
        <button onClick={handleSubmit} disabled={postingId === '' || description === ''} onMouseEnter={() => setGreenOutlineForm('submit')} onMouseLeave={() => setGreenOutlineForm('')}
          style={{ marginTop: 10, width: 155, height: 40, cursor: greenOutlineForm === 'submit' ? 'pointer' : 'default', backgroundColor: (postingId === '' || description === '') ? 'lightgrey' : '#378805', margin: "20px 0px 30px 0px", borderRadius: 400, borderWidth: 0, color: 'white', fontFamily: 'Inter', fontWeight: 600, fontSize: 18 }}>Submit Report
        </button>
      
      </div>
      <Footer />
    </div>
  )
}
