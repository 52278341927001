import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import 'firebase/firestore'
import { Redirect } from 'react-router-dom'
import logo from '../../logo.png'
import Icon from '@mdi/react'
import { mdiClose, mdiDelete, mdiPencil, mdiCheckBold  } from '@mdi/js';
import { Link } from 'react-router-dom'
import Footer from '../layout/Footer'


export default function Login() {

  const haversine = require('haversine')

  const [loggedIn, setLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState(false)
  const [isHoveringPost, setIsHoveringPost] = useState(false)
  const [posts, setPosts] = useState('')
  const [isHoveringClose, setIsHoveringClose] = useState(false)
  const [openedPosting, setOpenedPosting] = useState('')
  const [outerMargins, setOuterMargins] = useState('12')
  const [recentlyDeleted, setRecentlyDeleted] = useState(0)
  const [isHovering, setIsHovering] = useState('')
  const [accountInformation, setAccountInformation] = useState({})
  const [editName, setEditName] = useState(false)
  const [contactName, setContactName] = useState('Account Name')
  const [showRepostOptions, setShowRepostOptions] = useState(false)
  const [doNotClose, setDoNotClose] = useState(false)
  const [hoveringRepost, setHoveringRepost] = useState('')

  const [currentLat, setCurrentLat] = useState('no-location-permissions')
  const [currentLng, setCurrentLng] = useState('no-location-permissions')

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        setCurrentLat(position.coords.latitude)
        setCurrentLng(position.coords.longitude)
      }, (error) => {
        setCurrentLat('error-latitude')
        setCurrentLng('error-longitude')
      })
    }
  }, [loaded])

  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])

  useEffect(() => {
    try {
      if (loaded) {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get().then(doc => {
          setAccountInformation({ ...doc.data(), id: doc.id })
        }).then(() => console.log('ACCOUNT: ', accountInformation))
      }
    } catch (err) {
      console.log(err)
    }
  }, [loaded])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
    })
  }, [])

  useEffect(() => {
    if (loggedIn) {
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get().then(doc => {
        if(doc.data().contactName != undefined && doc.data().contactName !== '') {
          setContactName(doc.data().contactName)
        }
        console.log(contactName, doc.data().contactName)
      }).catch(err => console.log('No account name yet...', console.log(err.message)))
    }
  }, [loggedIn])

  const uploadNewName = () => {
    firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
      contactName
    }).then(() => {
      setEditName(false)
    })
  }

  const [atLeastOneHiddenPost, setAtLeastOneHiddenPost] = useState(false)

  const [sectionHovering, setSectionHovering] = useState('')

  //Load in postings, only load ones posted by user
  useEffect(() => {
    try {
      setAtLeastOneHiddenPost(false)
      firebase.firestore().collection('projects').get().then(querySnapshot => {
        const postData = []
        querySnapshot.forEach(doc => {
          /*
            Determines if a post is expired or not
          */
          let currentDate = new Date()
          console.log('currentDate', currentDate)

          let firebaseDate = new Date(1970, 0, 1)
          firebaseDate.setSeconds(doc.data().creation.seconds)
          console.log('firebaseDate', firebaseDate)

          let timeDifference = currentDate.getTime() - firebaseDate.getTime()

          let daysDifference = timeDifference / (1000 * 60 * 60 * 24)

          console.log('Days Difference:', daysDifference)

          if (daysDifference <= parseInt(doc.data().postDuration)) {
            console.log('This Document is not expired')
            if (doc.data().user === firebase.auth().currentUser.uid) {
              postData.push({ ...doc.data(), id: doc.id, expired: false })
              if (doc.data().hidden) {
                setAtLeastOneHiddenPost(true)
              }
            }
          } else {
            //Do not show on Dashboard, but Show on Account Screen with a notice saying its expired
            console.log('This document is expired')
            if (doc.data().user === firebase.auth().currentUser.uid) {
              postData.push({ ...doc.data(), id: doc.id, expired: true })
            }
          }
        })
        setPosts(postData)
      })
        
      //   onSnapshot(snapshot => {
      //   const postData = []
      //   snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }))
      //   setPosts(postData)
      // }).then(() => console.log('posts'))
      console.log('hi')
    } catch (err) {
      console.log('345432', err)
    }
  }, [recentlyDeleted])

  const tryToClose = () => {
    if (!doNotClose) {
      setOpenedPosting('')
      setShowRepostOptions(false)
    } else if (isHoveringClose) {
      setOpenedPosting('')
      setDoNotClose(false)
      setIsHoveringClose(false)
      setShowRepostOptions(false)
    }
  }

  const updateCreation = (itemsID, length) => {
    firebase.firestore().collection('projects').doc(itemsID).update({
      creation: firebase.firestore.FieldValue.serverTimestamp(),
      postDuration: length
    }).then(() => {
      setDoNotClose(false)
      setOpenedPosting('')
      setIsHoveringClose(false)
      setRecentlyDeleted(recentlyDeleted + 1)
      setShowRepostOptions(false)
    })
  }

  const hidePost = (hideID, alreadyHidden) => {
    firebase.firestore().collection('projects').doc(hideID).update({
      hidden: alreadyHidden ? false : true
    }).then(() => {
      setRecentlyDeleted(recentlyDeleted + 1)
    })
  }

  const getCurrentPostings = () => {
    //on click of an element it makes it almost full screen and you can see all the details and sign up!
    if (posts.length === 0 || posts == undefined || posts === null) {
      return (
        <div style={{ height: 120, backgroundColor: 'rgba(255, 0, 0, .08)', width: `${100}%`, padding: 0, marginBottom: 200, borderRadius: 10 }}>
          <p style={{ fontFamily: 'Inter', fontWeight: 600, color: 'rgba(255,0,0,.8)', fontSize: 20, padding: 0, margin: screenSize !== 'Extra small' ? 0 : '30px 10px 0 10px', lineHeight: screenSize !== 'Extra small' ? '120px' : 1.5, textAlign: 'center' }}>{screenSize !== 'Extra small' ? 'You have no postings.  Click "Post" to create one.' : 'You have no postings.  Go to the Post Page to create one.'}</p>
        </div>
      )}

    return Array.from(posts).map(item => (
    <div>
        {openedPosting === item.id ? (
          <div>
            <div  onClick={() => tryToClose()}  style={{ position: 'fixed', padding: 0, margin: 0, top: 0, height: '100%', left: 0, width: '100%', background: 'rgba(0,0,0,.4)', zIndex: 4}}>
        <div onMouseEnter={() => setDoNotClose(true)} onMouseLeave={() => setDoNotClose(false)} style={{ marginRight: 30, width: screenSize === 'Large' ? '40vw' : screenSize === 'Medium' ? '50vw' : screenSize === 'Small' ? '70vw' : '80vw', height: item.description.length < 100 ? 'auto' : '60vh', position: 'absolute', backgroundColor: 'white', overflowY: item.description.length < 100 ? 'hidden' : 'scroll', overflowX: 'hidden', top: '15vh', left: screenSize === 'Large' ? '32.5vw' : screenSize === 'Medium' ? '25vw' : screenSize === 'Small' ? '12vw' : '9vw', borderRadius: 15, zIndex: 6 }} onClick={() => setOpenedPosting(item.id)}> 
          {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
                  <div style={{ width: '100%', backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center', height: 225, borderTopLeftRadius: 15, borderTopRightRadius: 15}} />
          ):
            <div style={{ width: '100%', height: 225, borderTopLeftRadius: 15, borderTopRightRadius: 15, backgroundColor: '#ededed' }} />
                }
                <Icon path={mdiClose} onMouseEnter={() => setIsHoveringClose(true)} onMouseLeave={() => setIsHoveringClose(false)} title='Close' size={1} style={{ padding: 3, color: 'black', backgroundColor: 'white', cursor: isHoveringClose ? 'pointer' : 'cursor', borderRadius: 24, position: 'absolute', right: 10, top: 10}} />
                <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0, marginLeft: `8%`, marginRight: '25%' }}><span style={{ color: 'red' }}>{item.hidden && ('Hidden: ')}</span>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, marginLeft: `8%` }}>${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`} &#183; {item.address}</p>
                {item.description === '' ? (<div />) : <p style={{ fontFamily: 'Inter', fontWeight: 400, marginLeft: `8%`, marginRight: `8%`, marginBottom: 10 }}>Job Description: {item.description}</p>}

                <div style={{ width: screenSize === 'Large' ? '34vw' : screenSize === 'Medium' ? '42vw' : screenSize === 'Small' ? '60vw' : '68vw', backgroundColor: 'white', marginLeft: `8%`, marginTop: 0, display: 'flex', flexDirection: (item.businessCity === '' && item.businessState === '' && item.businessStreet === '' && item.businessZip === '') ? 'column' : 'row', justifyContent: 'space-between' }}>

                  <div style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 12, lineHeight: 1, marginTop: 0, color: 'rgba(0,0,0,.4)', textAlign: 'left' }}>
                    <p>{item.contactName}</p>
                      <p>{item.contactPhone}</p>
                      <p>{item.contactEmail}</p>
                      <p>Employment: {item.fullTime}</p>
                      {item.tips && (<p>Tips</p>)}
                    <br></br>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <p style={{ marginLeft: '8%', fontFamily: 'Inter', fontWeight: 800, color: 'rgba(0,0,0,.13)', marginBottom: 30 }}>Posting #: <span style={{ color: 'rgba(0,0,0,.13)', fontWeight: 600 }}>THW{item.id}</span></p>
                  <p onClick={() => hidePost(item.id, item.hidden)} onMouseEnter={() => setHoveringRepost('hide')} onMouseLeave={() => setHoveringRepost('')} style={{ marginRight: '8%', fontFamily: 'Inter', fontWeight: 600, color: item.hidden ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,.5)', marginBottom: 30, cursor: hoveringRepost === 'hide' ? 'pointer' : 'default' }}>{item.hidden ? 'Unhide Post' : 'Hide Post'}</p>
                </div>
                  {item.expired && (
                  <div>
                    <div onMouseEnter={() => setHoveringRepost('title')} onMouseLeave={() => setHoveringRepost('')} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 176, right: '15%', color: 'rgba(0,0,0,.3)', cursor: hoveringRepost === 'title' ? 'pointer' : 'default' }} onClick={() => setShowRepostOptions(!showRepostOptions)}>
                      <p style={{ margin: 0, fontFamily: 'Inter', fontWeight: 600, fontSize: 16 }}>Repost</p>
                    </div>
                    {showRepostOptions && (
                      <div style={{ position: 'absolute', top: 200, right: '15%', backgroundColor: 'lightgrey', padding: 3, borderColor: 'grey', borderWidth: .5, borderStyle: 'solid' }}>
                        <p onClick={() => updateCreation(item.id, "7")} onMouseEnter={() => setHoveringRepost('7')} onMouseLeave={() => setHoveringRepost('')} style={{ margin: 0, fontFamily: 'Inter', fontWeight: 400, fontSize: 16, cursor: hoveringRepost === '7' ? 'pointer' : 'default'}}>7 Days</p>
                        <p onClick={() => updateCreation(item.id, "30")} onMouseEnter={() => setHoveringRepost('30')} onMouseLeave={() => setHoveringRepost('')} style={{ margin: 0, fontFamily: 'Inter', fontWeight: 400, fontSize: 16, cursor: hoveringRepost === '30' ? 'pointer' : 'default' }}>30 Days</p>
                        <p onClick={() => updateCreation(item.id, "90")} onMouseEnter={() => setHoveringRepost('90')} onMouseLeave={() => setHoveringRepost('')} style={{ margin: 0, fontFamily: 'Inter', fontWeight: 400, fontSize: 16, cursor: hoveringRepost === '90' ? 'pointer' : 'default' }}>90 Days</p>
                        <p onClick={() => updateCreation(item.id, "180")} onMouseEnter={() => setHoveringRepost('180')} onMouseLeave={() => setHoveringRepost('')} style={{ margin: 0, fontFamily: 'Inter', fontWeight: 400, fontSize: 16, cursor: hoveringRepost === '180' ? 'pointer' : 'default' }}>180 Days</p>
                      </div>
                    )}
                  </div>
                )}
                <Link to={{ pathname: '/post', editPost: true, postID: item.id}} style={{ textDecoration: 'none'}}>
                  <div style={{ position: 'absolute', zIndex: 15, backgroundColor: 'white', width: 30, height: 30, top: 240, right: '15%' }}>
                    <Icon path={mdiPencil} onMouseEnter={() => setIsHoveringClose(true)} onMouseLeave={() => setIsHoveringClose(false)} title='Edit' size={'24px'} style={{ padding: 3, color: 'black', backgroundColor: 'white', cursor: isHoveringClose ? 'pointer' : 'cursor', borderRadius: 24, }} />
                  </div>
                </Link>
                <div style={{ position: 'absolute', zIndex: 15, backgroundColor: 'white', width: 30, height: 30, top: 240, right: '6%' }} onClick={() => firebase.firestore().collection('projects').doc(item.id).delete().then(() => setRecentlyDeleted(recentlyDeleted + 1))}>
                  <Icon path={mdiDelete} onMouseEnter={() => setIsHoveringClose(true)} onMouseLeave={() => setIsHoveringClose(false)} title='Delete' size={'24px'} style={{ padding: 3, color: 'black', backgroundColor: 'white', cursor: isHoveringClose ? 'pointer' : 'cursor', borderRadius: 24, }} />
                </div>
                
              </div >
            </div>
            {/* Copied below so that all the postings don't shift when you open one up */}

      <div style={{ marginRight: 30, width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw',  }} onClick={() => setOpenedPosting(item.id)}> 
        {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
          <div style={{  width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center', }} />
        ):
          <div style={{ width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundColor: '#ededed' }} />
        }
              <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0 }}><span style={{ color: 'red' }}><span style={{ color: 'red' }}>{item.expired && 'Expired: '}</span>{item.hidden && ('Hidden: ')}</span>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, paddingBottom: 30 }}>{haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) === 'NaN' ? item.contactName : haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) + "mi"} &#183; ${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
      </div >
        </div>
      ) :
          <div style={{ marginRight: 30, position: 'relative',width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', backgroundColor: (isHoveringPost === item.id) ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,0)', cursor: isHoveringPost === item.id ? 'pointer' : 'default', borderRadius: 5 }} onMouseEnter={() => { setIsHoveringPost(item.id) }} onMouseLeave={() => { setIsHoveringPost(false);   setSectionHovering('')}}
            onClick={() => {setOpenedPosting(item.id); setIsHoveringPost(false);}}> 
            {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
              <div style={{  width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center',}} />
        ):
          <div style={{ width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundColor: '#ededed' }} />
            }
        <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0}}><span style={{ color: 'red' }}><span style={{ color: 'red' }}>{item.expired && 'Expired: '}</span>{item.hidden && ('Hidden: ')}</span>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
            <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, paddingBottom: 30 }}>{haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) === 'NaN' ? item.contactName : haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) + "mi"} &#183; ${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
            
          
          </div >
        }
    </div>
    ))
  }


  // useEffect(() => {
  //   try {
  //     if (firebase.auth().currentUser.uid) {
  //       setLoggedIn(false)
  //       setLoaded(true)
  //     } else {
  //       setLoggedIn(false)
  //       setLoaded(true)
  //     }
  //   } catch (err) {
  //     setLoggedIn(false)
  //     setLoaded(true)
  //   }
  //   console.log('loggedIn', loggedIn)
  // }, [])

  if (!loaded) return null
  
  if (!loggedIn) {
    return (
      <div>
        <Redirect to='/' />
        <p>Not logged in</p>

      </div>
    )
  }

    return ( 
      <div>
              <head>
                  <link rel="preconnect" href="https://fonts.googleapis.com"/><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
      </head>
        
        {/* Page Content: */}
        <div style={{ marginLeft: `${outerMargins}%`, marginRight: `${outerMargins}%`, display: 'flex', flexDirection: 'column' }}>

          {/* NOTICE FORMAT */}
          {atLeastOneHiddenPost && (
            <div style={{ flex: 1, backgroundColor: 'rgba(0,255,0,.1)', borderColor: 'green', borderWidth: 1, borderStyle: 'solid', marginTop: 20, borderRadius: 10 }}>
              <p style={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 400, marginLeft: '2%', marginRight: '2%' }}>You have at least one hidden post. Hidden posts will not be shown to others, but they can be reactivated at any time during the remaining posting duration.</p>
            </div>
          )}

          <p style={{ fontFamily: 'Inter', fontSize: 32, fontWeight: 600, marginTop: 30, marginBottom: 0 }}>Account Settings</p>
          {/* profile picture and account name/email container */}
          <div style={{ flex: 1, marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{ width: 80, height: 80, borderRadius: 50, backgroundColor: 'lightgrey' }} />
            <div style={{ marginLeft: 20}}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 3}}>
                {editName ? (<input type="text" defaultValue={contactName} onChange={text => setContactName(text.target.value)} />) : <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 20, margin: "0 10px 0 0" }}>{contactName}</p>}
                <Icon path={editName ? mdiCheckBold : mdiPencil} onMouseEnter={() => setIsHovering('pencil')} onMouseLeave={() => setIsHovering('')} onClick={() => { editName ? uploadNewName() : setEditName(!editName)}} title='Edit' size={'18px'} style={{ padding: 3, color: 'black', backgroundColor: 'white', cursor: isHovering === 'pencil' ? 'pointer' : 'cursor', borderRadius: 24, }} />
              </div>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 18, margin: "5px 0 0 0" }}>{firebase.auth().currentUser.email}</p>
              <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 10, marginTop: 5, marginBottom: 0, color: 'rgba(0,0,0,.4)'}}>UserID: THW{firebase.auth().currentUser.uid}</p>
            </div>
          </div>
          
          {(screenSize === 'Large' || screenSize === 'Medium') ? (<div />) :
            <button onClick={() => { firebase.auth().signOut(); setLoggedIn(false) }} onMouseEnter={() => setIsHovering('post a job')} onMouseLeave={() => setIsHovering(false)} style={{ backgroundColor: '#378805', padding: '10px 15px 10px 15px', borderRadius: 100, borderWidth: 0, marginTop: 30, cursor: isHovering === 'post a job' ? 'pointer' : 'default', alignSelf: 'center' }}>
              <p style={{ color: 'white', fontSize: 16, padding: 0, fontFamily: 'Inter', fontWeight: 500, margin: 0 }}>Logout</p>
            </button>
          }
          <p style={{ fontFamily: 'Inter', fontSize: 26, fontWeight: 600, marginTop: 30, marginBottom: 15 }}>Your Postings</p>
        </div>

          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: `${outerMargins}%`, paddingRight: `${outerMargins}%`}}>
            {getCurrentPostings()}
        </div>

      
       <Footer/>
            </div>
    )
}
