import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/dashboard/Login';
import CreateAnAccount from './components/dashboard/CreateAnAccount';
import Account from './components/dashboard/Account';
import Post from './components/dashboard/Post';
import Footer from './components/layout/Footer';
import About from './components/dashboard/About';
import PrivacyPolicy from './components/dashboard/PrivacyPolicy';
import SavedJobs from './components/dashboard/SavedJobs';
import JobHistory from './components/dashboard/JobHistory';
import ReportPosting from './components/dashboard/ReportPosting';
import Admin from './components/dashboard/Admin'

//HOW TO RUN: cd to project folder and type npm start in terminal

//* GREAT redux tutorial: https://www.youtube.com/watch?v=HKU24nY8Hsc&list=PL4cUxeGkcC9ij8CfkAY2RAGb-tmkNwQHG&index=34

export default function App() {
  
    return (
      <BrowserRouter>
        <div style={{}}>
          <Navbar />
          <Switch>
            <Route exact path='/' component={Dashboard} />
            {/* <Route path='/project/:id' component={ProjectDetails} /> */}
            <Route path='/signin' component={Login} />
            <Route path='/signup' component={CreateAnAccount} />
            <Route path='/my-account' component={Account} />
            <Route path='/post' component={Post} />
            <Route path='/about' component={About} />
            <Route path='/privacy-policy' component={PrivacyPolicy} />
            <Route path='/saved-jobs' component={SavedJobs} />
            <Route path='/history' component={JobHistory} />
            <Route path='/report' component={ReportPosting} />
            <Route path='/admin' component={Admin} />
          </Switch>
        </div>
      </BrowserRouter>
    );
}