import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Icon from '@mdi/react'
import { useGeolocated } from "react-geolocated";

import {
  mdiMenu,
  mdiMagnify,
  mdiClose,
  mdiDelete,
  mdiHomeVariantOutline,
  mdiDomain,
  mdiClipboardTextClock,
  mdiAccountCircle,
  mdiCashSync,
  mdiBookmarkMultipleOutline,
  mdiInformation,
  mdiCommentQuoteOutline,
  mdiLifebuoy,
  mdiArrowRightThin,
  mdiChevronDown,
  mdiChevronUp,
  mdiShieldCrownOutline 

} from '@mdi/js';
import firebase from 'firebase'
import 'firebase/firestore'
import smallOrangeIcon from '../../images/smallOrangeTHWIcon.png'
import logo from '../../logo.png'

import Geocode from "react-geocode";
import LocationAutocomplete from 'location-autocomplete';

import Cookies from 'js-cookie'

//~ Redux
import { store } from '../../redux/store';
import { useSelector }                          from 'react-redux'
import {
  fetchCurrentLat,
  fetchCurrentLng
  // fetchColors,
  // fetchOutfitsBackwards,
  // fetchCloset,
  // fetchTheme,
  // fetchProfilePic,
  // fetchFollowingPosts,
  // fetchAccountPrivacy,
  // fetchFollowRequests
} from '../../redux/reducers';

const Navbar = (props) => {
  useEffect(() => {
    store.dispatch(fetchCurrentLat())
    store.dispatch(fetchCurrentLng())
  }, [])

  const data = useSelector(state => state)
  const { currentLatRedux, currentLngRedux } = data

  useEffect(() => {
    console.log('currentLatRedux', currentLatRedux)
    console.log('currentLngRedux', currentLngRedux)
  }, [currentLatRedux, currentLngRedux])

  useEffect(() => {
    firebase.firestore().collection('spotlights').doc('topBar').get().then((doc) => {
      if (doc.data().toggle) {
        setShowTopBar(true)
      } else {
        setShowTopBar(false)
      }
      if (doc.data().showPostTodayLinkOnTopBar) {
        setShowPostTodayLinkOnTopBar(true)
      } else {
        setShowPostTodayLinkOnTopBar(false)
      }
    })
  }, [])
 
  const [showTopBar, setShowTopBar] = useState(false)

  const [isHoveringPay, setIsHoveringPay] = useState(false)
  const [isHoveringWork, setIsHoveringWork] = useState(false)
  const [isHoveringLogo, setIsHoveringLogo] = useState(false)
  const [isHoveringMyAccount, setIsHoveringMyAccount] = useState(false)
  const [isHoveringLogout, setIsHoveringLogout] = useState(false)
  const [isHoveringLocation, setIsHoveringLocation] = useState(false)
  const [outerMargins, setOuterMargins] = useState('12')
  const [menuOpen, setMenuOpen] = useState(false)
  const [isHoveringCloseMenu, setIsHoveringCloseMenu] = useState('')
  const [menuHovering, setMenuHovering] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState('/')
  const [searchOpened, setSearchOpened] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [hovering, setHovering] = useState('')
  const [posts, setPosts] = useState([
    { category: "pet", contactName: "filler" },
    {category: "trade", contactName: "test"}
  ])
  const [greenOutlineForm, setGreenOutlineForm] = useState(false)
  const [postsLoaded, setPostsLoaded] = useState()

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
  })

  }, [])
  
  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])

  useEffect(() => {
    firebase.firestore().collection('projects').get().then(querySnapshot => {
      const postData = []

      querySnapshot.forEach(doc => {
        /*
          Determines if a post is expired or not
        */
        let currentDate = new Date()

        let firebaseDate = new Date(1970, 0, 1)
        firebaseDate.setSeconds(doc.data().creation.seconds)

        let timeDifference = currentDate.getTime() - firebaseDate.getTime()

        let daysDifference = timeDifference / (1000 * 60 * 60 * 24)


        if (daysDifference <= parseInt(doc.data().postDuration)) {
          //Show on Dashboard and Account Screen
          if (!doc.data().hidden) {
            if (doc.address == undefined) {
              postData.push({ ...doc.data(), id: doc.id, address: '' })
            } else {
              postData.push({ ...doc.data(), id: doc.id })
            }
          }
        } else {
          //Do not show on Dashboard, but Show on Account Screen with a notice saying its expired
          
        }

      })
      setPosts(postData)
      setPostsLoaded(true)
    }).catch(err => console.log('errrrr', err.message))
  }, [])

  const [redir, setRedir] = useState(false)

    const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      setRedir(true)
    }
  };

  // Javascript search bar for an object
  // https://stackoverflow.com/questions/57861003/how-to-filter-or-search-an-array-of-objects-in-react

 
    const filteredItems = posts.filter(
      item =>
        item.contactName.toLocaleLowerCase().includes(filterText) ||
        item.jobTitle.toLocaleLowerCase().includes(filterText) ||
        //item.address.includes(filterText) ||
        //item.description.toLocaleLowerCase().includes(filterText) ||
        //item.category.toLocaleLowerCase().includes(filterText) ||
        item.id.toLocaleLowerCase().includes(filterText)
    )

  const itemsToDisplay = filterText ? filteredItems : 'Search companies, locations, and categories.';

  const [shown, setShown] = useState(true)

  useEffect(() => {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyAxkiHdR0dvsMNPJ4T8qyIsh2364g_rc4k");

    // set response language. Defaults to english.
    Geocode.setLanguage("en");
  },[])

  const detIfClose = () => {
    if (hovering === 'searchResults') {
      //do not close
    } else {
      setSearchOpened(false)
    }
  }

  const [currentLat, setCurrentLat] = useState('no-location-permissions')
  const [currentLng, setCurrentLng] = useState('no-location-permissions')
  const [dropdownLocation, setDropdownLocation] = useState('')
  const [showLocationAutofill, setShowLocationAutofill] = useState('')
  const [loadedLocation, setLoadedLocation] = useState(false)

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation

    if (Cookies.get('currentLat') != undefined) {
        Geocode.setApiKey("AIzaSyAxkiHdR0dvsMNPJ4T8qyIsh2364g_rc4k");
        Geocode.setLanguage("en");
        Geocode.fromLatLng(Cookies.get('currentLat'), Cookies.get('currentLng')).then(
          (response) => {
            const address = response.results[0].formatted_address;
            let city, state, country;
            for (let i = 0; i < response.results[0].address_components.length; i++) {
              for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = response.results[0].address_components[i].short_name;
                    break;
                  case "country":
                    country = response.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            console.log('357', state);
            setDropdownLocation(city + ", " + state)
            console.log('356', address);
          },
          (error) => {
            console.error('e', error);
          }
        );
    }
    
    if (location) {
      location.getCurrentPosition((position) => {
        setCurrentLat(position.coords.latitude)
        setCurrentLng(position.coords.longitude)
        console.log('current LNG/LAT set')
        Geocode.setApiKey("AIzaSyAxkiHdR0dvsMNPJ4T8qyIsh2364g_rc4k");
        Geocode.setLanguage("en");
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          (response) => {
            const address = response.results[0].formatted_address;
            let city, state, country;
            for (let i = 0; i < response.results[0].address_components.length; i++) {
              for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = response.results[0].address_components[i].short_name;
                    break;
                  case "country":
                    country = response.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            console.log('357', state);
            setDropdownLocation(city + ", " + state)
            console.log('356', address);
          },
          (error) => {
            console.error('e', error);
          }
        );
      })
    } else {
      console.log('2343232')
    }
  }, [loaded, loadedLocation])


  const [accountInformation, setAccountInformation] = useState({ id: 'no account' })
  const [showPostTodayLinkOnTopBar, setShowPostTodayLinkOnTopBar] = useState(false)

    useEffect(() => {
    try {
      if (loaded) {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get().then(doc => {
          setAccountInformation({ ...doc.data(), id: doc.id })
        }).then(() => console.log('ACCOUNT: ', accountInformation))
      }
    } catch (err) {
      console.log(err)
    }
  }, [loaded])

  function onDropdownSelect(component) {
  // this will give you access to the entire location object, including
  // the `place_id` and `address_components`
    const place = component.autocomplete.getPlace();
    
    console.log('place:', place.address_components[0].long_name, place.address_components[2].short_name)
    setDropdownLocation(place.address_components[0].long_name + ', ' + place.address_components[2].short_name)
    
    setCurrentLat(place.geometry.location.lat())
    setCurrentLng(place.geometry.location.lng())

    Cookies.set('currentLat', place.geometry.location.lat())
    Cookies.set('currentLng', place.geometry.location.lng())
    console.log('cookies set')

    store.dispatch(fetchCurrentLat(place.geometry.location.lat()))
    store.dispatch(fetchCurrentLng(place.geometry.location.lng()))

  // other awesome stuff
  }

  useEffect(() => {
    if(Cookies.get('currentLat') != undefined || (currentLng !== 'no-location-permissions' && currentLat !== 'no-location-permissions')){
      setLoadedLocation(true)
      console.log('goo', loadedLocation)
    } else {
      console.log('badd')
    }
  }, [currentLat, currentLng, dropdownLocation])


  useEffect(() => {
    //console.log('NAVBAR:', window.location.pathname)
    setCurrentPage(window.location.pathname)
  }, [window.location.pathname])

  // if (!loadedLocation) return <p>{currentLat}</p>


  return (
        <body>
      <head>
                  <link rel="preconnect" href="https://fonts.googleapis.com"/><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
      </head>

      {/* Top navbar notification */}
      {showTopBar && (
        <div onClick={() => setShown(false)} style={{ backgroundColor: 'green', width: '100%', height: 28, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ color: 'white', fontFamily: 'Inter', fontSize: 13, textAlign: 'center' }}>{(screenSize === 'Small' || screenSize === 'Extra small') ? 'A' : 'As we begin our mission to make it easy for students to find jobs, a'}ll postings will be FREE for a limited time. {showPostTodayLinkOnTopBar && (<Link to='/post' style={{ textDecoration: 'underline', fontWeight: '500', color: 'white' }}>Post Today</Link>)}</p>
        </div>
      )}

      <div style={{ position: 'absolute', left: 300, top: 0, bottom: 0, right: 0, zIndex: 60, display: menuOpen ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,.03)' }} onClick={() => setMenuOpen(false)} />

      
      {/* MENU */}
      <div style={{ position: 'fixed', display: menuOpen ? 'block' : 'none', height: '100vh', width: 300, top: 0, left: 0, zIndex: 25, backgroundColor: 'white', borderRightWidth: 1, borderRightColor: 'black', boxShadow: '2px 2px 9px rgba(0,0,0,.4)' }}>
        <Icon path={mdiClose} onMouseEnter={() => setIsHoveringCloseMenu(true)} onClick={() => setMenuOpen(false)} onMouseLeave={() => setIsHoveringCloseMenu(false)} title='Close' size={'30px'} style={{ padding: 3, color: 'black', backgroundColor: 'white', cursor: isHoveringCloseMenu ? 'pointer' : 'cursor', position: 'absolute', left: 10, top: 12 }} />
        {/* Add a message up top, Home, Pay, History, Account, [About Us], Payment (Grey out), Saved Jobs, Privacy Policy  mdiHome, mdiDomain, mdiClipboardTextClock, mdiAccountCircle, mdiCashSync, mdiBookmarkMultipleOutline, mdiInformation   */}
        {/* <div style={{ width: '100%', backgroundColor: 'rgba(232, 88, 32, .1)', paddingTop: 20, paddingBottom: 10, marginTop: 60, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <img src={smallOrangeIcon} style={{ height: 20, width: 20, marginLeft: 25, marginRight: 10 }} />
          <p style={{ fontFamily: 'Inter', color: '#E85820', fontWeight: 500, fontSize: 14, width: 180, paddingTop: 0, marginTop: 0 }}>Get early access to jobs before they are posted with a free trial of THW Plus.</p>
          <Icon path={mdiArrowRightThin} size={'35px'} style={{ color: '#E85820', marginTop: 20, marginRight: 25 }} />
        </div> */}

        <Link to='/' style={{ textDecoration: 'none'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'home' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'home' ? 'pointer' : 'default', marginTop: 60  }}  onMouseEnter={() => setMenuHovering('home')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <Icon path={mdiHomeVariantOutline} size={'30px'} style={{ color: currentPage === '/' ? '#378805' : 'rgba(0,0,0,.8)', marginLeft: 20 }} />
            <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: currentPage === '/' ? '#378805' : 'rgba(0,0,0,.8)' }}>Home</p>
          </div>
        </Link>

        {loggedIn ? (
          <Link to='/post' style={{ textDecoration: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'pay' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'pay' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('pay')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
              <Icon path={mdiDomain} size={'30px'} style={{ color: currentPage === '/post' ? '#378805' : 'rgba(0,0,0,.8)', marginLeft: 20 }} />
              <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: currentPage === '/post' ? '#378805' : 'rgba(0,0,0,.8)' }}>Post Jobs</p>
            </div>   
          </Link>  
        ) :
          <Link to='/signup' style={{ textDecoration: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'pay' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'pay' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('pay')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
              <Icon path={mdiDomain} size={'30px'} style={{ color: currentPage === '/post' ? '#378805' : 'rgba(0,0,0,.8)', marginLeft: 20 }} />
              <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: currentPage === '/post' ? '#378805' : 'rgba(0,0,0,.8)' }}>Post Jobs</p>
            </div>
          </Link>
        }

        <Link to={loggedIn ? '/my-account' : '/signup'} style={{ textDecoration: 'none'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'account' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'account' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('account')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <Icon path={mdiAccountCircle} title='Account' size={'30px'} style={{ color: currentPage === '/my-account' ? '#378805' : 'rgba(0,0,0,.8)', marginLeft: 20 }} />
            <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: currentPage === '/my-account' ? '#378805' : 'rgba(0,0,0,.8)' }}>Account</p>
          </div>
        </Link>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: 'white', cursor: 'default' }}  onMouseEnter={() => setMenuHovering('payments')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
          <Icon path={mdiCashSync} size={'30px'} style={{ color: 'rgba(0,0,0,.4)', marginLeft: 20 }} />
          <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: 'rgba(0,0,0,.5)' }}>Payments</p>
        </div>

        <Link to='/saved-jobs' style={{ textDecoration: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'saved' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'saved' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('saved')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <Icon path={mdiBookmarkMultipleOutline} size={'30px'} style={{ color: currentPage === '/saved-jobs' ? '#378805' : 'rgba(0,0,0,.8)', marginLeft: 20 }} />
            <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: currentPage === '/saved-jobs' ? '#378805' : 'rgba(0,0,0,.8)' }}>Saved Jobs</p>
          </div>
        </Link>

        <Link to='/privacy-policy' style={{ textDecoration: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'policy' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'policy' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('policy')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <Icon path={mdiInformation} size={'30px'} style={{ color: currentPage === '/privacy-policy' ? '#378805' : 'rgba(0,0,0,.8)', marginLeft: 20 }} />
            <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: currentPage === '/privacy-policy' ? '#378805' : 'rgba(0,0,0,.8)' }}>Privacy Policy</p>
          </div>
        </Link>

        <a href='https://www.karalewich.com/thehourlyworker-feedback.html' target="_blank" style={{ textDecoration: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'feedback' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'feedback' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('feedback')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <Icon path={mdiCommentQuoteOutline} size={'30px'} style={{ color: 'rgba(0,0,0,.8)', marginLeft: 20 }} />
            <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: 'black' }}>Feedback</p>
          </div>
        </a>

         <a href='https://www.karalewich.com/thehourlyworker-feedback.html' target="_blank" style={{ textDecoration: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'help' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'help' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('help')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <Icon path={mdiLifebuoy} size={'30px'} style={{ color: 'rgba(0,0,0,.8)', marginLeft: 20 }} />
            <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: 'black' }}>Help</p>
          </div>
        </a>

        {(accountInformation.id === 'zULDpJ4K0rSY6wOl7SYhcVCdna03' || accountInformation.id === 'Wl8py7QTVYXeeZhZtFZnKuN000F2') && (
        <Link to='/admin' style={{ textDecoration: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'admin' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'admin' ? 'pointer' : 'default' }}  onMouseEnter={() => setMenuHovering('admin')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <Icon path={mdiShieldCrownOutline } size={'30px'} style={{ color: currentPage === '/admin' ? '#378805' : 'rgba(0,0,0,.8)', marginLeft: 20 }} />
            <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 18, marginLeft: 15, color: currentPage === '/admin' ? '#378805' : 'rgba(0,0,0,.8)' }}>Admin</p>
          </div>
        </Link>
        )}

        <Link to='/about'>
          <div style={{ position: 'absolute', bottom: 0, width: '100%', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(0,0,0,.1)', backgroundColor: menuHovering === 'about' ? 'rgba(0,0,0,.05)' : 'white', cursor: menuHovering === 'about' ? 'pointer' : 'default', }}  onMouseEnter={() => setMenuHovering('about')} onMouseLeave={() => setMenuHovering('')} onClick={() => setMenuOpen(false)}>
            <p style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 16, color: 'rgba(0,0,0,.9)', paddingLeft: 20 }}>About Us</p>
          </div>
        </Link>


  </div>
  
      {/* Actual Navbar */}
      
  <div>
            <div style={{  backgroundColor: 'white',display: 'flex', zIndex: 3, width: '100%', height: 60, flexDirection:'row', justifyContent:'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '5%' }}>
            <Icon path={mdiMenu} title='Menu' color={'rgba(0,0,0,.8)'} size={1} style={{ paddingLeft: 8, paddingTop: 0, cursor: isHoveringCloseMenu ? 'pointer' : 'default' }} onMouseEnter={() => setIsHoveringCloseMenu(true)} onMouseLeave={() => setIsHoveringCloseMenu(false)} onClick={() => {
              if (menuOpen) {
                setMenuOpen(false)
              } else {
                setMenuOpen(true)
              }
            }} />
              <Link to='/'>
                <img src={logo}onMouseEnter={() => setIsHoveringLogo(true)} onMouseLeave={() => setIsHoveringLogo(false)} style={{ height: 40, marginLeft: 15, cursor: isHoveringLogo ? 'pointer' : 'default', paddingTop: 4}} alt='test'/>
            </Link>
            
            {(screenSize === 'Small' || screenSize === 'Extra small') ? (<div />) :
              <Link to='/'>
                <button onMouseEnter={() => setIsHoveringWork(true)} onMouseLeave={() => setIsHoveringWork(false)} style={{ backgroundColor: 'white', borderWidth: 0, fontFamily: 'Inter', fontWeight: 700, marginLeft: 15, marginRight: 15, fontSize: 13, color: isHoveringWork ? 'black' : 'rgba(0,0,0,.8)', cursor: isHoveringWork ? 'pointer' : 'default' }}>Work</button>
              </Link>
            }

            {(screenSize === 'Small' || screenSize === 'Extra small') ? (<div />) : loggedIn ? (
              <Link to='/post'>
                <button onMouseEnter={() => setIsHoveringPay(true)} onMouseLeave={() => setIsHoveringPay(false)}style={{ marginRight: 10, backgroundColor: 'white', borderWidth: 0,fontSize: 13, fontFamily: 'Inter', fontWeight: 700, marginRight: 15, color: isHoveringPay ? 'black' : 'rgba(0,0,0,.8)', cursor: isHoveringPay ? 'pointer' : 'default' }}>Post</button>
              </Link>
            ) :
              <Link to='/signup' >
                <button onMouseEnter={() => setIsHoveringPay(true)} onMouseLeave={() => setIsHoveringPay(false)} style={{ marginRight: 10, backgroundColor: 'white', borderWidth: 0,fontSize: 13, fontFamily: 'Inter', fontWeight: 700, marginRight: 15, color: isHoveringPay ? 'black' : 'rgba(0,0,0,.8)', cursor: isHoveringPay ? 'pointer' : 'default' }}>Post</button>
              </Link>
            }
{/* 
            {(screenSize === 'Small' || screenSize === 'Extra small') ? (<div />) :
              <button style={{ marginRight: 10, backgroundColor: 'white', borderWidth: 0, fontSize: 13, fontFamily: 'Inter', fontWeight: 700, marginRight: 15, color: isHoveringPay ? 'black' : 'rgba(0,0,0,.8)', cursor: isHoveringPay ? 'pointer' : 'default' }}>605 Echo Glen Ave v</button>
            } */}
            
              {showLocationAutofill && (
              <div onMouseEnter={() => setShowLocationAutofill(false)} style={{ position: 'absolute', backgroundColor: 'rgba(0,0,0,.01)', top: 0, left: 0, bottom: 0, right: 0, zIndex: 19, width: '100%', height: '100%' }} />
            )}
            
            <button onClick={() => setShowLocationAutofill(true)} onMouseEnter={() => { setIsHoveringLocation(true); setShowLocationAutofill(true) }} onMouseLeave={() => { setIsHoveringLocation(false)}} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', zIndex: 20, marginTop: 1, borderWidth: 0, marginLeft: 5, borderRadius: 15, backgroundColor: isHoveringLocation ? 'rgba(0,0,0,.05)' : 'transparent', height: 30, cursor: isHoveringLocation ? 'pointer' : 'default' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0}} onClick={() => {if(showLocationAutofill) setShowLocationAutofill(false)}}>
              <p style={{ fontSize: 13, fontFamily: 'Inter', fontWeight: 700, color: 'rgba(0,0,0,.8)', marginLeft: 5 }}>{loadedLocation ? dropdownLocation : 'Set Location'}</p>
              <Icon path={showLocationAutofill ? mdiChevronUp : mdiChevronDown} size={'20px'} style={{ color: 'rgba(0,0,0,.8)', marginLeft: 3, marginRight: 5 }} />
              </div>


              <LocationAutocomplete
              name="venue"
              placeholder="City, State"
              //defaultValue={address}
              componentRestrictions={{ country: ['us'] }}
              locationType='(regions)'
              googleAPIKey="AIzaSyAxkiHdR0dvsMNPJ4T8qyIsh2364g_rc4k"
              //onChange={text => { setDropdownLocation(text) }}
              onDropdownSelect={(item) => {onDropdownSelect(item); setShowLocationAutofill(false)}}
              onFocus={() => setGreenOutlineForm('location')} onBlur={() => setGreenOutlineForm('')}
              style={{ position: 'absolute', display: showLocationAutofill ? 'block' : 'none', top: 63, marginLeft: -5, width: 200, zIndex: 20, fontFamily: 'Inter', fontWeight: 400, fontSize: 13, height: 30, backgroundColor: 'white', borderRadius: 10, marginTop: 10, padding: '0px 15px 0px 15px', borderWidth: 2, borderColor: greenOutlineForm === 'location' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid' }}
                onMouseEnter={() => console.log('uo')}
                onMouseLeave={() => console.log('chrid')}
              />            
            
              </button>


                
              </div>

        <div style={{ display: screenSize !== 'Extra small' ? 'flex' : 'none', position: 'relative', flexDirection: 'row', alignItems: 'center', paddingRight: '5%' }}>
            {/* <div style={{ backgroundColor: '#ededed', width: 228, height: 35, borderRadius: 90, display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 12 }}>
              <Icon path={mdiMagnify} title='Menu' size={1} style={{ paddingTop: 4, paddingLeft: 8}} />
              <p style={{ fontSize: 13, color: 'rgba(0,0,0,.7)', paddingLeft: 5 }}>Search for hourly jobs</p>
            </div> */}

            <input onKeyDown={handleKeyDown} value={filterText} onChange={text => setFilterText(text.target.value.toLocaleLowerCase())} onFocus={() => setSearchOpened(true)} onBlur={() => detIfClose()} type="text" placeholder="Search for hourly jobs" style={{ backgroundColor: '#ededed', width: 186, outline: 'none', height: 35, borderRadius: 90, marginRight: 12, borderWidth: 0, paddingLeft: 42, fontFamily: 'Inter', fontWeight: 400, fontSize: 13 }} />
            <Icon path={mdiMagnify} title='Menu' size={1} style={{ position: 'absolute', zIndex: 2, left: 9, top: 7 }} />
            {searchOpened ? (
              <div onMouseEnter={() => setHovering('searchResults')} onMouseLeave={() => setHovering('')} style={{ position: 'absolute', top: 40, left: 0, width: 228, backgroundColor: 'white', borderColor: '#ededed', borderWidth: .5, borderStyle: 'solid', zIndex: 3}}>
                {itemsToDisplay === 'Search companies, locations, and categories.' && (
                  <p style={{ fontFamily: 'Inter', fontSize: 16, fontWeight: 400, margin: 10 }}>Search companies, locations, and categories</p>
                )}
                {itemsToDisplay !== 'Search companies, locations, and categories.' && (
                <div>
                {itemsToDisplay.slice(0,2).map(item => (
                  <Link key={item.id} to={{ pathname: '/', searchFilter: item.id }} style={{ textDecoration: 'none' }} onClick={() => { setSearchOpened(false);  setFilterText('')}}>
                    <div>
                      <p style={{ fontFamily: 'Inter', fontSize: 14, color: 'black', fontWeight: 600, margin: '10px 10px 0px 10px'}}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
                      <p style={{ fontFamily: 'Inter', fontSize: 12, color: 'rgba(0,0,0,.3)', fontWeight: 400, margin: '10px 10px 10px 10px' }}>${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
                    </div>
                  </Link>
                ))}
                    {itemsToDisplay.length > 2 && (<Link to={{ pathname: '/', searchResults: itemsToDisplay }} style={{ textDecoration: 'none' }} onClick={() => { setSearchOpened(false); setFilterText('')}}><p style={{ fontFamily: 'Inter', fontSize: 14, color: 'black', fontWeight: 600, margin: '10px 10px 10px 10px' }}>See {itemsToDisplay.length - 2} more result{itemsToDisplay.length === 3 ? '' : 's'} &#10143;</p></Link>)}
                  </div>
                )}
              </div>
            ) :
              <div />
            }
            {/*
              So maybe it searches from an object which only has all the categories in it... as you type the letters the categories below populate.  Then you have to click one of them,
              and it links you to the dashboard page with a parameter that tells the dashboard to filter with that category.
            */}

            {(screenSize === 'Extra small' || screenSize === 'Small' ) ? (<div />) : loggedIn ? (
              <div>
                <button onMouseEnter={() => setIsHoveringLogout(true)} onMouseLeave={() => setIsHoveringLogout(false)} onClick={() => {
                  firebase.auth().signOut()
                  setLoggedIn(false)
                  }} style={{ backgroundColor: 'white', padding: 10, cursor: isHoveringLogout ? 'pointer' : 'default', color: isHoveringLogout ? 'black' : 'rgba(0,0,0,.8)', borderWidth: 0, marginRight: 15, fontSize: 14, fontFamily: 'Inter' }}>Logout</button>
                <Link to='/my-account' >
                  <button onMouseEnter={() => setIsHoveringMyAccount(true)} onMouseLeave={() => setIsHoveringMyAccount(false)} style={{ backgroundColor: isHoveringMyAccount ? 'black' : '#378805', width: 105, height: 35, borderRadius: 100, borderWidth: 0, marginRight: 10, cursor: isHoveringMyAccount ? 'pointer' : 'default'}}>
                    <p style={{ color: 'white', fontSize: 14, marginTop: 9, paddingTop: 0, fontFamily: 'Inter'}}>My Account</p>
                  </button>
                </Link>
              </div>
            ) :
              <div>
                <Link to='./signin'>
                  <button onMouseEnter={() => setIsHoveringLogout(true)} onMouseLeave={() => setIsHoveringLogout(false)} style={{ backgroundColor: 'white', padding: 10, borderWidth: 0, marginRight: 15, fontSize: 14, cursor: isHoveringLogout ? 'pointer' : 'default', fontFamily: 'Inter', color: isHoveringLogout ? 'black' : 'rgba(0,0,0,.8)' }}>Login</button>
                </Link>
                <Link to='/signup/' >
                  <button onMouseEnter={() => setIsHoveringMyAccount(true)} onMouseLeave={() => setIsHoveringMyAccount(false)}  style={{ backgroundColor: '#378805', width: 84, height: 35, borderRadius: 100, borderWidth: 0, marginRight: 10, backgroundColor: isHoveringMyAccount ? 'black' : '#378805', cursor: isHoveringMyAccount ? 'pointer' : 'default'}}>
                    <p style={{ color: 'white', fontSize: 14, marginTop: 9, paddingTop: 0, fontFamily: 'Inter' }}> Sign Up </p>
                  </button>
                </Link>
              </div>
            }
              </div>

        </div>

        </div>
        
        </body>
    );
}

export default Navbar;