import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import firebase from 'firebase'
import 'firebase/firestore'
import { compose } from 'redux'
import Icon from '@mdi/react'
import { useGeolocated } from "react-geolocated";
import loadingLogo from '../../images/Grey Loading Logo.png'
import { BarLoader } from 'react-spinners'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';



import {
  mdiClose,
  mdiDelete,
  mdiClockTimeEight,
  mdiDomain,
  mdiAccount,
  mdiMapMarker,
  mdiBookmarkOutline,
  mdiBookmark,
  mdiCurrencyUsd,
  mdiClockOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDown,
  mdiCashMultiple,
  mdiTuneVariant 
} from '@mdi/js';
import HorizontalScroll from 'react-horizontal-scrolling'
import { Link } from 'react-router-dom'
import delivery from "../../images/delivery.png"
import retail from "../../images/retail.png"
import landscaping from "../../images/landscaping.png"
import computer from "../../images/computer.png"
import home from "../../images/home.png"
import trade from "../../images/trade.png"
import pet from "../../images/pet.png"
import warehouse from "../../images/warehouse.png"
import maintenance from "../../images/maintenance.png"
import restaurant from "../../images/restaurant.png"
import customerService from "../../images/customerService.png"
import clear from "../../images/clear.png"
import Footer from '../layout/Footer'
import health from '../../images/health.png'
import marketing from '../../images/marketing.png'
import other from '../../images/other.png'
import office from '../../images/office.png'
import personal from '../../images/personal.png'
import physical from '../../images/physical.png'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Cookies from 'js-cookie'

//~ Redux
import { store } from '../../redux/store';
import { useSelector }                          from 'react-redux'
import {
  fetchCurrentLat,
  fetchCurrentLng
  // fetchColors,
  // fetchOutfitsBackwards,
  // fetchCloset,
  // fetchTheme,
  // fetchProfilePic,
  // fetchFollowingPosts,
  // fetchAccountPrivacy,
  // fetchFollowRequests
}                                               from '../../redux/reducers';


const containerStyle = {
  width: '400px',
  height: '400px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

const handleDragStart = (e) => e.preventDefault();

/*

  Colors: Green (#378805, rgb(55, 136, 5)), Secondary Color Orange: (#E85820, rgb(232, 88, 32) Note Use .05 for rgba on white)

*/

function Dashboard(props) {
  useEffect(() => {
    store.dispatch(fetchCurrentLat())
    store.dispatch(fetchCurrentLng())
  }, [])

  const data = useSelector(state => state)
  const { currentLatRedux, currentLngRedux } = data

  useEffect(() => {
    console.log('currentLatRedux', currentLatRedux)
    console.log('currentLngRedux', currentLngRedux)
    if (currentLatRedux !== 49) {
      setCurrentLat(currentLatRedux)
      setCurrentLng(currentLngRedux)
    } else {
      console.log('ELSE399')
      if(Cookies.get('currentLat') != undefined && Cookies.get('currentLng') != undefined) {
        setCurrentLat(Cookies.get('currentLat'))
        setCurrentLng(Cookies.get('currentLng'))
        console.log('got cookies', Cookies.get('currentLat'), Cookies.get('currentLng'))
      } else {
        console.log('failed to get cooks')
      }
    }
  }, [currentLatRedux, currentLngRedux])

  const haversine = require('haversine')

  const [currentLat, setCurrentLat] = useState('no-location-permissions')
  const [currentLng, setCurrentLng] = useState('no-location-permissions')

  const [isHoveringPost, setIsHoveringPost] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [openedPosting, setOpenedPosting] = useState('')
  const [isHoveringClose, setIsHoveringClose] = useState(false)
  const [outerMargins, setOuterMargins] = useState('12')
  const [recentlyDeleted, setRecentlyDeleted] = useState(0)
  const [filter, setFilter] = useState('')
  const [quickTags, setQuickTags] = useState('')
  const [filterClicked, setFilterClicked] = useState(0)
  const [tagsHovering, setTagsHovering] = useState('')
  const [spotlights, setSpotlights] = useState([])
  const [postsLoaded, setPostsLoaded] = useState(false)
  const [posts, setPosts] = useState('')
  const [sectionHovering, setSectionHovering] = useState('')
  const [filterHovering, setFilterHovering] = useState('')
  const [sort, setSort] = useState('')
  const [originalSorted, setOriginalSorted] = useState()
  const [bookmarkHovering, setBookmarkHovering] = useState('')
  const [doNotClose, setDoNotClose] = useState(false)
  const [savedPostIDs, setSavedPostIDs] = useState('')
  const [savesUpdated, setSavesUpdated] = useState(0)
  const [spotlightHovering, setSpotlightHovering] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [imageAt, setImageAt] = useState(0)
  const [imagePreviewArrowHover, setImagePreviewArrowHover] = useState('')
  const [loadedLocation, setLoadedLocation] = useState(false)
  const [loadMoreHovering, setLoadMoreHovering] = useState(false)
  const loadMoreByFactor = 30
  const [loadMoreBy, setLoadMoreBy] = useState(loadMoreByFactor)
  
  const [sortMutuallyExclusive1, setSortMutuallyExlusive1] = useState('Closest Jobs') // Closest Jobs (DEFAULT), Hourly Pay, One-Time Pay, Most Recent
  const [sortMutuallyExclusive2, setSortMutuallyExlusive2] = useState('') // '', Above $X/hr, Above $X
  const [sortRemote, setSortRemote] = useState('')
  const [payAboveNumberHOURLY, setPayAboveNumberHOURLY] = useState(15)
  const [payAboveNumberONETIME, setPayAboveNumberONETIME] = useState(80)
  const [locationNumberCutoff, setLocationNumberCutoff] = useState(5)
  const [sortCutoffLocation, setSortCutoffLocation] = useState(false)

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
      useGeolocated({
          positionOptions: {
              enableHighAccuracy: false,
          },
          userDecisionTimeout: 5000,
      });
  
  useEffect(() => {
    console.log('c', coords)
  }, [coords])

  useEffect(() => {
  console.log('currentLat updated:', currentLat);
}, [currentLat]);

  useEffect(() => {
    if (!loadedLocation) {
      const location = window.navigator && window.navigator.geolocation;

      if (!(currentLatRedux == undefined || currentLatRedux === 49 || currentLngRedux == undefined || currentLngRedux === 49)) {
        setCurrentLat(currentLatRedux);
        setCurrentLng(currentLngRedux);
        console.log('currentLat set! currentLatRedux:', currentLatRedux);
      } else {
        console.log('else123 hit');
        if (location) {
          console.log('if321324 hit');
          try {
            location.getCurrentPosition(
              (position) => {
                console.log('point234 hit, position.coords.latitude: ', position.coords.latitude);
                setCurrentLat(position.coords.latitude);
                setCurrentLng(position.coords.longitude);
              },
              (error) => {
                console.log('error3224 hit', error.message);
                try {
                  setCurrentLat(coords.latitude);
                  setCurrentLng(coords.longitude);
                } catch (err) {
                  setCurrentLat('error-latitude');
                  setCurrentLng('error-longitude');
                }
              }
            );
          } catch (err) {
            console.log('er2342', err.message);
          }
        } else {
          console.log('Geolocation not available');
        }
      }
    } else {
      console.log('location already loaded succesfully')
    }
}, [loaded, coords, currentLatRedux, currentLngRedux, loadedLocation]);


  useEffect(() => {
    if(Cookies.get('currentLat') != undefined || (currentLng !== 'no-location-permissions' && currentLat !== 'no-location-permissions')){
      setLoadedLocation(true)
      console.log('goo', loadedLocation)
    } else {
      console.log('badd')
    }
  }, [currentLat, currentLng])

  const [searchResults, setSearchResults] = useState('')

  useEffect(() => {
    document.title = 'Punching Clock'
    if (props.location.searchFilter == undefined || props.location.searchFilter === null) { } else {
      setSearchFilter(props.location.searchFilter)
      console.log('HEEEEERE', props.location.searchFilter)
    }
    if (props.location.searchResults == undefined || props.location.searchResults === null) { } else {
      setSearchResults(props.location.searchResults)
      console.log('HEEEEERE3', props.location.searchResults)
      setRecentlyDeleted(recentlyDeleted + 1)
    }
    console.log('HHHHHERE')
  }, [props])

  useEffect(() => {
    
    const db = firebase.firestore()
    
    let query = db.collection('projects')
    
    if (sortMutuallyExclusive1 === "Most Recent") {
      query = query.orderBy('creation', 'desc')
    }

    query.get().then(querySnapshot => {
      let postData = []
      setPosts('')
      querySnapshot.forEach(doc => {
        /*
        Determines if a post is expired or not
      */
      let currentDate = new Date()
      let firebaseDate = new Date(1970, 0, 1)
      firebaseDate.setSeconds(doc.data().creation.seconds)
      let timeDifference = currentDate.getTime() - firebaseDate.getTime()
        let daysDifference = timeDifference / (1000 * 60 * 60 * 24)
        //console.log('currentLat: ', currentLat, ', currentLng: ', currentLng, '. doc.data().lat: ', doc.data().lat, ', doc.data().lng: ', doc.data().lng, '. Calculation result: ', haversine({ latitude: currentLat, longitude: currentLng }, { latitude: doc.data().lat, longitude: doc.data().lng }, { unit: 'mile' }).toFixed(1))
        let distanceBetween = loadedLocation ? haversine({ latitude: currentLat, longitude: currentLng }, { latitude: doc.data().lat, longitude: doc.data().lng }, { unit: 'mile' }).toFixed(1) : 'location not loaded'
        if(!loadedLocation) {console.log('location not loaded')}
      if (daysDifference <= parseInt(doc.data().postDuration)) {
        if ((doc.data().category === filter || filter === '' || doc.data().category.indexOf(filter) !== -1)) {
          if (!doc.data().hidden) {
            if (searchResults === '') {
              postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
            } else {
              for (let i = 0; i < searchResults.length; i++) {
                if (searchResults[i].id === doc.data().id) {
                  postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
                }
              }
            }
          }
        }
      } else {
        //Do not show on Dashboard, but Show on Account Screen with a notice saying its expired
        //console.log('This document is expired')
      }
      })

      if (sortMutuallyExclusive1 === 'Closest Jobs') {
      const tempArray = postData
      postData = tempArray.sort(function(a,b) {return a.distance - b.distance })
      
      }

      if (sortMutuallyExclusive1 === 'Hourly Pay') {
        //Filter out "One Time Job" Objects
        const filteredJobs = postData.filter(postData => postData.hourlyWage !== '')
        //Sort by highest hourly pay in descending order
        filteredJobs.sort((a, b) => b.hourlyWage - a.hourlyWage)
        postData = filteredJobs
      }

      if (sortMutuallyExclusive1 === "One-Time Pay") {
        //Filter out "One Time Job" Objects
        const filteredJobs = postData.filter(job => job.oneTimePayment !== '')
        //Sort by highest hourly pay in descending order
        filteredJobs.sort((a, b) => b.oneTimePayment - a.oneTimePayment)
        postData = filteredJobs
      }

      if (sortMutuallyExclusive2 === 'Above $X/hr') {
        const filteredJobs = postData.filter(job => parseFloat(job.hourlyWage) >= payAboveNumberHOURLY)
        postData = filteredJobs
      }

      if (sortMutuallyExclusive2 === 'Above $X') {
        const filteredJobs = postData.filter(job => parseFloat(job.oneTimePayment) >= payAboveNumberONETIME)
        postData = filteredJobs
      }

      if (sortRemote) {
        const filteredRemote = postData.filter(job => job.remote)
        postData = filteredRemote
      }

      if (sortCutoffLocation) {

        const filteredJobs = postData.filter(job => parseFloat(job.distance) < locationNumberCutoff)
        postData = filteredJobs
    }

      console.log('HERE99')
      setPosts(postData)
      setPostsLoaded(true)      
    })
  }, [sortMutuallyExclusive1, sortMutuallyExclusive2, sortCutoffLocation, sortRemote, payAboveNumberHOURLY, payAboveNumberONETIME, loadedLocation])

  const sortRecent = order => {
    if (order === '') {
      console.log('Filter cleared')
      setPosts(originalSorted)
    } else if (order === 'business' || order === 'personal') {
      firebase.firestore().collection('projects').get().then(querySnapshot => {
        const postData = []
        setPosts('')
        querySnapshot.forEach(doc => {
          /*
          Determines if a post is expired or not
        */
          let currentDate = new Date()

          let firebaseDate = new Date(1970, 0, 1)
          firebaseDate.setSeconds(doc.data().creation.seconds)

          let timeDifference = currentDate.getTime() - firebaseDate.getTime()

          let daysDifference = timeDifference / (1000 * 60 * 60 * 24)
          
          let distanceBetween = haversine({ latitude: currentLat, longitude: currentLng }, { latitude: doc.data().lat, longitude: doc.data().lng }, { unit: 'mile' }).toFixed(1)


          if (daysDifference <= parseInt(doc.data().postDuration)) {
            if ((doc.data().category === filter || filter === '' || doc.data().category.indexOf(filter) !== -1) && doc.data().type === order) {
              if (!doc.data().hidden) {
                if (searchResults === '') {
                  postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
                } else {
                  for (let i = 0; i < searchResults.length; i++) {
                    if (searchResults[i].id === doc.data().id) {
                      postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
                    }
                  }
                }
              }
            }
          } else {
            //Do not show on Dashboard, but Show on Account Screen with a notice saying its expired
            //console.log('This document is expired')
          }
        })
        console.log('HERE99')
        setPosts(postData)
        setPostsLoaded(true)
      })
    } else if (order === 'highestPay') {
      setPosts('')
      const tempArray = posts
      setPosts(tempArray.sort(function (a, b) { return b.hourlyWage - a.hourlyWage }))
      setPostsLoaded(true)
    } else if (order === 'location') {
      setPosts('')
      console.log(posts)
      const tempArray = posts
      console.log(originalSorted)
      setPosts(tempArray.sort(function(a,b) {return a.distance - b.distance }))
      setPostsLoaded(true)
      console.log('here!!')
    } else {
      firebase.firestore().collection('projects').orderBy("creation", order).get().then(querySnapshot => {
        const postData = []
        setPosts('')
        querySnapshot.forEach(doc => {
          /*
            Determines if a post is expired or not
          */
          let currentDate = new Date()

          let firebaseDate = new Date(1970, 0, 1)
          firebaseDate.setSeconds(doc.data().creation.seconds)

          let timeDifference = currentDate.getTime() - firebaseDate.getTime()
          
          let distanceBetween = haversine({ latitude: currentLat, longitude: currentLng }, { latitude: doc.data().lat, longitude: doc.data().lng }, { unit: 'mile' }).toFixed(1)

          let daysDifference = timeDifference / (1000 * 60 * 60 * 24)


          if (daysDifference <= parseInt(doc.data().postDuration)) {
            //Show on Dashboard and Account Screen
            if (doc.data().category === filter || filter === '' || doc.data().category.indexOf(filter) !== -1) {
              if (!doc.data().hidden) {
                if (searchResults === '') {
                  postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
                } else {
                  for (let i = 0; i < searchResults.length; i++) {
                    if (searchResults[i].id === doc.data().id) {
                      postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
                    }
                  }
                }
              }
            }
          } else {
            //Do not show on Dashboard, but Show on Account Screen with a notice saying its expired
            //console.log('This document is expired')
          }
        })
        setPosts(postData)
        setPostsLoaded(true)
      })
    }
  }

  useEffect(() => {
    if (loggedIn) {
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('userSaved').get().then(querySnapshot => {
        const savedPostData = []
        querySnapshot.forEach(doc => {
          savedPostData.push({ ...doc.data(), id: doc.id })

        })
        setSavedPostIDs(savedPostData)
        console.log('savedPostsData', savedPostData)
      })
    }
  }, [loggedIn, savesUpdated])

  useEffect(() => {
    if(loggedIn) {
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get().then(doc => {

        //seperate try and catches for seperate values
        try {
          console.log(doc.data().jobTitle) //this alone throws an error if undefined
        } catch(err) {
          console.log('null')
        }
         
        
      })
    }
  }, [loggedIn])


  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
    })
  }, [])

  useEffect(() => {
    firebase.firestore().collection('spotlights').get().then(querySnapshot => {
      const spotlightData = []
      setSpotlights('')
      querySnapshot.forEach(doc => {
        spotlightData.push({ ...doc.data(), id: doc.id })
      })
      setSpotlights(spotlightData)
    }).then(() => console.log('spotlights loaded')).catch(err => console.log('Error retrieving spotlights from firebase: ', err.message))
  }, [])

  const [clearFilter, setClearFilter] = useState(false)

  useEffect(() => {
    //If there is a search filter, only show that result
    if (searchFilter !== '') { //removed && postsLoaded
      setPosts('')
      firebase.firestore().collection('projects').doc(searchFilter).get().then(doc => {
        let distanceBetween = haversine({latitude: currentLat, longitude: currentLng }, {latitude: doc.data().lat, longitude: doc.data().lng}, {unit: 'mile'}).toFixed(1)
        setPosts([{ ...doc.data(), id: doc.id, distance: distanceBetween }])
        console.log('POSTSSS', posts)
        setPostsLoaded(true)
      }).catch(err => console.log('er2', err.message))
    } else {
      firebase.firestore().collection('projects').get().then(querySnapshot => {
        const postData = []
        setPosts('')

        querySnapshot.forEach(doc => {
          /*
            Determines if a post is expired or not
          */
          let currentDate = new Date()

          let firebaseDate = new Date(1970, 0, 1)
          firebaseDate.setSeconds(doc.data().creation.seconds)

          let timeDifference = currentDate.getTime() - firebaseDate.getTime()

          let distanceBetween = haversine({latitude: currentLat, longitude: currentLng }, {latitude: doc.data().lat, longitude: doc.data().lng}, {unit: 'mile'}).toFixed(1)
          
          
          let daysDifference = timeDifference / (1000 * 60 * 60 * 24)


          if (daysDifference <= parseInt(doc.data().postDuration)) {
            //Show on Dashboard and Account Screen
            if (doc.data().category === filter || filter === '' || doc.data().category.indexOf(filter) !== -1) {
              if (!doc.data().hidden) {
                if (searchResults === '') {
                  postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
                } else {
                  for (let i = 0; i < searchResults.length; i++) {
                    if (searchResults[i].id === doc.id) {
                      postData.push({ ...doc.data(), id: doc.id, distance: distanceBetween })
                      console.log('4')
                    }
                    console.log('2,', i, searchResults[i].id, doc.id)
                  }
                }
              }
            }
          } else {
            //Do not show on Dashboard, but Show on Account Screen with a notice saying its expired
            //console.log('This document is expired')
          }

        })
        setPosts(postData)
        setPostsLoaded(true)
        setOriginalSorted(postData)
      }).catch(err => console.log('errrrr', err.message))
    }
  }, [recentlyDeleted, filter, searchFilter, loadedLocation, clearFilter])

  useEffect(() => {
    if (filter === '') {
      setQuickTags([
        { tagName: "spacer" },
        { tagName: "Delivery", imageLocation: delivery, tagCode: 'delivery' },
        { tagName: "Retail", imageLocation: retail, tagCode: 'retail' },
        { tagName: "Landscaping", imageLocation: landscaping, tagCode: 'landscaping' },
        { tagName: "Computer Skills", imageLocation: computer, tagCode: 'computer' },
        { tagName: "Home Services", imageLocation: home, tagCode: 'home' },
        { tagName: "Mechanical/Trade", imageLocation: trade, tagCode: 'trade' },
        { tagName: "Pet Care", imageLocation: pet, tagCode: 'pet' },
        { tagName: "Warehouse", imageLocation: warehouse, tagCode: 'warehouse' },
        { tagName: "Maintenance", imageLocation: maintenance, tagCode: 'maintenance' },
        { tagName: "Food/Restaurant", imageLocation: restaurant, tagCode: 'restaurant' },
        { tagName: "Customer Service", imageLocation: customerService, tagCode: 'customerService' }
      ])
    }
    
    if (filter !== '') {
      for (let i = 0; i < quickTags.length; i++) {
        if (quickTags[i].tagCode === filter) {
          setQuickTags([ { tagName: "spacer" }, quickTags[i], { tagName: "delete" },])
        }
      }
    }
    
  }, [filterClicked])

  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])
  

  const getDeleteOrSaved = item => {

    let isSaved = false

    for (let i = 0; i < savedPostIDs.length; i++) {
      if (savedPostIDs[i].id === item.id) {
        isSaved = true
        console.log('hi222')
      }
    }

    console.log('isSaved', isSaved)

    if (loggedIn) {
      return (
        <div>
          {item.user === firebase.auth().currentUser.uid ? (
            <div style={{ position: 'absolute', zIndex: 15, width: 30, height: 30, top: 240, right: '6%' }} onClick={() => firebase.firestore().collection('projects').doc(item.id).delete().then(() => setRecentlyDeleted(recentlyDeleted + 1))}>
              <Icon path={mdiDelete} onMouseEnter={() => setIsHoveringClose(true)} onMouseLeave={() => setIsHoveringClose(false)} title='Delete' size={'24px'} style={{ padding: 3, color: 'black', cursor: isHoveringClose ? 'pointer' : 'cursor', borderRadius: 24, }} />
            </div>
          ) :
            <div>
             {(loggedIn && loaded) && (<Icon title="Save this posting" path={isSaved ? mdiBookmark  : mdiBookmarkOutline} size='26px' style={{ color: item.id === bookmarkHovering ? 'black' : 'rgba(0,0,0,.3)', cursor: item.id === bookmarkHovering ? 'pointer' : 'cursor', position: 'absolute', zIndex: 15, top: 240, right: '6%' }} onMouseEnter={() => setBookmarkHovering(item.id)} onMouseLeave={() => setBookmarkHovering('')} onClick={() => saveThisPosting(item.id, isSaved)} />)}
            </div>
          }
        </div>
      )
    } else {
      return (
        <Link to='/signup'>
          <Icon title="Create an account to save jobs" path={mdiBookmarkOutline} size='26px' style={{ color: item.id === bookmarkHovering ? 'black' : 'rgba(0,0,0,.3)', cursor: item.id === bookmarkHovering ? 'pointer' : 'cursor', position: 'absolute', zIndex: 15, top: 170, right: '6%' }} /> 
        </Link>
    )}
  }

  const getFullTimeValues = arr => {
    if (arr === 'Full time' || arr === 'Part time' || arr === 'Flexible' || arr === 'One-time job' || arr.length === 1) {
      return arr
    }
    let string = ''
    for (let i = 0; i < arr.length; i++) {
      string += (i !== (arr.length - 1) ? "" : " & ") + arr[i] + (i !== (arr.length - 1) ? ", " : ".")
    }
    return string
  }

  const saveThisPosting = (idToBeSaved, alreadySaved) => {
    if (alreadySaved) {
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('userSaved').doc(idToBeSaved).delete().then(() => {
        setSavesUpdated(savesUpdated + 1)
      })
    } else {
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('userSaved').doc(idToBeSaved).set({
        idOfSavedPost: idToBeSaved,
        savedOn: firebase.firestore.FieldValue.serverTimestamp(),
      }).then(() => {
        setSavesUpdated(savesUpdated + 1)
      })
    }
    
  }



  const tryToClose = () => {
    if (!doNotClose) {
      setOpenedPosting('')
      setImageAt(0)
    } else if (isHoveringClose) {
      setOpenedPosting('')
      setDoNotClose(false)
      setIsHoveringClose(false)
      setImageAt(0)
    }
  }


  const getPosts = from => {
    //on click of an element it makes it almost full screen and you can see all the details and sign up!
    if ((posts.length === 0 || posts == undefined || posts === null) && postsLoaded) {
      return (
        <div style={{ height: 120, backgroundColor: 'rgba(255, 0, 0, .08)', width: `${100}%`, padding: 0, marginBottom: 200, borderRadius: 10 }}>
          <p style={{ fontFamily: 'Inter', fontWeight: 600, color: 'rgba(255,0,0,.8)', fontSize: 20, padding: 0, margin: 0, lineHeight: '120px', textAlign: 'center'}}>{filter === '' ? 'There are currently no job postings, check back later!' : 'There are currently no job postings for this filter.'}</p>
        </div>
      )
    }

    let postsToShow = posts
    //now if statement here if sort === 'recent'
    if (from === 'recent') {
      
    }

    return Array.from(postsToShow.slice(0, loadMoreBy)).map(item => (
    <div key={item.id}>
        {openedPosting === item.id ? ( 
          <div>
            <div onClick={() => tryToClose()} style={{ position: 'fixed', padding: 0, margin: 0, top: 0, height: '100%', left: 0, width: '100%', background: 'rgba(0,0,0,.4)', zIndex: 4 }}>
        <div style={{ marginRight: 30, width: screenSize === 'Large' ? '40vw' : screenSize === 'Medium' ? '50vw' : screenSize === 'Small' ? '70vw' : '80vw', height: '80vh', position: 'absolute', backgroundColor: 'white', overflowY: 'scroll', overflowX: 'hidden', top: '10vh', left: screenSize === 'Large' ? '32.5vw' : screenSize === 'Medium' ? '25vw' : screenSize === 'Small' ? '12vw' : '9vw', borderRadius: 15, zIndex: 6 }} onMouseEnter={() => setDoNotClose(true)} onMouseLeave={() => setDoNotClose(false)} onClick={() => setOpenedPosting(item.id)}> 
                {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
                  <div style={{ width: '100%', position: 'relative', backgroundImage: `url(${item.thumbnail[imageAt]})`, backgroundSize: 'cover', backgroundPosition: 'center', height: 225, borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
  

                  </div>
          ):
            <div style={{ width: '100%', height: 225, borderTopLeftRadius: 15, borderTopRightRadius: 15, backgroundColor: '#ededed' }} />
                }
                <Icon path={mdiClose} onMouseEnter={() => setIsHoveringClose(true)} onMouseLeave={() => setIsHoveringClose(false)} onClick={() => { setOpenedPosting(''); setDoNotClose(false)}} title='Close' size={1} style={{ padding: 3, color: 'black', backgroundColor: isHoveringClose ? 'lightgrey' : 'white', cursor: isHoveringClose ? 'pointer' : 'cursor', borderRadius: 24, position: 'absolute', right: 10, top: 10}} />
                <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0, marginLeft: `8%`, marginRight: '15%' }}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, marginLeft: `8%` }}>${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`} &#183; {item.address}</p>
                {item.description === '' ? (<div />) : <p style={{ fontFamily: 'Inter', fontWeight: 400, marginLeft: `8%`, marginRight: `8%`, marginBottom: 10 }}>Job Description: {item.description}</p>}

                <div style={{ width: screenSize === 'Large' ? '34vw' : screenSize === 'Medium' ? '42vw' : screenSize === 'Small' ? '60vw' : '68vw', backgroundColor: 'white', marginLeft: `8%`, marginTop: 0, display: 'flex', flexDirection: (item.businessCity === '' && item.businessState === '' && item.businessStreet === '' && item.businessZip === '') ? 'column' : 'row', justifyContent: 'space-between' }}>

                  <p style={{
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: 14,
                    margin: "10px 0px 0px 0px", padding: 0
                  }}>
                    Contact Information
                  </p>

                  <div style={{
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: 1,
                    marginTop: 0,
                    color: 'rgba(0,0,0,.4)',
                    textAlign: 'left'
                  }}>
                    <p>Name: {item.contactName}</p>
                    <p>Phone: {item.contactPhone}</p>
                    <p>Email: {item.contactEmail}</p>
                    {item.businessWebsite && (<p>Business Website: <a target="_blank" href={item.businessWebsite.substr(0,4)==='http' ? item.businessWebsite :'https://' + item.businessWebsite}>{item.businessWebsite}</a></p>)}
                    {item.businessApplication && (<p>Business Application: <a target="_blank" href={item.businessApplication.substr(0,4)==='http' ? item.businessApplication :'https://' + item.businessApplication}>{item.businessApplication}</a></p>)}
                  </div>

                  <p style={{
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: 14,
                    margin: "10px 0px 0px 0px", padding: 0
                  }}>
                    Additional Details
                  </p>
                  
                  <div style={{
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: 1,
                    marginTop: 0,
                    color: 'rgba(0,0,0,.4)',
                    textAlign: 'left'
                  }}>
                    <p>Employment: {getFullTimeValues(item.fullTime)}</p>
                    {item.tips && (<p>May receive tips</p>)}
                    
                    <br></br>
                  </div>
                </div>

                
                    
                
                {getDeleteOrSaved(item)}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                <p style={{ marginLeft: '8%', fontFamily: 'Inter', fontWeight: 800, color: 'rgba(0,0,0,.13)', marginBottom: 30 }}>Posting #: <span style={{ color: 'rgba(0,0,0,.13)', fontWeight: 600 }}>THW{item.id}</span></p>
                  {/* <Link to='/report'>
                    <p>Report</p>
                </Link> */}
                  <a target="_blank" href={`https://karalewich.com/thehourlyworker-feedback`} rel="noopener noreferrer" style={{ marginRight: '4%', marginTop: 18, fontFamily: 'Inter', fontWeight: 600, textDecoration: 'underline', fontSize: 12, color: 'rgba(0,0,0,.15)' }}>Report Posting</a>
                </div>
              </div >
            </div>
            {/* Copied below so that all the postings don't shift when you open one up */}
            
      <div style={{ marginRight: 30, width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw',  }} onClick={() => setOpenedPosting(item.id)}> 
        {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
          <div style={{  width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center', }} />
        ):
          <div style={{ width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundColor: '#ededed' }} />
        }
        <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0}}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, paddingBottom: 30 }}>{haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) === 'NaN' ? item.contactName : haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) + "mi"} &#183; ${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
      </div >
        </div>
      ) :
          <div style={{ marginRight: 30, position: 'relative',width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', backgroundColor: (isHoveringPost === item.id && sectionHovering === from) ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,0)', cursor: isHoveringPost === item.id ? 'pointer' : 'default', borderRadius: 5 }} onMouseEnter={() => { setIsHoveringPost(item.id); setSectionHovering(from) }} onMouseLeave={() => { setIsHoveringPost(false);   setSectionHovering('')}}
            onClick={() => {setOpenedPosting(item.id); setIsHoveringPost(false);}}> 
            {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
              <div style={{  width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center',}} />
        ):
          <div style={{ width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundColor: '#ededed' }} />
            }
        <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0}}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
            <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, paddingBottom: 30 }}>{haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) === 'NaN' ? item.contactName : haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) + "mi"} &#183; ${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
            {/* <DistanceMatrixService
              options={{
                //now I need to convert the ADDRESSES to lat/lng
                destinations: [{lat:1.296788, lng:103.778961}],
                origins: [{lng:103.780267, lat:1.291692}],
                travelMode: "DRIVING",
              }}
              callback={(response) => { 
                setDistance([ { id: item.id, distance: response.rows[0].elements[0].distance.value / 1609.344 }, ...distance])}}
            /> */}
{/*               <DistanceMatrixService
                options={{
                  //now I need to convert the ADDRESSES to lat/lng
                  destinations: [{ lat: 41.0023472, lng: -74.0110768 }],
                  origins: [{ lng: 40.745255, lat: -74.034775 }],
                  travelMode: "DRIVING",
                }}
                callback={(response) => {
                  //setDistance([{ id: item.id, distance: response.rows[0].elements[0].distance.value / 1609.344 }, ...distance])
                  console.log('38', response.rows[0], item.lat, item.lng)

                }}
              /> */}
          </div >
        }
    </div>
    ))
  }

const createItems = (length, [handleClick]) => {
    let deltaX = 0;
    let difference = 0;
    const swipeDelta = 20;

    return Array.from({ length }).map((item, i) => (
        <div
            data-value={i + 1}
            className="item"
            onMouseDown={(e) => (deltaX = e.pageX)}
            onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
            onClick={() => (difference < swipeDelta) && handleClick(i)}
        >
            <span className="item-inner" />
        </div>
    ));
};

  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  /*

        { tagName: "Delivery", imageLocation: delivery, tagCode: 'delivery' },
        { tagName: "Retail", imageLocation: retail, tagCode: 'retail' },
        { tagName: "Landscaping", imageLocation: landscaping, tagCode: 'landscaping' },
        { tagName: "Computer Skills", imageLocation: computer, tagCode: 'computer' },
        { tagName: "Home Services", imageLocation: home, tagCode: 'home' },
        { tagName: "Mechanical/Trade", imageLocation: trade, tagCode: 'trade' },
        { tagName: "Pet Care", imageLocation: pet, tagCode: 'pet' },
        { tagName: "Warehouse", imageLocation: warehouse, tagCode: 'warehouse' },
        { tagName: "Maintenance", imageLocation: maintenance, tagCode: 'maintenance' },
        { tagName: "Food/Restaurant", imageLocation: restaurant, tagCode: 'restaurant' },
        { tagName: "Customer Service", imageLocation: customerService, tagCode: 'customerService' }

  */
  
  /*
    <div onMouseEnter={() => setTagsHovering(item.tagName)} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(item.tagCode);  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === item.tagName ? 'pointer' : 'default' }}>
      <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${item.imageLocation})`, backgroundSize: 'cover'}} />
      <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>{item.tagName}</p>
    </div>
  */
  


  const tagComponents = [
    <div style={{ width: 185 }}>
      <div onMouseEnter={() => setTagsHovering('Computer Skills')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'computer' ? '' : 'computer'); setFilterClicked(filterClicked + 1) }} style={{ backgroundColor: 'white', paddingLeft: 6, opacity: (filter === 'computer' || filter === '') ? 1 : .3, paddingRight: 15, height: 51, borderRadius: 100, width: 155, margin: '0px 80px 0px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Computer Skills' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${computer})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Computer Skills</p>
      </div>
    </div>,
    <div style={{ width: 200 }}>
      <div onMouseEnter={() => setTagsHovering('Customer Service')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'customerService' ? '' : 'customerService');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, opacity: (filter === 'customerService' || filter === '') ? 1 : .3, paddingRight: 15, height: 51, borderRadius: 100, width: 170, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Customer Service' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${customerService})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Customer Service</p>
      </div>
    </div>,
    <div style={{ width: 130 }}>
      <div onMouseEnter={() => setTagsHovering('Delivery')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'delivery' ? '' : 'delivery');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, opacity: (filter === 'delivery' || filter === '') ? 1 : .3,paddingRight: 15, height: 51, width: 100, borderRadius: 100, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Delivery' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${delivery})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Delivery</p>
      </div>
    </div>,
    <div style={{ width: 195 }}>
      <div onMouseEnter={() => setTagsHovering('Food/Restaurant')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'restaurant' ? '' : 'restaurant');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, opacity: (filter === 'restaurant' || filter === '') ? 1 : .3,paddingRight: 15, height: 51, borderRadius: 100, width: 165, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Food/Restaurant' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${restaurant})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Food/Restaurant</p>
      </div>
    </div>,
    <div style={{ width: 160 }}>
      <div onMouseEnter={() => setTagsHovering('Health Care')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'health' ? '' : 'health');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, opacity: (filter === 'health' || filter === '') ? 1 : .3,paddingRight: 15, height: 51, borderRadius: 100, width: 130, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Health Care' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${health})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Health Care</p>
      </div>
    </div>,
    <div style={{ width: 180 }}>
      <div onMouseEnter={() => setTagsHovering('Home Services')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'home' ? '' : 'home');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, opacity: (filter === 'home' || filter === '') ? 1 : .3,paddingRight: 15, height: 51, borderRadius: 100, width: 150, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Home Services' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${home})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Home Services</p>
      </div>
    </div>,
    <div style={{ width: 165 }}>
      <div onMouseEnter={() => setTagsHovering('Landscaping')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'landscaping' ? '' : 'landscaping');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white',opacity: (filter === 'landscaping' || filter === '') ? 1 : .3, paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, width: 135, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Landscaping' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${landscaping})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Landscaping</p>
      </div>
    </div>,
    <div style={{ width: 170 }}>
      <div onMouseEnter={() => setTagsHovering('Maintenance')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'maintenance' ? '' : 'maintenance');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6,opacity: (filter === 'maintenance' || filter === '') ? 1 : .3, paddingRight: 15, height: 51, borderRadius: 100, width: 140, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Maintenance' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${maintenance})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Maintenance</p>
      </div>
    </div>,
    <div style={{ width: 190 }}>
      <div onMouseEnter={() => setTagsHovering('Marketing/Sales')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'marketing' ? '' : 'marketing');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', opacity: (filter === 'marketing' || filter === '') ? 1 : .3,paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, width: 160, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Marketing/Sales' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${marketing})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Marketing/Sales</p>
      </div>
    </div>,
    <div style={{ width: 200 }}>
      <div onMouseEnter={() => setTagsHovering('Mechanical/Trade')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'trade' ? '' : 'trade');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white',opacity: (filter === 'trade' || filter === '') ? 1 : .3, paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, width: 170, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Mechanical/Trade' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${trade})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Mechanical/Trade</p>
      </div>
    </div>,
    <div style={{ width: 175 }}>
      <div onMouseEnter={() => setTagsHovering('Miscellaneous')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'other' ? '' : 'other');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6,opacity: (filter === 'other' || filter === '') ? 1 : .3, paddingRight: 15, height: 51, borderRadius: 100, width: 145, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Miscellaneous' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${other})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Miscellaneous</p>
      </div>
    </div>,
    <div style={{ width: 180 }}>
      <div onMouseEnter={() => setTagsHovering('Office Support')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'office' ? '' : 'office');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6,opacity: (filter === 'office' || filter === '') ? 1 : .3, paddingRight: 15, height: 51, borderRadius: 100, width: 150, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Office Support' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${office})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Office Support</p>
      </div>
    </div>,
    <div style={{ width: 175 }}>
      <div onMouseEnter={() => setTagsHovering('Personal Care')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'personal' ? '' : 'personal');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', opacity: (filter === 'personal' || filter === '') ? 1 : .3, paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, width: 145, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Personal Care' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${personal})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Personal Care</p>
      </div>
    </div>,
    <div style={{ width: 140 }}>
    <div onMouseEnter={() => setTagsHovering('Pet Care')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'pet' ? '' : 'pet');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white',opacity: (filter === 'pet' || filter === '') ? 1 : .3, paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, width: 110, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Pet Care' ? 'pointer' : 'default' }}>
      <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${pet})`, backgroundSize: 'cover'}} />
      <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Pet Care</p>
      </div>
    </div>,
    <div style={{ width: 175 }}>
      <div onMouseEnter={() => setTagsHovering('Physical Work')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'physical' ? '' : 'physical');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', opacity: (filter === 'physical' || filter === '') ? 1 : .3, paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, width: 145, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Physical Work' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${physical})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Physical Work</p>
      </div>
    </div>,
    <div style={{ width: 115 }}>
      <div onMouseEnter={() => setTagsHovering('Retail')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'retail' ? '' : 'retail');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6,opacity: (filter === 'retail' || filter === '') ? 1 : .3, paddingRight: 15, height: 51, borderRadius: 100, width: 85, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Retail' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${retail})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Retail</p>
      </div>
    </div>,
    <div style={{ width: 150 }}>
      <div onMouseEnter={() => setTagsHovering('Warehouse')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(filter === 'warehouse' ? '' : 'warehouse');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6,opacity: (filter === 'warehouse' || filter === '') ? 1 : .3, paddingRight: 15, height: 51, borderRadius: 100, width: 120, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'Warehouse' ? 'pointer' : 'default' }}>
        <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${warehouse})`, backgroundSize: 'cover'}} />
        <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Warehouse</p>
      </div>
    </div>,
  ];

  if (!loaded || !postsLoaded) return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 60px)', backgroundColor: '#ededed', padding: 0 }}>
      <img src={loadingLogo} style={{ width: 100, height: 100, marginBottom: 30, marginTop: -40 }} />
      
      <BarLoader
        color={'#d7d7d7'}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )

  return (
    <body>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com"/><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
      </head>
      
      <div>
        {/* Start Tags */}
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{ width: '73%', paddingLeft: '13%', paddingRight: '14%', backgroundColor: '#ededed', height: 80 }}>
              <AliceCarousel
                disableDotsControls
                disableButtonsControls
                mouseTracking
                autoWidth
                items={tagComponents}
                activeIndex={activeIndex}
                onSlideChanged={syncActiveIndex}
            />
            {activeIndex > 0 && (
            <Icon path={mdiChevronLeft} size={'24px'} style={{position: 'absolute', boxShadow: '2px 2px 4px rgba(0,0,0,.05)', left: '10%', top: 87.5 + 28, width: 35, height: 35, backgroundColor: 'white', color: 'black', borderWidth: 0, borderRadius: 40 }}  onClick={slidePrev} />

            )}
            {activeIndex < 12 && (
              <Icon path={mdiChevronRight} size={'24px'} style={{position: 'absolute', boxShadow: '2px 2px 4px rgba(0,0,0,.05)', right: '10%', top: 87.5 + 28, width: 35, height: 35, backgroundColor: 'white', color: 'black', borderWidth: 0, borderRadius: 40 }}  onClick={slideNext} />
            )}
            </div>
        </div>
        <div>

            {/* QUICK TAGS ROW 1 */}
              <div style={{ backgroundColor: 'black', display: 'none', minWidth: '100%', height: 80, marginBottom: 0, padding: 0 }}>
                {/* <div style={{ width: `${100 - outerMargins}%`, overflow: 'hidden', marginBottom: 0, padding: 0 }}>
                  <HorizontalScroll style={{ marginLeft: `${outerMargins}vw`, width: `${100 - (outerMargins)}vw`, marginBottom: 0, padding: 0 }}>
                    {quickTags.map(item =>
                      item.tagName === 'spacer' ? (
                        <div style={{ backgroundColor: '#ededed', width: `${outerMargins}vw`, height: 51, borderRadius: 100, marginRight: 10 }}/>
                      ) : item.tagName === 'delete' ? (
                        <div onMouseEnter={() => setTagsHovering('delete')} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter('');  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === 'delete' ? 'pointer' : 'default' }}>
                          <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${clear})`, backgroundSize: 'cover'}} />
                          <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>Clear tag</p>
                        </div>
                      ) :
                        <div onMouseEnter={() => setTagsHovering(item.tagName)} onMouseLeave={() => setTagsHovering('')} onClick={() => { setFilter(item.tagCode);  setFilterClicked(filterClicked + 1)}} style={{ backgroundColor: 'white', paddingLeft: 6, paddingRight: 15, height: 51, borderRadius: 100, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 15, marginBottom: 0, cursor: tagsHovering === item.tagName ? 'pointer' : 'default' }}>
                          <div style={{ width: 35, height: 35, borderRadius: 50, marginRight: 15, backgroundImage: `url(${item.imageLocation})`, backgroundSize: 'cover'}} />
                          <p style={{ textAlign: 'center', alignSelf: 'center', fontFamily: 'Inter', fontSize: 14 }}>{item.tagName}</p>
                        </div>
                  )}
                  </HorizontalScroll>
                </div> */}

                        {/* Plus add the height of the top bar for notifications */}
                <div style={{ backgroundColor: '#ededed', position: 'absolute', width: `${outerMargins}%`, height: 80, left: 0, top: 60 + 28 }} /> 
                <div style={{ backgroundColor: '#ededed', position: 'absolute', width: `${outerMargins}%`, height: 80, right: 0, top: 60 + 28 }} />
                  
          </div>

              <div style={{  width: '100%', backgroundColor: 'white'}}>
                {/* <div style={{ height: 100, marginLeft: `${outerMargins}%`, marginRight: `${outerMargins}%`, backgroundColor: 'rgba(0,0,0,.05)', borderWidth: 1, borderColor: 'black', borderStyle: 'solid', marginTop: 10, marginBottom: 20 }}>
                  <p>Second Row of tags</p>
                </div> */}

              {/* <div style={{ height: 35, marginLeft: `${outerMargins}%`, marginRight: `${outerMargins}%`, backgroundColor: 'white', marginTop: 15, marginBottom: 0, paddingTop: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              {(screenSize === 'Small' || screenSize === 'Extra small') ? (<div />) :
                <div style={{ height: 25, backgroundColor: sort === 'recent' ? 'grey' : sort !== '' ? 'rgba(0,0,0,.1)' : filterHovering === 'recent' ? '#cdcdcd' : '#ededed', cursor: filterHovering === 'recent' ? 'pointer' : 'default', borderRadius: 100, margin: 0, padding: 0, boxShadow: '2px 2px 4px rgba(0,0,0,.1)', borderColor: 'rgba(0,0,0,.05)', borderStyle: 'solid', borderWidth: '.5px', width: 87, marginRight: 15 }} onMouseEnter={() => setFilterHovering('recent')} onMouseLeave={() => setFilterHovering('')} onClick={() => { sortRecent(sort === 'recent' ? '' : 'asc'); setSort(sort === 'recent' ? '' : 'recent') }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Icon path={mdiClockTimeEight} size={'15px'} style={{ marginLeft: 10, color: sort === '' ? 'black' : sort === 'recent' ? 'black' : 'rgba(0,0,0,.3)' }} />
                    <p style={{ margin: 0, lineHeight: '25px', fontFamily: 'Inter', fontWeight: 600, paddingLeft: 4, paddingRight: 8, fontSize: 14, color: sort === '' ? 'black' : sort === 'recent' ? 'black' : 'rgba(0,0,0,.3)' }}>Oldest</p>
                  </div>
                </div>
              }
              {(screenSize === 'Small' || screenSize === 'Extra small') ? (<div />) :
                <div style={{ height: 25, backgroundColor: sort === 'oldest' ? 'grey' : sort !== '' ? 'rgba(0,0,0,.1)' : filterHovering === 'oldest' ? '#cdcdcd' : '#ededed', cursor: filterHovering === 'oldest' ? 'pointer' : 'default', borderRadius: 100, margin: 0, padding: 0, boxShadow: '2px 2px 4px rgba(0,0,0,.1)', borderColor: 'rgba(0,0,0,.05)', borderStyle: 'solid', borderWidth: '.5px', width: 130, marginRight: 15 }} onMouseEnter={() => setFilterHovering('oldest')} onMouseLeave={() => setFilterHovering('')} onClick={() => { sortRecent(sort === 'oldest' ? '' : 'desc'); setSort(sort === 'oldest' ? '' : 'oldest') }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Icon path={mdiClockTimeEight} size={'15px'} style={{ marginLeft: 10, color: sort === '' ? 'black' : sort === 'oldest' ? 'black' : 'rgba(0,0,0,.3)' }} />
                    <p style={{ margin: 0, lineHeight: '25px', fontFamily: 'Inter', fontWeight: 600, paddingLeft: 4, paddingRight: 8, fontSize: 14, color: sort === '' ? 'black' : sort === 'oldest' ? 'black' : 'rgba(0,0,0,.3)' }}>Most Recent</p>
                  </div>
                </div>
              }
              {(screenSize === 'Extra small') ? (<div />) :
                <div style={{ height: 25, backgroundColor: sort === 'business' ? 'grey' : sort !== '' ? 'rgba(0,0,0,.1)' : filterHovering === 'business' ? '#cdcdcd' : '#ededed', cursor: filterHovering === 'business' ? 'pointer' : 'default', borderRadius: 100, margin: 0, padding: 0, boxShadow: '2px 2px 4px rgba(0,0,0,.1)', borderColor: 'rgba(0,0,0,.05)', borderStyle: 'solid', borderWidth: '.5px', width: 100, marginRight: 15 }} onMouseEnter={() => setFilterHovering('business')} onMouseLeave={() => setFilterHovering('')} onClick={() => { sortRecent(sort === 'business' ? '' : 'business'); setSort(sort === 'business' ? '' : 'business') }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Icon path={mdiDomain} size={'15px'} style={{ marginLeft: 10, color: sort === '' ? 'black' : sort === 'business' ? 'black' : 'rgba(0,0,0,.3)' }} />
                    <p style={{ margin: 0, lineHeight: '25px', fontFamily: 'Inter', fontWeight: 600, paddingLeft: 4, paddingRight: 8, fontSize: 14, color: sort === '' ? 'black' : sort === 'business' ? 'black' : 'rgba(0,0,0,.3)' }}>Business</p>
                  </div>
                </div>
              }
              {screenSize === 'Extra small' ? (<div />) :
                <div style={{ height: 25, backgroundColor: sort === 'personal' ? 'grey' : sort !== '' ? 'rgba(0,0,0,.1)' : filterHovering === 'personal' ? '#cdcdcd' : '#ededed', cursor: filterHovering === 'personal' ? 'pointer' : 'default', borderRadius: 100, margin: 0, padding: 0, boxShadow: '2px 2px 4px rgba(0,0,0,.1)', borderColor: 'rgba(0,0,0,.05)', borderStyle: 'solid', borderWidth: '.5px', width: 98, marginRight: 15 }} onMouseEnter={() => setFilterHovering('personal')} onMouseLeave={() => setFilterHovering('')} onClick={() => { sortRecent(sort === 'personal' ? '' : 'personal'); setSort(sort === 'personal' ? '' : 'personal') }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Icon path={mdiAccount} size={'15px'} style={{ marginLeft: 10, color: sort === '' ? 'black' : sort === 'personal' ? 'black' : 'rgba(0,0,0,.3)' }} />
                    <p style={{ margin: 0, lineHeight: '25px', fontFamily: 'Inter', fontWeight: 600, paddingLeft: 4, paddingRight: 8, fontSize: 14, color: sort === '' ? 'black' : sort === 'personal' ? 'black' : 'rgba(0,0,0,.3)' }}>Personal</p>
                  </div>
                </div>
              }
                <div style={{ height: 25, backgroundColor: sort === 'location' ? 'grey' : sort !== '' ? 'rgba(0,0,0,.05)' : filterHovering === 'location' ? '#cdcdcd' : 'rgba(0,0,0,.05)', cursor: filterHovering === 'location' ? 'pointer' : 'default', borderRadius: 100, margin: 0, padding: 0, boxShadow: '2px 2px 4px rgba(0,0,0,.1)', borderColor: 'rgba(0,0,0,.05)', borderStyle: 'solid', borderWidth: '.5px', width: 98, marginRight: 15 }} onMouseEnter={() => setFilterHovering('location')} onMouseLeave={() => setFilterHovering('')} onClick={() => { sortRecent(sort === 'location' ? '' : 'location'); setSort(sort === 'location' ? '' : 'location'); if(sort === 'location') {setSort(''); sortRecent(''); setClearFilter(!clearFilter)}}}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Icon path={mdiMapMarker} size={'15px'} style={{ marginLeft: 10, color: sort === '' ? 'black' : sort === 'location' ? 'black' : 'rgba(0,0,0,.3)' }} />
                    <p style={{ margin: 0, lineHeight: '25px', fontFamily: 'Inter', fontWeight: 600, paddingLeft: 4, paddingRight: 8, fontSize: 14, color: sort === '' ? 'black' : sort === 'location' ? 'black' : 'rgba(0,0,0,.3)' }}>Closest</p>
                  </div>
                </div>
                <div title='This is being tested near River Vale, NJ.  In the future, this will be available everywhere!' style={{ height: 25, backgroundColor: sort === 'highestPay' ? 'grey' : sort !== '' ? 'rgba(0,0,0,.05)' : filterHovering === 'highestPay' ? '#cdcdcd' : 'rgba(0,0,0,.05)', cursor: filterHovering === 'highestPay' ? 'pointer' : 'default', borderRadius: 100, margin: 0, padding: 0, boxShadow: '2px 2px 4px rgba(0,0,0,.1)', borderColor: 'rgba(0,0,0,.05)', borderStyle: 'solid', borderWidth: '.5px', width: 124 }} onMouseEnter={() => setFilterHovering('highestPay')} onMouseLeave={() => setFilterHovering('')} onClick={() => { sortRecent(sort === 'highestPay' ? '' : 'highestPay'); setSort(sort === 'highestPay' ? '' : 'highestPay');  if(sort === 'highestPay') {setSort(''); sortRecent(''); setClearFilter(!clearFilter)}}}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Icon path={mdiCurrencyUsd } size={'17px'} style={{ marginLeft: 10, color: sort === '' ? 'black' : sort === 'highestPay' ? 'black' : 'rgba(0,0,0,.3)' }} />
                    <p style={{ margin: 0, lineHeight: '25px', fontFamily: 'Inter', fontWeight: 600, paddingLeft: 4, paddingRight: 8, fontSize: 14, color: sort === '' ? 'black' : sort === 'highestPay' ? 'black' : 'rgba(0,0,0,.3)' }}>Highest Pay</p>
                  </div>
                </div>
              {sort !== '' && (
                <p onMouseEnter={() => setFilterHovering('clear')} onMouseLeave={() => setFilterHovering('')} onClick={() => { setSort(''); sortRecent(''); setClearFilter(!clearFilter)}}  style={{ fontFamily: 'Inter', color: 'grey', fontWeight: 500, fontSize: 14, marginLeft: 15, marginTop: 13, cursor: filterHovering === 'clear' ? 'pointer' : 'default' }}>Clear</p>
              )}
                 
              </div> 
              
              */}

            {screenSize !== 'Extra small' && (
              <div style={{ marginLeft: `${outerMargins}%`, marginRight: `${outerMargins}%`, display: 'flex', flexDirection: screenSize === 'Large' ? 'row' : screenSize === 'Medium' ? 'row' : screenSize === 'Small' ? 'column' : 'column' }}>
                {Array.from(spotlights).map(item => {
                  if (item.a_isShown) {
                    return (
                      <div style={{ height: 180, boxShadow: `5px 5px 10px ${item.b_backgroundColor}50`, backgroundColor: item.b_backgroundColor, borderRadius: 9, flex: 1, marginRight: item.id === 'two' ? 0 : `${outerMargins / 10}%`, display: 'flex', flexDirection: 'row', marginTop: 30 }} >
                        {/* Content: 66% / Image: 33% */}
                        <div style={{ flex: .67, position: 'relative' }}>
                          <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: screenSize === "Large" ? 22 : screenSize === 'Medium' ? 16 : screenSize === 'Small' ? 18 : 16, color: item.c_textColor, margin: '20px 0px 0px 20px' }}>{item.e_title}</p>
                          <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 14, color: item.c_textColor, margin: '20px 0px 0px 20px' }}>{item.f_description}</p>
                          {item.k_linkExternalWebsite ? (
                            <a target="_blank" href={item.j_buttonLink} onMouseEnter={() => setSpotlightHovering(item.id)} onMouseLeave={() => setSpotlightHovering('')}>
                              <button style={{ height: 40, backgroundColor: item.d_buttonColor, margin: "20px 0px 20px 20px", borderWidth: 0, borderRadius: 100, cursor: spotlightHovering === item.id ? 'pointer' : 'default' }}>
                                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 16, padding: '3px 12px 3px 12px', color: item.c_textColor, margin: 0 }}>{item.g_buttonText}</p>
                              </button>
                            </a>
                          ) :
                            <Link to={item.j_buttonLink} onMouseEnter={() => setSpotlightHovering(item.id)} onMouseLeave={() => setSpotlightHovering('')}>
                              <button style={{ height: 40, backgroundColor: item.d_buttonColor, margin: "20px 0px 20px 20px", borderWidth: 0, borderRadius: 100, cursor: spotlightHovering === item.id ? 'pointer' : 'default' }}>
                                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 16, padding: '3px 12px 3px 12px', color: item.c_textColor, margin: 0 }}>{item.g_buttonText}</p>
                              </button>
                            </Link>
                          }
                        </div>
                        <div style={{ flex: .33, backgroundImage: `url(${item.i_imgURL})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                      
                      </div>
                    )
                  } else {
                    return <div style={{ flex: 1 }} />
                  }
                
                })}


              </div>
            )}
            
            
            <div style={{ width: `${100 - outerMargins*2}%`, marginTop: 30, marginLeft: `${outerMargins}%`, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
              <Popup trigger={
                  <button style={{ height: 28, backgroundColor: (sortCutoffLocation || sortMutuallyExclusive1 === 'Closest Jobs') ? '#378805' : '#e4e4e4', marginRight: 10, borderRadius: 100, paddingHorizonal: 13, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', outline: 0, borderWidth: 0 }}>
                    <Icon color={(sortCutoffLocation || sortMutuallyExclusive1 === 'Closest Jobs') ? 'white' : 'black'} path={mdiMapMarker} size={'18px'} style={{ marginRight: '5px' }} />
                    <p style={{ fontFamily: 'Inter', fontSize: 12, fontWeight: 600, margin: 0, padding: 0, border: 0, outline: 0, color: (sortCutoffLocation || sortMutuallyExclusive1 === 'Closest Jobs') ? 'white' : 'black' }}>Location</p>
                    <Icon color={(sortCutoffLocation || sortMutuallyExclusive1 === 'Closest Jobs') ? 'white' : 'black'} path={mdiChevronDown} size={'18px'} style={{ marginLeft: '5px' }} />
                  </button>
                }
                position='bottom center'>
                <div style={{ display: 'flex', marginLeft: '10px', marginRight: '10px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <input type="radio" checked={sortMutuallyExclusive1 === 'Closest Jobs'} onClick={() => setSortMutuallyExlusive1(sortMutuallyExclusive1 === 'Closest Jobs' ? '' : 'Closest Jobs')} />
                  <p style={{ marginLeft: '10px'}}>Closest Jobs</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '10px', marginRight: '10px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <input type="radio" checked={sortCutoffLocation} onClick={() => setSortCutoffLocation(!sortCutoffLocation)}  />
                  <p style={{ marginLeft: '10px' }}>Within</p>
                  <input
                    type="number"
                    value={locationNumberCutoff}
                    onChange={(event) => setLocationNumberCutoff(event.target.value)}
                    style={{
                      width: 30,
                      height: 25,
                      backgroundColor: '#e4e4e4',
                      outline: 0,
                      borderRadius: 10,
                      borderWidth: 0,
                      marginLeft: '5px',
                      textAlign: 'center'
                    }} />
                  <p style={{ marginLeft: '5px' }}> miles</p>
                </div>
                
              </Popup>

              <Popup trigger={
                <button
                  style={{
                    height: 28,
                    backgroundColor: (sortMutuallyExclusive1 === 'Hourly Pay' || sortMutuallyExclusive1 === 'One-Time Pay' || sortMutuallyExclusive2 === 'Above $X' || sortMutuallyExclusive2 === 'Above $X/hr') ? '#378805' : '#e4e4e4',
                    marginRight: 10, borderRadius: 100, paddingHorizonal: 13,
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    alignItems: 'center', outline: 0, borderWidth: 0
                  }}>
                  <Icon
                    color={(sortMutuallyExclusive1 === 'Hourly Pay' || sortMutuallyExclusive1 === 'One-Time Pay' || sortMutuallyExclusive2 === 'Above $X' || sortMutuallyExclusive2 === 'Above $X/hr') ? 'white' : 'black'}
                    path={mdiCashMultiple} size={'18px'} style={{ marginRight: '5px' }} />
                  <p
                    style={{
                      color: (sortMutuallyExclusive1 === 'Hourly Pay' || sortMutuallyExclusive1 === 'One-Time Pay' || sortMutuallyExclusive2 === 'Above $X' || sortMutuallyExclusive2 === 'Above $X/hr') ? 'white' : 'black',
                      fontFamily: 'Inter', fontSize: 12, fontWeight: 600, margin: 0, padding: 0, border: 0, outline: 0
                    }}>Highest Pay</p>
                  <Icon
                    color={(sortMutuallyExclusive1 === 'Hourly Pay' || sortMutuallyExclusive1 === 'One-Time Pay' || sortMutuallyExclusive2 === 'Above $X' || sortMutuallyExclusive2 === 'Above $X/hr') ? 'white' : 'black'}
                    path={mdiChevronDown} size={'18px'} style={{ marginLeft: '5px' }} />
                  </button>
                }
                position='bottom center'>
                <div style={{ display: 'flex', marginLeft: '10px', marginRight: '10px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <input type="radio"
                    checked={sortMutuallyExclusive1 === 'Hourly Pay'}
                    onClick={() => {
                      setSortMutuallyExlusive1(sortMutuallyExclusive1 === 'Hourly Pay' ? '' : 'Hourly Pay');
                      if(sortMutuallyExclusive2 === 'Above $X') { setSortMutuallyExlusive2('') }
                    }} />
                  <p style={{ marginLeft: '10px'}}>Hourly</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '10px', opacity: sortMutuallyExclusive1 === 'One-Time Pay' || sortMutuallyExclusive2 === 'Above $X' ? .3 : 1, marginRight: '10px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <input type="radio" disabled={sortMutuallyExclusive1 === 'One-Time Pay' || sortMutuallyExclusive2 === 'Above $X'} checked={sortMutuallyExclusive2 === 'Above $X/hr'} onClick={() => setSortMutuallyExlusive2(sortMutuallyExclusive2 === 'Above $X/hr' ? '' : 'Above $X/hr')}/>
                  <p style={{ marginLeft: '10px' }}>Above $</p>
                  <input
                    type="number"
                    value={payAboveNumberHOURLY}
                    onChange={(event) => setPayAboveNumberHOURLY(event.target.value)}
                    style={{
                      width: 30,
                      height: 25,
                      backgroundColor: '#e4e4e4',
                      outline: 0,
                      borderRadius: 10,
                      borderWidth: 0,
                      marginLeft: '1px',
                      textAlign: 'center'
                    }}
                  />
                  <p style={{ marginLeft: '0px'}}>/hr</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '10px', marginRight: '10px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <input type="radio"
                    checked={sortMutuallyExclusive1 === 'One-Time Pay'}
                    onClick={() => {
                      setSortMutuallyExlusive1(sortMutuallyExclusive1 === 'One-Time Pay' ? '' : 'One-Time Pay')
                      if (sortMutuallyExclusive2 === 'Above $X/hr') { setSortMutuallyExlusive2('')}
                    }} />
                  <p style={{ marginLeft: '10px'}}>One-Time Payment</p>
                </div>
                <div style={{ display: 'flex', marginLeft: '10px', opacity: sortMutuallyExclusive1 === 'Hourly Pay' || sortMutuallyExclusive2 === 'Above $X/hr' ? .3 : 1, marginRight: '10px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <input type="radio" disabled={sortMutuallyExclusive1 === 'Hourly Pay' || sortMutuallyExclusive2 === 'Above $X/hr'} checked={sortMutuallyExclusive2 === 'Above $X'} onClick={() => setSortMutuallyExlusive2(sortMutuallyExclusive2 === 'Above $X' ? '' : 'Above $X')} />
                  <p style={{ marginLeft: '10px' }}>Above $</p>
                  <input
                    type="number"
                    value={payAboveNumberONETIME}
                    onChange={(event) => setPayAboveNumberONETIME(event.target.value)}
                    style={{
                      width: 30,
                      height: 25,
                      backgroundColor: '#e4e4e4',
                      outline: 0,
                      borderRadius: 10,
                      borderWidth: 0,
                      marginLeft: '1px',
                      textAlign: 'center'
                    }}
                  />
                </div>
                
              </Popup>

              <button onClick={() => setSortMutuallyExlusive1(sortMutuallyExclusive1 === 'Most Recent' ? '' : 'Most Recent')}  style={{ height: 28, backgroundColor: sortMutuallyExclusive1 === 'Most Recent' ? '#378805' : '#e4e4e4', marginRight: 10, borderRadius: 100, paddingHorizonal: 13, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', outline: 0, borderWidth: 0 }}>
                <Icon path={mdiClockOutline } color={sortMutuallyExclusive1 === 'Most Recent' ? 'white' : 'black'} size={'18px'} style={{ marginRight: '5px' }} />
                <p style={{ fontFamily: 'Inter', fontSize: 12, fontWeight: 600, margin: '0px 5px 0px 0px', padding: 0, border: 0, outline: 0, color: sortMutuallyExclusive1 === 'Most Recent' ? 'white' : 'black' }}>Most Recent</p>
              </button>

              <Popup trigger={
                  <button style={{ height: 28, backgroundColor: sortRemote === 'Remote Only' ? '#378805' :'#e4e4e4', marginRight: 10, borderRadius: 100, paddingHorizonal: 13, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', outline: 0, borderWidth: 0 }}>
                    <Icon path={mdiTuneVariant } color={sortRemote === 'Remote Only' ? 'white' : 'black'} size={'18px'} style={{ marginRight: '5px' }} />
                    <p style={{ fontFamily: 'Inter', color: sortRemote === 'Remote Only' ? 'white' : 'black', fontSize: 12, fontWeight: 600, margin: 0, padding: 0, border: 0, outline: 0 }}>Type</p>
                    <Icon path={mdiChevronDown} color={sortRemote === 'Remote Only' ? 'white' : 'black'} size={'18px'} style={{ marginLeft: '5px' }} />
                  </button>
                }
                position='bottom center'>
                <div style={{ display: 'flex', marginLeft: '10px', marginRight: '10px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <input type="radio" checked={sortRemote === 'Remote Only'} onClick={() => setSortRemote(sortRemote === 'Remote Only' ? '' : 'Remote Only')} />
                  <p style={{ marginLeft: '10px'}}>Remote Only</p>
                </div>
              </Popup>

            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center', padding: 0 }}>
              <p style={{ marginLeft: `${outerMargins}%`,  fontFamily: 'Inter', fontSize: 12 }}><i>Filters: {sortMutuallyExclusive1 === '' ? 'Sort: Random' : sortMutuallyExclusive1}{(sortMutuallyExclusive1 === 'Hourly Pay' || sortMutuallyExclusive1 === 'One-Time Pay') ? ' - Highest to Lowest' : ''}{sortMutuallyExclusive2 === 'Above $X/hr' ? `, Above $${payAboveNumberHOURLY}` : ''}{sortMutuallyExclusive2 === 'Above $X' ? `, Above $${payAboveNumberONETIME}` : ''}{sortRemote === 'Remote Only' ? ', Remote only' : ''}{sortCutoffLocation ? `- Within a ${locationNumberCutoff} mile radius` : ""}</i></p>
              
              
              <div onClick={() => { setSortMutuallyExlusive1(''); setSortMutuallyExlusive2(''); setSortCutoffLocation(false); setSortRemote('')}} style={{ borderRadius: 100, opacity: (sortMutuallyExclusive1 === '' && sortMutuallyExclusive2 === '' && !sortCutoffLocation && sortRemote === '') ? 0 : 1, backgroundColor: '#ededed', margin: '0px 0px 0px 10px', padding: '5px 10px 5px 5px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <Icon path={mdiClose} size='12px' style={{ padding: 0, margin: '0px 3px 0px 0px'}} /> 
                <p style={{ margin: 0, padding: 0, fontFamily: 'Inter', fontSize: 12 }}>Clear</p>
              </div>
            </div>
              
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
              {/* <p>Current Lat{currentLat} and currentLng: {currentLng}</p> */}
              <p style={{ marginLeft: `${outerMargins}%`, fontFamily: 'Inter', fontWeight: 700, fontSize: 22, lineHeight: 1, marginTop: filter === '' ? 20 : 20, paddingTop: 0 }}>{(filter === '' && (searchFilter == undefined || searchFilter === '') && (searchResults == undefined || searchResults === '')) ? 'All Jobs' : searchFilter !== '' ? 'Search Results' : searchResults !== '' ? 'Search Results' : `Jobs (Filtered by Industry)`}</p>
              { (searchFilter !== '' && searchFilter != undefined) && (
                <p onClick={() => setSearchFilter('')} onMouseEnter={() => setSpotlightHovering('clearSearch')} onMouseLeave={() => setSpotlightHovering('')} style={{ fontFamily: 'Inter', fontWeight: 700, cursor: spotlightHovering === 'clearSearch' ? 'pointer' : 'default', marginLeft: 20, fontSize: 22, lineHeight: 1, color: 'rgba(0,0,0,.3)', marginTop: filter === '' ? 20 : 20, paddingTop: 0 }}>Clear</p>
              )}
              {(searchResults !== '' && searchResults != undefined && searchFilter === '') && (
                <p onClick={() => {setSearchResults(''); setRecentlyDeleted(recentlyDeleted + 1)}} onMouseEnter={() => setSpotlightHovering('clearSearch')} onMouseLeave={() => setSpotlightHovering('')} style={{ fontFamily: 'Inter', fontWeight: 700, cursor: spotlightHovering === 'clearSearch' ? 'pointer' : 'default', marginLeft: 20, fontSize: 22, lineHeight: 1, color: 'rgba(0,0,0,.3)', marginTop: filter === '' ? 20 : 20, paddingTop: 0 }}>Clear</p>
              )}
              { (filter !== '' && searchFilter === '') && (
                <p  onClick={() => { setFilter('');  setFilterClicked(filterClicked + 1)}} onMouseEnter={() => setSpotlightHovering('clearSearch')} onMouseLeave={() => setSpotlightHovering('')} style={{ fontFamily: 'Inter', fontWeight: 700, cursor: spotlightHovering === 'clearSearch' ? 'pointer' : 'default', marginLeft: 20, fontSize: 22, lineHeight: 1, color: 'rgba(0,0,0,.3)', marginTop: filter === '' ? 20 : 20, paddingTop: 0 }}>Clear</p>
              )}
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: `${outerMargins}%`, paddingRight: `${outerMargins}%` }}>
             {getPosts('recent')}
            </div>

             {(posts.length < loadMoreBy) ? (
                <div style={{ width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 0px 40px 0px'}}>
                  <button style={{ height: 40, backgroundColor: '#f4f4f4', borderWidth: 0, borderRadius: 100, padding: '0px 20px 0px 20px', opacity: .3}}>
                    <p>You've Reached the End</p>
                  </button>
                </div>
             ) :
                <div style={{ width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 0px 40px 0px'}}>
                  <button onClick={() => setLoadMoreBy(loadMoreBy + loadMoreByFactor)} style={{ height: 40, backgroundColor: '#f4f4f4', borderWidth: 0, borderRadius: 100, padding: '0px 20px 0px 20px', cursor: loadMoreHovering ? 'pointer' : 'default', }} onMouseEnter={() => setLoadMoreHovering(true)} onMouseLeave={() => setLoadMoreHovering(false)}>
                    <p>Load More</p>
                  </button>
                </div>
              }

          </div>
        </div>
                    {screenSize === 'Extra small' && (
              <div style={{ marginLeft: `${outerMargins}%`, marginRight: `${outerMargins}%`, marginBottom: 50, display: 'flex', flexDirection: screenSize === 'Large' ? 'row' : screenSize === 'Medium' ? 'row' : screenSize === 'Small' ? 'column' : 'column' }}>
                {Array.from(spotlights).map(item => {
                  if (item.a_isShown) {
                    return (
                      <div style={{ height: 180, backgroundColor: item.b_backgroundColor, borderRadius: 9, flex: 1, marginRight: item.id === 'two' ? 0 : `${outerMargins / 10}%`, display: 'flex', flexDirection: 'row', marginTop: 30 }} >
                        {/* Content: 66% / Image: 33% */}
                        <div style={{ flex: .67, position: 'relative' }}>
                          <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: screenSize === "Large" ? 22 : screenSize === 'Medium' ? 16 : screenSize === 'Small' ? 18 : 16, color: item.c_textColor, margin: '20px 0px 0px 20px' }}>{item.e_title}</p>
                          <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 14, color: item.c_textColor, margin: '20px 0px 0px 20px' }}>{item.f_description}</p>
                          {item.k_linkExternalWebsite ? (
                            <a target="_blank" href={item.j_buttonLink} onMouseEnter={() => setSpotlightHovering(item.id)} onMouseLeave={() => setSpotlightHovering('')}>
                              <button style={{ height: 40, backgroundColor: item.d_buttonColor, margin: "20px 0px 20px 20px", borderWidth: 0, borderRadius: 100, cursor: spotlightHovering === item.id ? 'pointer' : 'default' }}>
                                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 16, padding: '3px 12px 3px 12px', color: item.c_textColor, margin: 0 }}>{item.g_buttonText}</p>
                              </button>
                            </a>
                          ) :
                            <Link to={item.j_buttonLink} onMouseEnter={() => setSpotlightHovering(item.id)} onMouseLeave={() => setSpotlightHovering('')}>
                              <button style={{ height: 40, backgroundColor: item.d_buttonColor, margin: "20px 0px 20px 20px", borderWidth: 0, borderRadius: 100, cursor: spotlightHovering === item.id ? 'pointer' : 'default' }}>
                                <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 16, padding: '3px 12px 3px 12px', color: item.c_textColor, margin: 0 }}>{item.g_buttonText}</p>
                              </button>
                            </Link>
                          }
                        </div>
                        <div style={{ flex: .33, backgroundImage: `url(${item.i_imgURL})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                      
                      </div>
                    )
                  } else {
                    return <div style={{ flex: 1 }} />
                  }
                
                })}


              </div>
            )}
      </div>
      <Footer/>
    </body>
  )
}


// export default connect(mapStateToProps)(Dashboard)
export default Dashboard