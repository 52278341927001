import React, { useState, useEffect } from 'react'
import firebase from 'firebase';
import { storage } from "../../config/fbConfig";
import { Redirect } from 'react-router-dom'

import { animateScroll } from 'react-scroll';
import LocationAutocomplete from 'location-autocomplete';
import Multiselect from 'multiselect-react-dropdown';
import Icon from '@mdi/react';
import { mdiDeleteOutline } from '@mdi/js';
import "./style.css";

import { useAuthState } from 'react-firebase-hooks/auth'
import { createCheckoutSession } from './stripe/createCheckoutSession' //"package"
import { AsYouType } from 'libphonenumber-js'


import addIcon from '../../images/add.png'

import smallGreenTHWIcon from '../../images/smallGreenTHWIcon.png'
import postPicture from '../../images/postPicture.png'

const currentcyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

function removeA(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}


export default function Post(props) {

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_DROP_DEPTH':
        return { ...state, dropDepth: action.dropDepth }
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone };
      case 'ADD_FILE_TO_LIST':
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };

  const [data, dispatch] = React.useReducer(
    reducer, { dropDepth: 0, inDropZone: false, fileList: [] }
  )

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth + 1 });
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 });
    if (data.dropDepth > 0) return
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    e.dataTransfer.dropEffect = 'copy';
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };

  const onChangedText = (text) => {
    let formatedNo = phoneFormat(text)
    setContactPhone(formatedNo)
  }

  const phoneFormat = (text) => {
    var cleaned = ("" + text).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "",
        number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      return number;
    }
    return text
  } 


const handleDrop = e => {
  e.preventDefault();
  e.stopPropagation();

  let files = [...e.dataTransfer.files];

  if (files && files.length > 0) {
    // Keep only the first file if multiple files are dropped
    const image = files[0];

    // Update state to set the new image
    dispatch({ type: 'SET_FILE_LIST', files: [image] });
    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });

    // Create a preview URL for the new image
    const imagePreview = URL.createObjectURL(image);

    // Update state with the new image preview
    setImageAsFile(image);
    setPreviewImage(imagePreview);
    setFormUpdated(!formUpdated);
  }
};


  const [greenOutlineForm, setGreenOutlineForm] = useState('')

  const keyPressHanlder = (event) => {
    setFormUpdated(!formUpdated)
    const { key } = event;
    setHourlyWage((prevValue) =>
      key !== "Backspace"
        ? !Number.isNaN(parseInt(key)) || key === "," || key === "."
          ? prevValue + key
          : prevValue
        : prevValue.substring(0, prevValue.length - 1)
    );
  };

  const keyPressHanlderOneTimePayment = (event) => {
    setFormUpdated(!formUpdated)
    const { key } = event;
    setOneTimePayment((prevValue) =>
      key !== "Backspace"
        ? !Number.isNaN(parseInt(key)) || key === "," || key === "."
          ? prevValue + key
          : prevValue
        : prevValue.substring(0, prevValue.length - 1)
    );
  };


  const [userA, userALoading] = useAuthState(firebase.auth())
  
  useEffect(() => {
    console.log(userA, 'userA')
  }, [userA, userALoading])

  const [editingPost, setEditingPost] = useState(false)
  const [preselectedCategories, setPreselectedCategories] = useState([])


  useEffect(() => { 
    if (props.location.editPost != undefined && props.location.editPost) {
      setEditingPost(true)
      firebase.firestore().collection('projects').doc(props.location.postID).get().then(doc => {
        setJobTitle(doc.data().jobTitle)
        setOneTimePayment(doc.data().oneTimePayment)
        setContactEmail(doc.data().contactEmail)
        setContactName(doc.data().contactName)
        setContactPhone(doc.data().contactPhone)
        console.log(1231233)
        setDescription(doc.data().description)
        setFullTime(doc.data().fullTime)
        setPostDuration(doc.data().postDuration)
        setTips(doc.data().tips)
        setAddress(doc.data().address)
        setBusinessWebsite(doc.data().businessWebsite)
        setBusinessApplication(doc.data().businessApplication)
        setCategory(doc.data().category)

        for (let i = 0; i < doc.data().category.length; i++) {
          switch (doc.data().category[i]) {
            case 'computer':
              setPreselectedCategories([...preselectedCategories, { name: 'Computer Skills', value: 'computer', id: 1 }])
              break;
            case 'customerService':
              setPreselectedCategories([...preselectedCategories, { name: 'Customer Service', value: 'customerService', id: 2 }])
              break;
            case 'delivery':
              setPreselectedCategories([...preselectedCategories, { name: 'Delivery', value: 'delivery', id: 3 }])
              break;
            case 'restaurant':
              setPreselectedCategories([...preselectedCategories, { name: 'Food/Restuarant', value: 'restaurant', id: 4 }])
              break;
            case 'health':
              setPreselectedCategories([...preselectedCategories,{ name: 'Health Care', value: 'health', id: 5 } ])
              break;
            case 'home':
              setPreselectedCategories([...preselectedCategories, { name: 'Home Services', value: 'home', id: 6 }])
              break;
            case 'landscaping':
              setPreselectedCategories([...preselectedCategories, { name: 'Landscaping', value: 'landscaping', id: 7 }])
              break;
            case 'maintenance':
              setPreselectedCategories([...preselectedCategories, { name: 'Maintenance', value: 'maintenance', id: 8 }])
              break;
            case 'marketing':
              setPreselectedCategories([...preselectedCategories, { name: 'Marketing/Sales', value: 'marketing', id: 9 }])
              break;
            case 'trade':
              setPreselectedCategories([...preselectedCategories, { name: 'Mechanical/Trade', value: 'trade', id: 10 }])
              break;
            case 'other':
              setPreselectedCategories([...preselectedCategories, { name: 'Micellaneous', value: 'other', id: 11 }])
              break;
            case 'office':
              setPreselectedCategories([...preselectedCategories, { name: 'Office Support', value: 'office', id: 12 }])
              break;
            case 'personal':
              setPreselectedCategories([...preselectedCategories, { name: 'Personal Care', value: 'personal', id: 13 }])
              break;
            case 'pet':
              setPreselectedCategories([...preselectedCategories, { name: 'Pet Care', value: 'pet', id: 14 }])
              break;
            case 'physical':
              setPreselectedCategories([...preselectedCategories, { name: 'Physical Work', value: 'physical', id: 15 }])
              break;
            case 'retail':
              setPreselectedCategories([...preselectedCategories, { name: 'Retail', value: 'retail', id: 16 }])
              break;
            case 'warehouse':
              setPreselectedCategories([...preselectedCategories, { name: 'Warehouse', value: 'warehouse', id: 17 }])
              break;
            default:
              break;

          }
        }


        setFormUpdated(!formUpdated)

        /*
    { name: 'Computer Skills', value: 'computer', id: 1 },
    { name: 'Customer Service', value: 'customerService', id: 2 },
    { name: 'Delivery', value: 'delivery', id: 3 },
    { name: 'Food/Restuarant', value: 'restaurant', id: 4 },
    { name: 'Health Care', value: 'health', id: 5 },
    { name: 'Home Services', value: 'home', id: 6 },
    { name: 'Landscaping', value: 'landscaping', id: 7 },
    { name: 'Maintenance', value: 'maintenance', id: 8 },
    { name: 'Marketing/Sales', value: 'marketing', id: 9 },
    { name: 'Mechanical/Trade', value: 'trade', id: 10 },
    { name: 'Micellaneous', value: 'other', id: 11 },
    { name: 'Office Support', value: 'office', id: 12 },
    { name: 'Personal Care', value: 'personal', id: 13 },
    { name: 'Pet Care', value: 'pet', id: 14 },
    { name: 'Physical Work', value: 'physical', id: 15 },
    { name: 'Retail', value: 'retail', id: 16 },
    { name: 'Warehouse', value: 'warehouse', id: 17 },
        */

        if (doc.data().hourlyWage !== '') {
          setHourlyWage(doc.data().hourlyWage)
        } else {
          setOneTimePayment(doc.data().oneTimePayment)
        }
        // try {
        //   doc.data().thumbnail.forEach(itemm => {
        //     setImageAsFile([...imageAsFile, { name: itemm }])
        //   })
        // } catch(err) {}

        // try { setPreviewImage(doc.data().thumbnail) } catch (err) {}
        setBusinessName(doc.data().businessName)
        setUnderstood(true)
        //thumbnail and location having trouble changing
        setFormUpdated(!formUpdated)
      })
    }
  }, [])

  
  const [businessChecked, setBusinessChecked] = useState(true)
  const [personalChecked, setPersonalChecked] = useState(false)
  const [jobTitle, setJobTitle] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [businessStreet, setBusinessStreet] = useState('')
  const [businessCity, setBusinessCity] = useState('')
  const [businessState, setBusinessState] = useState('')
  const [businessZip, setBusinessZip] = useState('')
  const [businessWebsite, setBusinessWebsite] = useState('')
  const [hourlyWage, setHourlyWage] = useState('')
  const [oneTimePayment, setOneTimePayment] = useState('')
  const [fullTime, setFullTime] = useState('')
  const [tips, setTips] = useState(false)
  const [description, setDescription] = useState('')
  const [isHoveringSubmitJob, setIsHoveringSubmitJob] = useState(false)
    const [contactName, setContactName] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [isHoveringUploadImage, setIsHoveringUploadImage] = useState(false)
  const [outerMargins, setOuterMargins] = useState('12')
  const [category, setCategory] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [postDuration, setPostDuration] = useState('')
  const [saveDefaults, setSaveDefaults] = useState(false)
  const [imagePreviewArrowHover, setImagePreviewArrowHover] = useState('')
  const [businessApplication, setBusinessApplication] = useState('')
  const [remote, setRemote] = useState(false)
  
  const [previewImage, setPreviewImage] = useState('')
  const [imageAt, setImageAt] = useState(0)


  const [currentScreen, setCurrentScreen] = useState('pay') // work, pay, home (more like a regular website you know)
  
  const [hoverTerms, setHoveringTerms] = useState(false)
  const [formReady, setFormReady] = useState(false)
  const [formError, setFormError] = useState('')
  const [formUpdated, setFormUpdated] = useState(true)

  
  const allInputs = { imgUrl: '' }
    
  const [imageAsFile, setImageAsFile] = useState('')
  const [imageAsUrl, setImageAsUrl] = useState(allInputs)

  const [hoverSubmit, setHoverSubmit] = useState(false)

  const [savingMessage, setSavingMessage] = useState('')
  const [defaultSelected, setAsDefaultSelected] = useState(false)
  
  const [understood, setUnderstood] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)

  const [address, setAddress] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')



  useEffect(() => {
    console.log('hi')

    if (jobTitle !== '') {
      if (hourlyWage !== '' || oneTimePayment !== '') {
        if (category !== '') {
          if (description !== '') {
            if (fullTime !== '') {
              if (imageAsFile !== '' || previewImage !== '') {
                if (postDuration !== '') {
                      if (contactName !== '') {
                        if (contactPhone !== '') {
                          if (contactEmail !== '') {
                            if (understood) {
                              setFormReady(true)
                              console.log('setastrue')
                            } else {
                              setFormError('Must accept terms'); setFormReady(false)
                            }
                          } else {
                            setFormError('Must provide contact email'); setFormReady(false)
                          }
                        } else {
                          setFormError('Must provide contact phone'); setFormReady(false)
                        }
                      } else {
                        setFormError('Must provide contact name'); setFormReady(false)
                    }
                } else {
                  setFormError('Must specify post duration'); setFormReady(false)
                }
              } else {
                setFormError('Must upload images'); setFormReady(false)
              }
            } else {
              setFormError('Must specify full/part time'); setFormReady(false)
            }
          } else {
            setFormError('Must write a description'); setFormReady(false)
          }
        } else {
          setFormError('Must pick a job category'); setFormReady(false)
        }
      } else {
        setFormError('No payment amount'); setFormReady(false)
      }
    } else {
      setFormError('No job title.'); setFormReady(false);
    }

  }, [jobTitle, formUpdated, hourlyWage, oneTimePayment, category, description, fullTime, imageAsFile, postDuration, businessName, address, contactName, contactPhone, contactEmail])


  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])

  const handleImageAsFile = (e) => {
    const image = e.target.files[0]
    console.log(0, e.target.files)
    console.log('RANNN')
    setImageAsFile(image)
    try {
      console.log('7', URL.createObjectURL(image))

      setPreviewImage(URL.createObjectURL(image))
      setFormUpdated(!formUpdated)
    } catch (error) {
      console.log('exited file picker')
    }

  }

  //for determining amount of images for switching between image previews
  useEffect(() => { 
    try {
      setImageAt(previewImage.length - 1)
    } catch (err) {}
  }, [previewImage])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
    })
    setImageAsFile('')

  }, [])

  useEffect(() => {
    if (loggedIn && !props.location.editPost) {
      //Get default values
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get().then(doc => {
        try { setBusinessName(doc.data().businessName) } catch(err) {}
        try { setContactEmail(doc.data().contactEmail) } catch(err) {}
        try { setContactName(doc.data().contactName) } catch(err) {}
        try { setContactPhone(doc.data().contactPhone) } catch(err) {}
        try { setDescription(doc.data().description) } catch(err) {}
        try { setJobTitle(doc.data().jobTitle) } catch(err) {}
        try { setPostDuration(doc.data().postDuration) } catch (err) { }
        try { setBusinessApplication(doc.data().businessApplication) } catch (err) { }
        try { setAddress(doc.data().address) } catch (err) { }
        try { setBusinessWebsite(doc.data().businessWebsite) } catch (err) { }
        // try { setPreviewImage(doc.data().thumbnail) } catch (err) { }
        try { setLat(doc.data().lat) } catch (err) { }
        try { setLng(doc.data().lng )} catch (err) { }
        //try { setHourlyWage(doc.data().hourlyWage) } catch (err) { }
        //try { setOneTimePayment(doc.data().oneTimePayment) } catch (err) { }
        //try { setFullTime(doc.data().fullTime) } catch (err) { }
        //try { setTips(doc.data().tips) } catch (err) { }
        //try { setCategory(doc.data().category) } catch(err) {}
        // try {
        //   for (let i = 0; i < doc.data().category.length; i++) {
        //     switch (doc.data().category[i]) {
        //       case 'computer':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Computer Skills', value: 'computer', id: 1 }])
        //         break;
        //       case 'customerService':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Customer Service', value: 'customerService', id: 2 }])
        //         break;
        //       case 'delivery':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Delivery', value: 'delivery', id: 3 }])
        //         break;
        //       case 'restaurant':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Food/Restuarant', value: 'restaurant', id: 4 }])
        //         break;
        //       case 'health':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Health Care', value: 'health', id: 5 }])
        //         break;
        //       case 'home':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Home Services', value: 'home', id: 6 }])
        //         break;
        //       case 'landscaping':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Landscaping', value: 'landscaping', id: 7 }])
        //         break;
        //       case 'maintenance':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Maintenance', value: 'maintenance', id: 8 }])
        //         break;
        //       case 'marketing':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Marketing/Sales', value: 'marketing', id: 9 }])
        //         break;
        //       case 'trade':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Mechanical/Trade', value: 'trade', id: 10 }])
        //         break;
        //       case 'other':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Micellaneous', value: 'other', id: 11 }])
        //         break;
        //       case 'office':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Office Support', value: 'office', id: 12 }])
        //         break;
        //       case 'personal':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Personal Care', value: 'personal', id: 13 }])
        //         break;
        //       case 'pet':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Pet Care', value: 'pet', id: 14 }])
        //         break;
        //       case 'physical':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Physical Work', value: 'physical', id: 15 }])
        //         break;
        //       case 'retail':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Retail', value: 'retail', id: 16 }])
        //         break;
        //       case 'warehouse':
        //         setPreselectedCategories([...preselectedCategories, { name: 'Warehouse', value: 'warehouse', id: 17 }])
        //         break;
        //       default:
        //         break;

        //     }
        //   }
        // } catch(err) { }
      })

      console.log('fullTime', fullTime === '' ? 'blank' : 'something else')
    }
  }, [loggedIn])

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0
    });
  }, [window.location.pathname]);
  
//                   <option value="computer">Computer Skills</option>
//                   <option value="customerService">Customer Service</option>
//                   <option value="delivery">Delivery/Transportation</option>
//                   <option value="restaurant">Food/Restaurant</option>
//                   <option value="health">Health Care</option>
//                   <option value="home">Home Services</option>
//                   <option value="landscaping">Landscaping/Physical</option>
//                   <option value="maintenance">Maintenance</option>
//                   <option value="marketing">Marketing/Sales</option>
//                   <option value="other">Miscellaneous</option>
//                   <option value="office">Office Support</option>
//                   <option value="pet">Personal Care/Services</option>
//                   <option value="retail">Retail</option>
//                   <option value="trade">Mechanical/Trade</option>
//                   <option value="warehouse">Warehouse</option>

  const [categories] = useState([
    { name: 'Computer Skills', value: 'computer', id: 1 },
    { name: 'Customer Service', value: 'customerService', id: 2 },
    { name: 'Delivery', value: 'delivery', id: 3 },
    { name: 'Food/Restuarant', value: 'restaurant', id: 4 },
    { name: 'Health Care', value: 'health', id: 5 },
    { name: 'Home Services', value: 'home', id: 6 },
    { name: 'Landscaping', value: 'landscaping', id: 7 },
    { name: 'Maintenance', value: 'maintenance', id: 8 },
    { name: 'Marketing/Sales', value: 'marketing', id: 9 },
    { name: 'Mechanical/Trade', value: 'trade', id: 10 },
    { name: 'Micellaneous', value: 'other', id: 11 },
    { name: 'Office Support', value: 'office', id: 12 },
    { name: 'Personal Care', value: 'personal', id: 13 },
    { name: 'Pet Care', value: 'pet', id: 14 },
    { name: 'Physical Work', value: 'physical', id: 15 },
    { name: 'Retail', value: 'retail', id: 16 },
    { name: 'Warehouse', value: 'warehouse', id: 17 },
])


  let date = new Date()
  let month = date.toLocaleString('default', { month: 'long' })
  let year = date.getFullYear().toString().substring(2)
  let dateNum = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  const handleFireBaseUpload = () => {

    if (imageAsFile !== '' && previewImage !== '') {
      //e.preventDefault()
      let arrayOfFirebaseURLs = []
      console.log('imageAsFile:', imageAsFile, ', previewImage:', previewImage)
      console.log('start of upload')
      // async magic goes here...
      for (let i = 0; i < 1; i++) {
        console.log('loop ran:', i, 'times.', imageAsFile, imageAsFile.length, imageAsFile)
        if (imageAsFile === '') {
          console.error(`not an image, the image file is a ${typeof (imageAsFile)}`)
        }
        const uploadTask = storage.ref(`/postingThumbnails/${imageAsFile.name}`).put(editingPost ? imageAsFile.name : imageAsFile)
        //initiates the firebase side uploading 
        uploadTask.on('state_changed',
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot)
          }, (err) => {
            //catches the errors
            console.log(err)
        }, () => {
            storage.ref('postingThumbnails').child(imageAsFile.name).getDownloadURL().then(fireBaseUrl => {
              try {
                if (imageAsFile.slice(0, 10) === 'https://fi') {
                  console.log('EQUAL, dont add')
                } else {
                  arrayOfFirebaseURLs.push(fireBaseUrl)
                  console.log('added', fireBaseUrl, i, arrayOfFirebaseURLs)
                }
              } catch (err) {
                console.log('err232')
              }
              console.log('heehafdafer')
  
                if (!editingPost) {
                  firebase.firestore().collection('projects').add({
                    jobTitle,
                    address,
                    businessName: businessName,
                    businessStreet,
                    businessCity,
                    businessState,
                    businessZip,
                    hourlyWage: hourlyWage != undefined ? hourlyWage.replace('$', '') : '', //remove dollar signs
                    oneTimePayment: oneTimePayment != undefined ? oneTimePayment.replace('$', '') : '', //remove dollar signs
                    fullTime,
                    tips,
                    description,
                    user: firebase.auth().currentUser.uid,
                    thumbnail: arrayOfFirebaseURLs,
                    contactName,
                    contactPhone,
                    contactEmail,
                    category,
                    postDuration,
                    creation: firebase.firestore.FieldValue.serverTimestamp(),
                    year,
                    month,
                    dateNum,
                    hours,
                    minutes,
                    seconds,
                    lat,
                    lng,
                    businessWebsite,
                    businessApplication,
                    remote
                  }).then(() => {
                    if (defaultSelected) {
                      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).set({
                        jobTitle,
                        address,
                        businessName,
                        businessStreet,
                        businessCity,
                        businessState,
                        businessZip,
                        hourlyWage: hourlyWage.replace('$', ''), //remove dollar signs
                        oneTimePayment: oneTimePayment.replace('$', ''), //remove dollar signs
                        fullTime,
                        tips,
                        description,
                        thumbnail: arrayOfFirebaseURLs,
                        contactName,
                        contactPhone,
                        contactEmail,
                        category,
                        postDuration,
                        creation: firebase.firestore.FieldValue.serverTimestamp(),
                        year,
                        month,
                        dateNum,
                        hours,
                        minutes,
                        seconds,
                        lat,
                        lng,
                        businessWebsite,
                        businessApplication,
                        remote
                      }).catch(err => console.log('333', err.message))
                    
                    } else {
                      setCurrentScreen('work')
                      setSavingMessage('')
                    }
  
                    if (saveDefaults && !editingPost) {
                      setSavingMessage('Saving default values to your profile')
                      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
                        jobTitle,
                        address,
                        businessName,
                        businessStreet,
                        businessCity,
                        businessState,
                        businessZip,
                        hourlyWage: hourlyWage.replace('$', ''), //remove dollar signs
                        oneTimePayment: oneTimePayment.replace('$', ''), //remove dollar signs
                        fullTime,
                        tips,
                        description,
                        thumbnail: arrayOfFirebaseURLs,
                        contactName,
                        contactPhone,
                        contactEmail,
                        category,
                        postDuration,
                        lat,
                        lng,
                        businessWebsite,
                        businessApplication,
                        remote
                      }).then(() => {
                        setCurrentScreen('work')
                        setSavingMessage('')
                        console.log('SAVED DEFAULTS', saveDefaults)
                      })
                    } else {
                      setCurrentScreen('work')
                      setSavingMessage('')
                      console.log('DID NOT SAVE DEFAULTS', saveDefaults)
                    }
                  }).catch(err => console.log('334', err.message))
                } else {
                  
                  firebase.firestore().collection('projects').doc(props.location.postID).update({
                    jobTitle,
                    address,
                    businessName,
                    businessStreet,
                    businessCity,
                    businessState,
                    businessZip,
                    hourlyWage: hourlyWage != undefined ? hourlyWage.replace('$', '') : '', //remove dollar signs
                    oneTimePayment: oneTimePayment != undefined ? oneTimePayment.replace('$', '') : '', //remove dollar signs
                    fullTime,
                    tips,
                    description,
                    user: firebase.auth().currentUser.uid,
                    thumbnail: arrayOfFirebaseURLs,
                    contactName,
                    contactPhone,
                    contactEmail,
                    category,
                    postDuration,
                    creation: firebase.firestore.FieldValue.serverTimestamp(),
                    year,
                    month,
                    dateNum,
                    hours,
                    minutes,
                    seconds,
                    lat,
                    lng,
                    businessWebsite,
                    businessApplication,
                    remote
                  }).then(() => {
                      setCurrentScreen('account')
                      setSavingMessage('')
                   }).catch(err => console.log(32324, err.message))
                
                }
              })
          })
      }
    } else {
      console.log('Herooo')
      if (!editingPost) {
        console.log('not editing')
        firebase.firestore().collection('projects').add({
          jobTitle,
          address,
          businessName,
          businessStreet,
          businessCity,
          businessState,
          businessZip,
          hourlyWage: hourlyWage != undefined ? hourlyWage.replace('$', '') : '', //remove dollar signs
          oneTimePayment: oneTimePayment != undefined ? oneTimePayment.replace('$', '') : '', //remove dollar signs
          fullTime,
          tips,
          description,
          user: firebase.auth().currentUser.uid,
          thumbnail: previewImage,
          contactName,
          contactPhone,
          contactEmail,
          category,
          postDuration,
          creation: firebase.firestore.FieldValue.serverTimestamp(),
          year,
          month,
          dateNum,
          hours,
          minutes,
          seconds,
          lat,
          lng,
          businessWebsite,
          businessApplication,
          remote
        }).then(() => {
          if (defaultSelected) {
            firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).set({
              jobTitle,
              address,
              businessName,
              businessStreet,
              businessCity,
              businessState,
              businessZip,
              hourlyWage: hourlyWage.replace('$', ''), //remove dollar signs
              oneTimePayment: oneTimePayment.replace('$', ''), //remove dollar signs
              fullTime,
              tips,
              description,
              thumbnail: previewImage,
              contactName,
              contactPhone,
              contactEmail,
              category,
              postDuration,
              creation: firebase.firestore.FieldValue.serverTimestamp(),
              year,
              month,
              dateNum,
              hours,
              minutes,
              seconds,
              lat,
              lng,
              businessWebsite,
              businessApplication,
              remote
            }).catch(err => console.log('333', err.message))
          
          } else {
            setCurrentScreen('work')
            setSavingMessage('')
          }

          if (saveDefaults && !editingPost) {
            setSavingMessage('Saving default values to your profile')
            firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
              jobTitle,
              address,
              businessName,
              businessStreet,
              businessCity,
              businessState,
              businessZip,
              hourlyWage: hourlyWage.replace('$', ''), //remove dollar signs
              oneTimePayment: oneTimePayment.replace('$', ''), //remove dollar signs
              fullTime,
              tips,
              description,
              thumbnail: previewImage,
              contactName,
              contactPhone,
              contactEmail,
              category,
              postDuration,
              lat,
              lng,
              businessWebsite,
              businessApplication,
              remote
            }).then(() => {
              setCurrentScreen('work')
              setSavingMessage('')
              console.log('SAVED DEFAULTS', saveDefaults)
            })
          } else {
            setCurrentScreen('work')
            setSavingMessage('')
            console.log('DID NOT SAVE DEFAULTS', saveDefaults)
          }
        }).catch(err => console.log('334', err.message))
      } else {
        console.log('editing')
        firebase.firestore().collection('projects').doc(props.location.postID).update({
          jobTitle,
          address,
          businessName,
          businessStreet,
          businessCity,
          businessState,
          businessZip,
          hourlyWage: hourlyWage != undefined ? hourlyWage.replace('$', '') : '', //remove dollar signs
          oneTimePayment: oneTimePayment != undefined ? oneTimePayment.replace('$', '') : '', //remove dollar signs
          fullTime,
          tips,
          description,
          user: firebase.auth().currentUser.uid,
          thumbnail: previewImage,
          contactName,
          contactPhone,
          contactEmail,
          category,
          postDuration,
          creation: firebase.firestore.FieldValue.serverTimestamp(),
          year,
          month,
          dateNum,
          hours,
          minutes,
          seconds,
          lat,
          lng,
          businessWebsite,
          businessApplication,
          remote
        }).then(() => {
            setCurrentScreen('account')
            setSavingMessage('')
          }).catch(err => console.log(32324, err.message))
      
      }
    }
  


  }

  const handleSubmit = (e) => {
    //e.preventDefault()
    setSavingMessage('Saving')

    if (editingPost) {
      if (imageAsFile !== '' && previewImage !== '') {
      //e.preventDefault()
      let arrayOfFirebaseURLs = []
      console.log('imageAsFile:', imageAsFile, ', previewImage:', previewImage)
      console.log('start of upload')
      // async magic goes here...
          console.log('loop ran:','times.', imageAsFile, imageAsFile.length, imageAsFile)
          if (imageAsFile === '') {
            console.error(`not an image, the image file is a ${typeof (imageAsFile)}`)
          }
          const uploadTask = storage.ref(`/postingThumbnails/${imageAsFile.name}`).put(imageAsFile, { contentType: 'image/png' })
          //initiates the firebase side uploading 
        uploadTask.on('state_changed',
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot)
          }, (err) => {
            //catches the errors
            console.log(err)
        }, () => {
            console.log('imageAsFileName:', imageAsFile.name)
            storage.ref('postingThumbnails').child(imageAsFile.name).getDownloadURL().then(fireBaseUrl => {
              try {
                if (imageAsFile.slice(0, 10) === 'https://fi') {
                  console.log('EQUAL, dont add')
                } else {
                  arrayOfFirebaseURLs.push(fireBaseUrl)
                  console.log('added', fireBaseUrl, arrayOfFirebaseURLs)
                }
              } catch (err) {
                console.log('err232')
              }
              console.log('heehafdafer')
              firebase.firestore().collection('projects').doc(props.location.postID).update({
                jobTitle,
                address,
                businessName,
                businessStreet,
                businessCity,
                businessState,
                businessZip,
                hourlyWage: hourlyWage != undefined ? hourlyWage.replace('$', '') : '', //remove dollar signs
                oneTimePayment: oneTimePayment != undefined ? oneTimePayment.replace('$', '') : '', //remove dollar signs
                fullTime,
                tips,
                description,
                user: firebase.auth().currentUser.uid,
                thumbnail: arrayOfFirebaseURLs,
                contactName,
                contactPhone,
                contactEmail,
                category,
                postDuration,
                creation: firebase.firestore.FieldValue.serverTimestamp(),
                year,
                month,
                dateNum,
                hours,
                minutes,
                seconds,
                lat,
                lng,
                businessWebsite,
                remote
              }).then(() => {
                console.log('fbURLS', arrayOfFirebaseURLs)

                setCurrentScreen('account')
                setSavingMessage('')
              }).catch(err => console.log(32324, err.message))
            })
          })
              } else {
              firebase.firestore().collection('projects').doc(props.location.postID).update({
                jobTitle,
                address,
                businessName,
                businessStreet,
                businessCity,
                businessState,
                businessZip,
                hourlyWage: hourlyWage != undefined ? hourlyWage.replace('$', '') : '', //remove dollar signs
                oneTimePayment: oneTimePayment != undefined ? oneTimePayment.replace('$', '') : '', //remove dollar signs
                fullTime,
                tips,
                description,
                user: firebase.auth().currentUser.uid,
                thumbnail: previewImage,
                contactName,
                contactPhone,
                contactEmail,
                category,
                postDuration,
                creation: firebase.firestore.FieldValue.serverTimestamp(),
                year,
                month,
                dateNum,
                hours,
                minutes,
                seconds,
                lat,
                lng,
                businessWebsite,
                remote
              }).then(() => {
                console.log('fbURLS', previewImage)

                setCurrentScreen('account')
                setSavingMessage('')
              }).catch(err => console.log(32324, err.message))
            }
    } else {
      handleFireBaseUpload()
    }
  }

// const onToken = (amount, description) => token =>
//   axios.post(PAYMENT_SERVER_URL,
//     {
//       description,
//       source: token,
//       currency: CURRENCY,
//       amount: fromUSDToCent(amount)
//     })
//     .then(successPayment)
//       .catch(errorPayment);
  
//   const CURRENCY = 'USD';

//   const fromUSDToCent = amount => amount * 100;

//   const successPayment = data => {
//     handleSubmit()
//     //what is this data??
//     //loading thing here
//   };
  
  //oneTimePayment !== '' ? true : false
  useEffect(() => {
    if (oneTimePayment !== '') {
      setFullTime([...fullTime, 'One-time job'])
      setFormUpdated(!formUpdated)
    }
  }, [oneTimePayment])

  const errorPayment = data => {
    alert('Payment Error');
    console.log('payment failure error', data)
  };

  const onSelect = (selectedList, selectedItem) => {
    console.log('selectedList', selectedList)
    setCategory([])
    try {
      for (let i = 0; i < selectedList.length; i++) {
        setCategory([...category, selectedList[i].value])
      }
    } catch (err) {
      setCategory(selectedList[0].value)
    }
    setFormUpdated(!formUpdated)
  }

  useEffect(() => {
    console.log('useEffect contactPhone - ', contactPhone)
  }, [contactPhone])

  const onRemove = (selectedList, removedItem) => {
    console.log('selectedListR', selectedList)
    setCategory([])
    for (let i = 0; i < selectedList.length; i++) {
      setCategory([...category, selectedList[i].value])
    }
  }

  function onDropdownSelect(component) {

    const place = component.autocomplete.getPlace();
    
    setAddress(place.formatted_address)

  // this will return a reference to the input field
    const inputField = component.input;
    
    setLat(place.geometry.location.lat())
    setLng(place.geometry.location.lng())

  // other awesome stuff
  }

  const [openedPosting, setOpenedPosting] = useState('')
  const [doNotClose, setDoNotClose] = useState(false)
  const [isHoveringClose, setIsHoveringClose] = useState(false)

  const tryToClose = () => {
    if (!doNotClose) {
      setOpenedPosting('')
      setImageAt(0)
    } else if (isHoveringClose) {
      setOpenedPosting('')
      setDoNotClose(false)
      setIsHoveringClose(false)
      setImageAt(0)
    }
  }


  if (currentScreen === 'work' || (loaded && !loggedIn)) {
    return <Redirect to='/' />
  }

  if (currentScreen === 'account' || (loaded && !loggedIn)) {
    return <Redirect to='/my-account' />
  }

  return (
    <div style={{ flex: 1, overflow: 'hidden'}}>
      {/* HEADER */}
      {!editingPost && (
        <div style={{ height: screenSize === 'Extra small' ? 150 : 300, marginBottom: 20, backgroundImage: `url(${postPicture})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
          <p style={{ color: 'white', fontSize: 40, fontWeight: 600 }}>Post a Job</p>
        </div>
      )}

      {/* JOB DETAILS */}
      <div style={{ width: '100vw', display: 'flex', flexDirection: 'row', marginTop: 20 }}>
        {/* TITLE */}
        <div style={{ width: '31vw', display: screenSize === 'Extra small' ? 'none' : 'flex', alignItems: 'flex-start', justifyContent: 'flex-end'}}>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: 200}}>
            <div style={{ width: 24, height: 24, borderRadius: 200, backgroundColor: 'forestgreen', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <b style={{ fontSize: 16, color:' white' }}>1</b>
            </div>
            <p style={{ fontFamily: 'Inter', marginLeft: 10, marginRight: 15, fontSize: 16, fontWeight: 600}}>Job Details</p>
          </div>
        </div>
        {/* Form container */}
        <div style={{ width: screenSize === 'Extra small' ? '100vw' : '69vw', marginLeft: screenSize === 'Extra small' ? 20 : 0 }}>
          {/* Job title */}
          <input onFocus={() => setGreenOutlineForm('jobTitle')} onBlur={() => setGreenOutlineForm('')} style={{ height: 30, width: '20%' - 30, backgroundColor: 'white', borderRadius: 10, marginTop: 10, padding: '0px 15px 0px 15px', borderWidth: 2, outline: 0, borderColor: greenOutlineForm === 'jobTitle' ? '#378805' : 'rgba(0,0,0,.1)', borderStyle: 'solid', fontFamily: 'Inter', fontWeight: 400, fontSize: 13 }} type={'text'} placeholder={'Job title'} value={jobTitle} onChange={text => { setJobTitle(text.target.value); setFormUpdated(!formUpdated) }} defaultValue={jobTitle} />
          {/* One time payment/hourly wage */}
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <input onFocus={() => setGreenOutlineForm('onetime')} onBlur={() => setGreenOutlineForm('')} disabled={oneTimePayment !== '' ? true : false} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, width: '20%' - 30, backgroundColor: oneTimePayment !== '' ? '#ededed' : 'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, outline: 0, borderColor: greenOutlineForm === 'onetime' ? '#378805' : 'rgba(0,0,0,.1)', borderStyle: 'solid', fontFamily: 'Inter', fontWeight: 400, fontSize: 13 }} type={'text'} placeholder={currentcyFormat.format("") + ' per hour'} onKeyDown={keyPressHanlder} value={hourlyWage === '' ? '' : currentcyFormat.format(hourlyWage) + ' per hour'} />
            <p style={{ fontFamily: 'Inter', marginLeft: 10, marginRight: 10, fontSize: 16, lineHeight: '30px' }}>or</p>
            <input onFocus={() => setGreenOutlineForm('hourlyWage')} onBlur={() => setGreenOutlineForm('')} disabled={hourlyWage !== '' ? true : false} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, width: '20%'  - 30, backgroundColor: hourlyWage !== '' ? '#ededed' :'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, outline: 0, borderColor: greenOutlineForm === 'hourlyWage' ? '#378805' : 'rgba(0,0,0,.1)', borderStyle: 'solid', fontFamily: 'Inter', fontWeight: 400, fontSize: 13}} type={'text'} placeholder={'One-time payment'} value={oneTimePayment === '' ? '' : currentcyFormat.format(oneTimePayment) + ' payment'} onKeyDown={keyPressHanlderOneTimePayment}  />
          </div>
          {/* Categories: multiple values can be selected from dropdown */}
          <div style={{ minHeight: 35, width: '60%', fontFamily: 'Inter', fontWeight: 400, backgroundColor: 'white', borderRadius: 10, marginTop: 5, padding: '0px 0px 0px 0px', borderWidth: 2, borderColor: 'rgba(0,0,0,.1)', borderStyle: 'solid', fontFamily: 'Inter', fontWeight: 400, fontSize: 13 }}>
          
          <Multiselect
              options={categories} // Options to display in the dropdown
              
              
            //selectedValues={} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              
              onRemove={onRemove} // Function will trigger on remove event
              hidePlaceholder={category.length > 1}
              placeholder='Type of Job'
              selectionLimit={3}
              displayValue="name" // Property name to display in the dropdown options
              avoidHighlightFirstOption={true}
              style={{
                searchBox: { borderWidth: 0 },
                chips: { backgroundColor: '#378805' },
                options: { backgroundColor: 'white', margin: 0, padding: 0 },
                highlightOption: { background: '#378805' },

              }}
              selectedValues={preselectedCategories}
              //selectedValues={category}
             
            />
          </div>

          {/* May Recieve Tips */}
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row' }}>
            <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10 }}>
              <input type={'checkbox'} style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13  }} checked={fullTime.includes('Full time')} name='ftime' value='Full time' onChange={text => {fullTime.includes('Full time') ? setFullTime(removeA(fullTime, 'Full time')) : setFullTime([...fullTime, text.target.value]);  setFormUpdated(!formUpdated)}} />
              Full time
            </label>
            <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10 }}>
              <input type={'checkbox'} style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13}} checked={fullTime.includes('Part time')} name='ftime' value='Part time' onChange={text => {fullTime.includes('Part time') ? setFullTime(removeA(fullTime, 'Part time')) : setFullTime([...fullTime, text.target.value]);  setFormUpdated(!formUpdated)}} />
              Part time
            </label>
            <label style={{fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10 }}>
              <input type={'checkbox'} style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13}} checked={fullTime.includes('Flexible')} name='ftime' value='Flexible' onChange={text => {fullTime.includes('Flexible') ? setFullTime(removeA(fullTime, 'Flexible')) : setFullTime([...fullTime, text.target.value]);  setFormUpdated(!formUpdated)}}/>
              Flexible
            </label>
            <label style={{fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10 }}>
              <input type={'checkbox'} style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13}} checked={fullTime.includes('One-time job')} name='ftime' value='One-time job' onChange={text => {fullTime.includes('One-time job') ? setFullTime(removeA(fullTime, 'One-time job')) : setFullTime([...fullTime, text.target.value]);  setFormUpdated(!formUpdated)}}/>
              One-time job
            </label>
          </div>

          <br></br>
          <textarea onFocus={() => setGreenOutlineForm('desc')} onBlur={() => setGreenOutlineForm('')} style={{ height: 100, width: screenSize === 'Extra small' ? '100%' : '40%', fontFamily: 'Inter', fontWeight: 400, backgroundColor: 'white', borderRadius: 10, marginTop: 5, padding: '10px 15px 0px 15px', borderWidth: 2, borderColor: greenOutlineForm === 'desc' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid'}} type={'text'} placeholder={'Description of Position'} value={description} onChange={text => {setDescription(text.target.value);  setFormUpdated(!formUpdated)}} defaultValue={description} />
          <div style={{ marginTop: 10 }}>
            <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center' }}>
              <input type={'checkbox'} name='tips' onClick={() => setTips(!tips)} checked={tips} style={{fontFamily: 'Inter', fontWeight: 400, fontSize: 13}} />
              May recieve tips
              </label>
          </div>

          <div style={{ marginTop: 10 }}>
            <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center' }}>
              <input type={'checkbox'} name='tips' onClick={() => setRemote(!remote)} checked={remote} style={{fontFamily: 'Inter', fontWeight: 400, fontSize: 13}} />
              Remote
              </label>
          </div>

        </div>
        </div>
        
      {/* LINE */}
      <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,.15)', width: '80vw', marginLeft: '10vw', display: 'flex', marginTop: 30, marginBottom: 30 }} />

      {/* YOUR POSTING */}
      <div style={{ width: '100vw', display: 'flex', flexDirection: 'row' }}>
        {/* TITLE */}
        <div style={{ width: '31vw', display: screenSize === 'Extra small' ? 'none' : 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: 200}}>
            <div style={{ width: 24, height: 24, borderRadius: 200, backgroundColor: 'forestgreen', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <b style={{ fontSize: 16, color:' white' }}>2</b>
            </div>
            <p style={{ fontFamily: 'Inter', marginLeft: 10, marginRight: 15, fontSize: 16, fontWeight: 600}}>Your Posting</p>
          </div>
        </div>

        {/* Form container */}
        <div style={{ width: screenSize === 'Extra small' ? '100vw' : '69vw', marginLeft: screenSize === 'Extra small' ? 20 : 0 }}>
          <div style={{ flexDirection: 'row', justifyContent: 'flex-start'  }}>
            {previewImage === '' && (
              <label
                onDrop={e => handleDrop(e)}
                onDragOver={e => handleDragOver(e)}
                onDragEnter={e => handleDragEnter(e)}
                onDragLeave={e => handleDragLeave(e)}
                onMouseEnter={() => setIsHoveringUploadImage(true)} onMouseLeave={() => setIsHoveringUploadImage(false)} for="files" style={{ width: screenSize === 'Extra small' ? '100%' : '40%', height: 100, borderRadius: 20, backgroundColor: isHoveringUploadImage ? '#ededed90' : '#ededed', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 20, cursor: isHoveringUploadImage ? 'pointer' : 'default' }}>
                <div style={{ width: 40, height: 40, opacity: .3, backgroundImage: `url(${addIcon})`, backgroundSize: 'cover' }} />
                <p style={{ margin: 0, fontFamily: 'Inter', fontSize: 14, color: 'rgba(0,0,0,.5)', fontWeight: 500, marginTop: 10 }}>Upload Image (required)</p>
            </label>)}

              {previewImage !== '' && (
                // <img src={previewImage} style={{ width: '40%', height: 100, borderRadius: 20, marginTop: 20 }} />
                <div style={{ width: '40%', height: 100, position: 'relative', borderRadius: 20, marginTop: 20, backgroundImage: `url(${previewImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
    
                  <div onClick={() => {
                    if (previewImage !== '') {
                      setPreviewImage(''); setImageAt(0); setImageAsFile(''); setFormUpdated(!formUpdated)
                    } else {
                      setPreviewImage(''); setImageAt(0); setImageAsFile(''); setFormUpdated(!formUpdated)
                    }
                  }} onMouseEnter={() => setIsHoveringUploadImage(true)} onMouseLeave={() => setIsHoveringUploadImage(false)} style={{ width: 25, height: 25, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: isHoveringUploadImage ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,.5)', position: 'absolute', bottom: 8, right: 8, cursor: isHoveringUploadImage ? 'pointer' : 'default' }}>
                    <Icon path={mdiDeleteOutline} size={'16px'} color='white' />
                  </div>

                </div>
              )}
            <input type="file" id="files" onChange={handleImageAsFile} style={{ visibility: 'hidden'}} /><br></br>
            {/* <p style={{fontFamily: 'Inter', width: 200}}>{imageAsFile.name}</p> */}
          </div>

          <p style={{ fontFamily: 'Inter', fontSize: 16, marginTop: 15, fontWeight: 700 }}>Posting Duration</p>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10}}>
                <input type={'radio'} value={7} checked={(postDuration === '7' || postDuration === 7)} onChange={text => {setPostDuration(text.target.value);  setFormUpdated(!formUpdated)}} />
                7 days
              </label>
              <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10}}>
                <input type={'radio'} value={30} checked={(postDuration === '30' || postDuration === 30)} onChange={text => {setPostDuration(text.target.value);  setFormUpdated(!formUpdated)}}  />
                30 days
              </label>
              <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10}}>
                <input type={'radio'} value={90} checked={(postDuration === '90' || postDuration === 90)} onChange={text => {setPostDuration(text.target.value);  setFormUpdated(!formUpdated)}}  />
                90 days
              </label>
              <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10}}>
                <input type={'radio'} value={180} checked={(postDuration === '180' || postDuration === 180)} onChange={text => {setPostDuration(text.target.value);  setFormUpdated(!formUpdated)}}  />
                180 days
            </label>
              <label style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 13, display: 'flex', alignItems: 'center', marginRight: 10}}>
                <input type={'radio'} value={365} checked={(postDuration === '365' || postDuration === 365)} onChange={text => {setPostDuration(text.target.value);  setFormUpdated(!formUpdated)}}  />
                365 days
              </label>
          </div>
          </div>
          

        </div>

      {/* LINE */}
      <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,.15)', width: '80vw', marginLeft: '10vw', display: 'flex', marginTop: 40, marginBottom: 30 }} />

      {/* BUSINESS INFORMATION */}
      <div style={{ width: '100vw', display: 'flex', flexDirection: 'row'}}>
        {/* TITLE */}
        <div style={{ width: '31vw', display: screenSize === 'Extra small' ? 'none' : 'flex', alignItems: 'flex-start', justifyContent: 'flex-end'}}>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: 200}}>
            <div style={{ width: 24, height: 24, borderRadius: 200, backgroundColor: 'forestgreen', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <b style={{ fontSize: 16, color:' white' }}>3</b>
            </div>
            <p style={{ fontFamily: 'Inter', marginLeft: 10, marginRight: 15, fontSize: 16, fontWeight: 600}}>Business Info</p>
          </div>
        </div>
        {/* Form container */}
        <div style={{ width: '69vw', marginLeft: screenSize === 'Extra small' ? 20 : 0 }}>
          <input onFocus={() => setGreenOutlineForm('bname')} onBlur={() => setGreenOutlineForm('')} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, width: '20%' - 30, backgroundColor: 'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, borderColor: greenOutlineForm === 'bname' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid' }} type={'text'} placeholder={'Business name'} value={businessName} onChange={text => { setBusinessName(text.target.value); setFormUpdated(!formUpdated) }} defaultValue={businessName} />
        <input onFocus={() => setGreenOutlineForm('bwebsite')} onBlur={() => setGreenOutlineForm('')} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, width: '20%'  - 30, marginLeft: screenSize === 'Extra small' ? 0 : 15, backgroundColor: 'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, borderColor: greenOutlineForm === 'bwebsite' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid'}} type={'text'} placeholder={'Website'} value={businessWebsite} onChange={text => {setBusinessWebsite(text.target.value);  setFormUpdated(!formUpdated)}} defaultValue={businessWebsite} />
          <br></br>
        <input onFocus={() => setGreenOutlineForm('bapp')} onBlur={() => setGreenOutlineForm('')} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, width: '40%' - 30, backgroundColor: 'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, borderColor: greenOutlineForm === 'bapp' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid' }} type={'text'} placeholder={'External Application Link'} value={businessApplication} onChange={text => { setBusinessApplication(text.target.value); setFormUpdated(!formUpdated) }} defaultValue={businessApplication} />
          <br></br>
        <LocationAutocomplete
              name="venue"
            placeholder="Business Address"
            defaultValue={address}
              componentRestrictions={{ country: ['us'] }}
              //locationType="(regions)"
              googleAPIKey="AIzaSyAxkiHdR0dvsMNPJ4T8qyIsh2364g_rc4k"
            onChange={text => { setAddress(text); setFormUpdated(!formUpdated) }}
            onDropdownSelect={(item) => onDropdownSelect(item)}
            onFocus={() => setGreenOutlineForm('location')} onBlur={() => setGreenOutlineForm('')}
              style={{ width: screenSize === 'Extra small' ? '100%' : '40%', fontFamily: 'Inter', fontWeight: 400, fontSize: 13, height: 30, backgroundColor: 'white', borderRadius: 10, marginTop: 10, padding: '0px 15px 0px 15px', borderWidth: 2, borderColor: greenOutlineForm === 'location' ? '#378805' : 'rgba(0,0,0,.1)', outline: 0, borderStyle: 'solid' }}
              />
            
          </div>
          
        </div>
        
      {/* LINE */}
      <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,.15)', width: '80vw', marginLeft: '10vw', display: 'flex', marginTop: 40, marginBottom: 30 }} />

      
      {/* CONTACT INFORMATION */}
      <div style={{  width: '100vw', display: 'flex', flexDirection: 'row', marginBottom: 150 }}>
        {/* TITLE */}
        <div style={{ width: '31vw',  display: screenSize === 'Extra small' ? 'none' : 'flex', alignItems: 'flex-start', justifyContent: 'flex-end'}}>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: 200}}>
            <div style={{ width: 24, height: 24, borderRadius: 200, backgroundColor: 'forestgreen', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <b style={{ fontSize: 16, color:' white' }}>4</b>
            </div>
            <p style={{ fontFamily: 'Inter', marginLeft: 10, marginRight: 15, fontSize: 16, fontWeight: 600}}>Contact Info</p>
          </div>
        </div>

        {/* Form container */}
        <div style={{ width: '69vw', marginLeft: screenSize === 'Extra small' ? 20 : 0, marginBottom: screenSize === 'Extra small' ? 30 : 0 }}>
          <input onFocus={() => setGreenOutlineForm('cname')} onBlur={() => setGreenOutlineForm('')} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, fontSize: 13, width: '20%' - 30, backgroundColor: 'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, outline: 0, borderColor: greenOutlineForm === 'cname' ? '#378805' : 'rgba(0,0,0,.1)', borderStyle: 'solid' }} type={'text'} placeholder={'Name'} onChange={text => {setContactName(text.target.value);  setFormUpdated(!formUpdated)}} defaultValue={contactName} />
          <input value={contactPhone} onFocus={() => setGreenOutlineForm('cphone')} onBlur={() => setGreenOutlineForm('')} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, fontSize: 13, width: '20%' - 30, marginLeft: screenSize === 'Extra small' ? 0 : 15, backgroundColor: 'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, outline: 0, borderColor: greenOutlineForm === 'cphone' ? '#378805' : 'rgba(0,0,0,.1)', borderStyle: 'solid' }} onChange={text => { onChangedText(text.target.value);  setFormUpdated(!formUpdated)}} placeholder={'Phone number'} />
          
          <br></br>

          <input onFocus={() => setGreenOutlineForm('cemail')} onBlur={() => setGreenOutlineForm('')} style={{ height: 30, fontFamily: 'Inter', fontWeight: 400, fontSize: 13, width: '20%' - 30, backgroundColor: 'white', borderRadius: 10, marginTop: 15, padding: '0px 15px 0px 15px', borderWidth: 2, outline: 0, borderColor: greenOutlineForm === 'cemail' ? '#378805' : 'rgba(0,0,0,.1)', borderStyle: 'solid' }} type={'text'} onChange={text => { setContactEmail(text.target.value); setFormUpdated(!formUpdated) }} placeholder={'Email'} defaultValue={contactEmail} />
        </div>
      </div>

      {/* Bottom bar */}
      <div style={{ width: '100vw', backgroundColor: '#ededed', display: 'flex', flexDirection: 'row', position: 'fixed', bottom:0, }}>
        <div style={{ width: '31vw', display: screenSize === 'Extra small' ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 50, alignItems: 'center' }}>
          <img src={smallGreenTHWIcon} style={{ width: 40, height: 40}} />
        </div>
        <div style={{ width: screenSize === 'Extra small' ? '100vw' : '69vw', paddingTop: editingPost ? 10 : 30, paddingBottom: editingPost ? 20 : 30, display: 'flex', flexDirection: screenSize === 'Extra small' ? 'column' : 'row', marginLeft: screenSize === 'Extra small' ? 15 : 0 }}>
          {!editingPost && (
            <div style={{ marginRight: 50 }}>
              <div style={{ display: 'flex', flexDirection: 'row', margin: 0, padding: 0 }}>
                <input type={'checkbox'} onChange={() => { setUnderstood(!understood); setFormUpdated(!formUpdated) }} />
                <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, margin: 0 }}> I have read and agree to the <span style={{ textDecoration: 'underline', color: '#378805', fontWeight: 500, cursor: hoverTerms ? 'pointer' : 'default' }} onMouseEnter={() => setHoveringTerms(true)} onMouseLeave={() => setHoveringTerms(false)} onClick={() => setOpenedPosting('terms-of-service')}>Terms of Service</span></p>
              </div>
            
              <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0px 0px 0px', padding: 0 }}>
                <input type={'checkbox'} onChange={() => setSaveDefaults(!saveDefaults)} />
                <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, margin: 0 }}> Save defaults (for future postings)</p>
              </div>
            </div>
          )}

          {formReady ? (
            <button disabled={paymentLoading} onMouseEnter={() => setHoverSubmit(true)} onMouseLeave={() => setHoverSubmit(false)}
              onClick={() => {
                //createCheckoutSession(userA.uid, postDuration);
                setPaymentLoading(true);
                handleSubmit()
              }} style={{ marginTop: 10, width: 105, height: 40, cursor: hoverSubmit ? 'pointer' : 'default', backgroundColor: paymentLoading ? 'lightgrey' : '#378805', borderRadius: 400, borderWidth: 0, color: 'white', fontFamily: 'Inter', fontWeight: 600, fontSize: 18 }}>{paymentLoading ? 'Loading' : 'Post Job'}</button>
          ) :
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <button onClick={() => alert('Please check this page. You are missing a required field to post this job.', formError)} style={{ marginTop: 10, width: 105, height: 40, backgroundColor: 'grey', cursor: hoverSubmit ? 'pointer' : 'default', borderRadius: 400, borderWidth: 0, color: 'white', fontFamily: 'Inter', fontWeight: 600, fontSize: 18 }}>{editingPost ? 'Save' : 'Post Job'} </button>
              {editingPost && (<a style={{ textDecoration: 'none', marginLeft: 20, marginTop: 10, color: 'rgba(0,0,0,.7)' }} href="javascript:history.back()">Cancel</a>)}
            </div>
          }

          </div>
      </div>
        
      {openedPosting === 'terms-of-service' && (
        <div onClick={() => tryToClose()} style={{ position: 'fixed', padding: 0, margin: 0, top: 0, height: '100%', left: 0, width: '100%', background: 'rgba(0,0,0,.4)', zIndex: 4 }}>
          <div  onMouseEnter={() => setDoNotClose(true)} onMouseLeave={() => setDoNotClose(false)} style={{ marginRight: 30, width: screenSize === 'Large' ? '50vw' : screenSize === 'Medium' ? '60vw' : screenSize === 'Small' ? '80vw' : '90vw', position: 'absolute', backgroundColor: 'white', top: '5vh', left: screenSize === 'Large' ? '25vw' : screenSize === 'Medium' ? '20vw' : screenSize === 'Small' ? '10vw' : '5vw', borderRadius: 15, zIndex: 6 }} >
            <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 20, textAlign: 'center' }}>Terms of Service</p>
            <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 14, padding: '0px 50px 20px 50px'}}>
The Hourly Worker serves to provide potential employees with information on local job opportunities. The opportunities published on this website include contact information to allow the employee (the “Employee”) to communicate directly with the third-party employer (the "Employer") regarding the job opening.
<br></br><br></br>
All information contained in the job posting was created and provided by the Employer over whom The Hourly Worker exercises no control. The Hourly Worker plays no role in and therefore maintains no responsibility for any job requirements or conditions that the Employer may impose after the Employee submits an application to the Employer. The Hourly Worker shall similarly have no liability for working conditions or for any conduct of the Employer as it relates to the Employee's employment. The Hourly Worker serves to connect the Employee with the Employer and makes no guarantees or promises in connection with the Employee’s employment.
<br></br><br></br>
The Hourly Worker is not liable for any agreements, offers, or promises between the Employee and the Employer. Nor is The Hourly Worker liable for any costs and/or damages that may arise from the Employee entering into any agreement with the Employer. Once the Employer has the Employee’s contact information, The Hourly Worker is not liable for the manner in which the Employer uses this information.
<br></br><br></br>
Under no circumstances shall The Hourly Worker be liable to any Employee for his/her use or misuse of or reliance on this website, whether the damages arise from reliance caused by information posted on the website, the Employee’s inability to use the website, or from the interruption, suspension, or termination of the website.

             </p>
          </div>
        </div>
      )}

      </div>
//     <div style={{ flex: 1, backgroundColor: 'white', margin: 0, padding: 0 }}>
//                 <div style={{ backgroundColor: '#ededed', width: `${100 - outerMargins}%`, height: 80, marginTop: 0, paddingTop: 0, marginBottom: 0, paddingLeft: `${outerMargins}%`, justifyContent: 'center' }}>
//                   <p style={{ fontSize: 32, paddingTop: 21, marginTop: 0, fontFamily: 'Inter', fontWeight: 600, marginBottom: 0, paddingBottom: 0 }}>Post a Job</p>
//                 </div>
//                 <form style={{ paddingLeft: `${outerMargins}%`, paddingRight: `${outerMargins}%`, marginTop: 20, paddingTop: 0, marginBottom: 50}} action='/' method='' onSubmit={handleSubmit}>
//                   <div style={{ marginBottom: 20 }}>
//                     <label style={{ fontFamily: 'Inter', fontWeight: 600 }}>Is this for a business?</label><br></br>
//                     <label style={{ marginRight: 20, fontFamily: 'Inter', fontWeight: 400 }}>Business<input type='radio' name='select_type' value='select_business' onClick={() => {setSelectedFor('business')}} /></label>
//                     <label style={{ fontFamily: 'Inter', fontWeight: 400 }}>Personal<input type='radio' name='select_type' value='select_personal' onClick={() => {setSelectedFor('personal')}}  /></label>
//                   </div>

//                     {selectedFor === 'business' && (
//                       <div>
//                         <label for='business_name' style={{ fontFamily: 'Inter', fontWeight: 600 }}>Business Name </label><br></br>
//                         <input defaultValue={businessName} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="business_name" name="business_name" onChange={text => setBusinessName(text.target.value)}/>
//                       </div>
//                     )}
//                   {selectedFor === 'business' && (
//                     <div style={{  marginBottom: 30 }}>
//                       <label for='busines_address' style={{ fontFamily: 'Inter', fontWeight: 600 }}>Business Address </label><br></br>
//                         <div style={{ paddingRight: '5%'}}>
//                           <label for='busines_address_street' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Street</label><br></br>
//                           <input defaultValue={businessStreet} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="busines_address_street" name="busines_address_street"  onChange={text => setBusinessStreet(text.target.value)} />
//                         </div>
//                         <div style={{ paddingRight: '5%'}}>
//                           <label for='busines_address_city' style={{ fontFamily: 'Inter', fontWeight: 400 }}>City</label><br></br>
//                           <input defaultValue={businessCity} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="busines_address_city" name="busines_address_city"  onChange={text => setBusinessCity(text.target.value)} />
//                         </div>
//                         <div style={{ paddingRight: '5%'}}>
//                           <label for='busines_address_state' style={{ fontFamily: 'Inter', fontWeight: 400 }}>State</label><br></br>
//                           <input defaultValue={businessState} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="busines_address_state" name="busines_address_state"  onChange={text => setBusinessState(text.target.value)} />
//                         </div>
//                         <div style={{ paddingRight: '5%'}}>
//                           <label for='busines_address_zip' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Zip</label><br></br>
//                           <input defaultValue={businessZip} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="busines_address_zip" name="busines_address_zip"  onChange={text => setBusinessZip(text.target.value)} />
//                         </div>
//                     </div>
//               )}
//                     <div style={{  marginBottom: 30 }}>
//                       <label style={{ fontFamily: 'Inter', fontWeight: 600 }}>Contact Information</label><br></br>
//                         <div style={{ paddingRight: '5%'}}>
//                           <label for='contact_name' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Name*</label><br></br>
//                           <input defaultValue={contactName} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="contact_name" name="contact_name"  onChange={text => setContactName(text.target.value)} />
//                         </div>
//                         <div style={{ paddingRight: '5%'}}>
//                           <label for='contact_phone' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Phone*</label><br></br>
//                           <input defaultValue={contactPhone} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="tel" id="contact_phone" name="contact_phone"  onChange={text => setContactPhone(text.target.value)} />
//                         </div>
//                         <div style={{ paddingRight: '5%'}}>
//                           <label for='contact_email' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Email</label><br></br>
//                           <input defaultValue={contactEmail} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="email" id="contact_email" name="contact_email"  onChange={text => setContactEmail(text.target.value)} />
//                         </div>
//                     </div>
//                     <div style={{ paddingRight: '15%' }}>

//                 <label style={{ fontFamily: 'Inter', fontWeight: 600 }}>Job Details</label><br></br>
//                     <div style={{ paddingRight: '15%'}}>
//                       <label for='job_title' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Job Title*</label><br></br>
//                       <input defaultValue={jobTitle} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="job_title" name="job_title" onChange={text => setJobTitle(text.target.value)} />
//                     </div>
//                       <label for='job_wage' style={{ fontFamily: 'Inter', fontWeight: 400, marginTop: 150 }}>Hourly Wage</label><br></br>
//                       <input defaultValue={hourlyWage} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="job_wage" name="job_wage"  onChange={text => setHourlyWage(text.target.value)} />
//                     </div>
//                     <div style={{ paddingRight: '15%'}}>
//                       <label for='job_one_time_payment' style={{ fontFamily: 'Inter', fontWeight: 400 }}>One-Time Payment</label><br></br>
//                       <input defaultValue={oneTimePayment} style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="text" id="job_one_time_payment" name="job_one_time_payment"  onChange={text => setOneTimePayment(text.target.value)} />
//                     </div>
//                     <div>
//                       <input type='checkbox' id='fulltime' name='fulltime' value='Full time'  onChange={text => setFullTime(text.target.value)} />
//                       <label for='fulltime' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Full time</label>
//                       <input type='checkbox' id='parttime' name='parttime' value='Part time'  onChange={text => setFullTime(text.target.value)}/>
//                       <label for='parttime' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Part time</label>
//                       <input type='checkbox' id='flexible' name='flexible' value='Flexible'  onChange={text => setFullTime(text.target.value)}/>
//                       <label for='flexible' style={{fontFamily: 'Inter', fontWeight: 400}}>Flexible</label>
//                     </div>
//                     <div style={{ marginTop: 15, marginBottom: 35 }}>
//                       <input type='checkbox' id='tips' name='tips' value='Tips'  onChange={text => setTips(text.target.value)}/>
//                       <label for='tips' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Tips</label>
//               </div>

//                 <label for="dog-names" style={{ fontFamily: 'Inter', fontWeight: 400 }}>Choose a category*</label><br></br>
//                 <select name="categories" id="categories" onChange={text => setCategory(text.target.value)} style={{ width: 400, backgroundColor: '#f8f8f8', height: 40, border: 0, fontFamily: 'Inter', fontWeight: 400, fontSize: 18}}>
//                   <option value="computer">Computer Skills</option>
//                   <option value="customerService">Customer Service</option>
//                   <option value="delivery">Delivery/Transportation</option>
//                   <option value="restaurant">Food/Restaurant</option>
//                   <option value="health">Health Care</option>
//                   <option value="home">Home Services</option>
//                   <option value="landscaping">Landscaping/Physical</option>
//                   <option value="maintenance">Maintenance</option>
//                   <option value="marketing">Marketing/Sales</option>
//                   <option value="other">Miscellaneous</option>
//                   <option value="office">Office Support</option>
//                   <option value="pet">Personal Care/Services</option>
//                   <option value="retail">Retail</option>
//                   <option value="trade">Mechanical/Trade</option>
//                   <option value="warehouse">Warehouse</option>
//                 </select>
              

//                  <p style={{ fontFamily: 'Inter', fontWeight: 400, marginTop: 35 }}>Upload a thumbnail*</p>
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 50, width: 400 }}>
//                       <label onMouseEnter={() => setIsHoveringUploadImage(true)} onMouseLeave={() => setIsHoveringUploadImage(false)} for="files" style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 14, backgroundColor: isHoveringUploadImage ? 'black' : '#378805', cursor: isHoveringUploadImage ? 'pointer' : 'default', color: 'white', width: 200, height: 20, textAlign: 'center', paddingTop: 10, paddingBottom: 10, borderRadius: 50, marginRight: 0 }}>Upload Image</label>
//                       <input type="file" id="files" onChange={handleImageAsFile} style={{ visibility: 'hidden'}} /><br></br>
//                       <p style={{fontFamily: 'Inter', width: 200}}>{imageAsFile.name}</p>
//                 </div>
//                   <div>
//                     <label for='description' style={{ fontFamily: 'Inter', fontWeight: 400, marginTop: 30, marginBottom: 0 }}>Description</label><br></br>
//                     <textarea defaultValue={description}  style={{ height: 40, width: 400, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} id="description" name="description" rows="250" cols="400"   onChange={text => setDescription(text.target.value)}/>
//                   </div>
        
//                   <div style={{  marginBottom: 30 }}>
//                     <label style={{ fontFamily: 'Inter', fontWeight: 600 }}>Posting Duration*</label><br></br>
//                       <input type='radio' id='7days' name='duration' value={7}  onChange={text => setPostDuration(text.target.value === postDuration ? '' : text.target.value)} />
//                       <label for='7days' style={{ fontFamily: 'Inter', fontWeight: 400 }}>7 days</label>
//                       <input type='radio' id='30days' name='duration' value={30}  onChange={text => setPostDuration(text.target.value === postDuration ? '' : text.target.value)}/>
//                       <label for='30days' style={{ fontFamily: 'Inter', fontWeight: 400 }}>30 days</label>
//                       <input type='radio' id='90days' name='duration' value={90}  onChange={text => setPostDuration(text.target.value === postDuration ? '' : text.target.value)}/>
//                       <label for='90days' style={{ fontFamily: 'Inter', fontWeight: 400 }}>90 days</label>
//                       <input type='radio' id='180days' name='duration' value={180}  onChange={text => setPostDuration(text.target.value === postDuration ? '' : text.target.value)}/>
//                       <label for='180days' style={{fontFamily: 'Inter', fontWeight: 400}}>180 days</label>
//                       <p>{postDuration}</p>
//                     </div>
                    
//                     <div style={{ marginBottom: 30 }}>
//                       <input type='checkbox' id='flexible' name='save' value='save'  onChange={() => setSaveDefaults(!saveDefaults)}/>
//                       <label for='save' style={{ fontFamily: 'Inter', fontWeight: 400 }}>Save as default information on account to speed up future postings</label>
//                     </div>
        
//               {hourlyWage === '' && oneTimePayment === '' ? (<div><label style={{ fontFamily: 'Inter', fontWeight: 600, color: 'red' }}>You must fill out either an hourly wage, or a one time payment.</label></div>) : <p>&nbsp;</p>}
//         <p>{savingMessage}</p>
//         {!(jobTitle === '' || contactName === '' || contactPhone === '' || postDuration === '' || imageAsFile === '' || (hourlyWage === '' && oneTimePayment === '')) ? (
//           <input onMouseEnter={() => setIsHoveringSubmitJob(true)} onMouseLeave={() => setIsHoveringSubmitJob(false)} style={{ backgroundColor: (jobTitle === '' || contactName === '' || contactPhone === '' || postDuration === '' || imageAsFile === '' || (hourlyWage === '' && oneTimePayment === '') || savingMessage === 'Saving') ? 'grey' : (isHoveringSubmitJob ? 'black' : '#378805'), width: 84, height: 35, borderRadius: 100, borderWidth: 0, marginRight: 10, marginTop: 15, color: 'white', fontFamily: 'Inter', cursor: (jobTitle === '' || contactName === '' || contactPhone === '' || postDuration === '' || imageAsFile === '' || (hourlyWage === '' && oneTimePayment === '')) ? 'default' : (isHoveringSubmitJob ? 'pointer' : 'defualt'), fontWeight: 600, fontSize: 16}} type='submit' value='Submit' />
//         ) : <p>Fill out all the required information</p>
//         }
//             </form>
//               </div>
  )
}
