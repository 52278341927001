import React, { useState, useEffect } from 'react'
import allWhiteLogo from '../../images/allWhiteLogo.png'
import appStoreLogo from '../../images/app-store-logo.png'
import { Link } from 'react-router-dom'
import firebase from 'firebase'

export default function Footer() {

  const [loggedIn, setLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [hovering, setHovering] = useState('')

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
  })

  }, [])

  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])

  return (
    <body>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com"/><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
      </head>

      {/* FOOTER */}
      <div style={{ width: '100%', height: 5, backgroundColor: '#378805' }} />
      <div style={{ width: '100%', backgroundColor: '#111', padding: 0, margin: 0, display: 'flex'}}>
        <div style={{ flex: 1 }} />
        {screenSize === 'Large' ? (
          <div style={{ flex: 2, paddingTop: 50, paddingBottom: 50 }}>
            <img src={allWhiteLogo} style={{ height: 80 }} />
            <p style={{ fontFamily: 'Inter', color: '#444', fontWeight: 400, textAlign: 'justify', fontSize: 14, marginLeft: 20, marginRight: 40 }}>Copyright 2023 Punching Clock. <br></br><br></br>All data and content on the website is for your informational use only.  You may not copy, redistribute, sell, retransmit, or make the data available to a third party, or otherwise use it for any unrelated commercial or public purpose unless you have a seperate written agreement with Punching Clock.</p>
          </div>
        ) : <div />}
        {screenSize === 'Extra small' ? (<div />) :
            <div style={{ flex: 2, backgroundColor: '#111', paddingTop: 55, paddingBottom: 50 }}>
            <p style={{ fontFamily: 'Inter', fontWeight: 600, color: 'white', marginLeft: 60 }}>Solutions</p>
            <Link to='/' style={{ textDecoration: 'none' }} onMouseEnter={() => setHovering('work')} onMouseLeave={() => setHovering('')}>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60, cursor: hovering === 'work' ? 'pointer' : 'default' }}>Find Hourly Work</p>
            </Link>
            <Link to={loggedIn ? '/post' : '/signup'} style={{ textDecoration: 'none' }} onMouseEnter={() => setHovering('post')} onMouseLeave={() => setHovering('')}>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60, cursor: hovering === 'post' ? 'pointer' : 'default'}}>Hire Workers</p>
            </Link>
              {/* <img src={appStoreLogo} title="Coming soon!" style={{ height: 40, marginTop: 50, marginLeft: 60}} /> */}
            </div>
        }
        {screenSize === 'Extra small' ? (<div />) :
          <div style={{ flex: 2, backgroundColor: '#111', paddingTop: 55, paddingBottom: 50 }}>
            <p style={{ fontFamily: 'Inter', fontWeight: 600, color: 'white', marginLeft: 60 }}>Our Company</p>
            <Link to='/about' style={{ textDecoration: 'none' }}  onMouseEnter={() => setHovering('about')} onMouseLeave={() => setHovering('')}>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60, cursor: hovering === 'about' ? 'pointer' : 'default' }}>About Us</p>
              {/* Need to make this page */}
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60 }}>Meet Our Team</p> 
              {/* Make tutorials */}
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60 }}>How It Works</p>
              {/* Need to make this page */}
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60 }}>Why Use Us?</p>
            </Link>
            <a href='https://www.karalewich.com/thehourlyworker-feedback.html' target="_blank" style={{ textDecoration: 'none', cursor: hovering === 'contact' ? 'pointer' : 'default' }}  onMouseEnter={() => setHovering('contact')} onMouseLeave={() => setHovering('')}>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60 }}>Contact Us</p>
            </a>
          </div>
        }
        {screenSize === 'Extra small' ? (<div />) :
          <div style={{ flex: 2, backgroundColor: '#111', paddingTop: 55, paddingBottom: 50 }}>
            <a href='https://www.karalewich.com/thehourlyworker-feedback.html' target="_blank" style={{ textDecoration: 'none', cursor: hovering === 'contact' ? 'pointer' : 'default' }}  onMouseEnter={() => setHovering('contact')} onMouseLeave={() => setHovering('')}>
              <p style={{ fontFamily: 'Inter', fontWeight: 600, color: 'white', marginLeft: 60 }}>Help & Support</p>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60 }}>Need Help?</p>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60 }}>Send Feedback</p>
            </a>
            <Link to='/privacy-policy' style={{ textDecoration: 'none' }}  onMouseEnter={() => setHovering('privacy-policy')} onMouseLeave={() => setHovering('')}>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60, cursor: hovering === 'privacy-policy' ? 'pointer' : 'default' }}>Privacy Policy</p>
            </Link>
            <a href='https://www.karalewich.com/thehourlyworker-feedback.html' target="_blank" style={{ textDecoration: 'none', cursor: hovering === 'contact' ? 'pointer' : 'default' }}  onMouseEnter={() => setHovering('contact')} onMouseLeave={() => setHovering('')}>
              <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#666', marginLeft: 60 }}>Report an Issue</p>
            </a>
          </div>
        }
        <div style={{ flex: 1, backgroundColor: '#111' }} />
        {/* <img src={allWhiteLogo} style={{ height: 70 }} /> */}
      </div>
      {screenSize === 'Large' ? (<div />) :
          <div style={{ paddingBottom: 50, paddingTop: screenSize === 'Extra small' ? 50 : 0, backgroundColor: '#111', paddingRight: '16.6%', paddingLeft: '16.6%' }}>
            <img src={allWhiteLogo} style={{ height: 80 }} />
            <p style={{ fontFamily: 'Inter', color: '#444', fontWeight: 400, textAlign: 'justify', fontSize: 14, marginLeft: 20, marginRight: 40 }}>Copyright 2023 Punching Clock. <br></br><br></br>All data and content on the website is for your informational use only.  You may not copy, redistribute, sell, retransmit, or make the data available to a third party, or otherwise use it for any unrelated commercial or public purpose unless you have a seperate written agreement with Punching Clock.</p>
          </div>
      }
    </body>
  )
}
