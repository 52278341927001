import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise = null
console.log('ai')

const initializeStripe = async () => {
  console.log('ji')
  if (!stripePromise) {
    stripePromise = await loadStripe('pk_live_51KHVUXL1QUPQvIOGjWBwzWHYNGykGDYqq2nI3WgEKLR2NsyjvfJrxhdqvmtT3YaPzaLd1yENLeCx4lEcOyt6u4Y500iNsEBNb6')
  }
  console.log('stripePromise', stripePromise)
  return stripePromise
}

export default initializeStripe