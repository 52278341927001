// //~Firebase
// import firebase from 'firebase'
// require('firebase/firestore')


const initialState = {
  currentLatRedux: 49,
  currentLngRedux: 49,
}

export const mainReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'CURRENT_LAT':
      return { ... state, currentLatRedux: action.payload } // {copy of the state, then set counter to: point to current counter and add one} ... so if it was a color, you just set it equal to whatever the new color is
    case 'CURRENT_LNG':
      return { ... state, currentLngRedux: action.payload } // {copy of the state, then set counter to: point to current counter and add one} ... so if it was a color, you just set it equal to whatever the new color is
    default:
      return state
  }
}

export const setCurrentLat = a => ({
  type: 'CURRENT_LAT',
  payload: a
})

export const setCurrentLng = a => ({
  type: 'CURRENT_LNG',
  payload: a
})

export const fetchCurrentLat = (input) => {
  return function (dispatch) {
    dispatch(setCurrentLat(input))
  }
}

export const fetchCurrentLng = (input) => {
  return function (dispatch) {
    dispatch(setCurrentLng(input))
  }
}
