import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import 'firebase/firestore'
import { Redirect } from 'react-router-dom'
import Icon from '@mdi/react'
import {
  mdiClose,
  mdiDelete,
  mdiBookmark
} from '@mdi/js';
import Footer from '../layout/Footer'


export default function SavedJobs() {

  const haversine = require('haversine')

  const [currentLat, setCurrentLat] = useState('no-location-permissions')
  const [currentLng, setCurrentLng] = useState('no-location-permissions')

  const [savedPosts, setSavedPosts] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [postsLoaded, setPostsLoaded] = useState(false)
  const [bookmarkHovering, setBookmarkHovering] = useState('')
  const [doNotClose, setDoNotClose] = useState(false)
  const [isHoveringPost, setIsHoveringPost] = useState(false)
  const [isHoveringClose, setIsHoveringClose] = useState(false)
  const [sectionHovering, setSectionHovering] = useState('')
  const [openedPosting, setOpenedPosting] = useState('')
  const [recentlyDeleted, setRecentlyDeleted] = useState(0)
  const [toggleUnsaved, setToggleUnsaved] = useState(0)
    const [loadedLocation, setLoadedLocation] = useState(false)

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        setCurrentLat(position.coords.latitude)
        setCurrentLng(position.coords.longitude)
      }, (error) => {
        setCurrentLat('error-latitude')
        setCurrentLng('error-longitude')
      })
    }
  }, [loaded])

  useEffect(() => {
    if (currentLat !== 'no-location-permissions') {
      if (currentLng !== 'no-location-permissions') {
       setLoadedLocation(!loadedLocation)
      }
    }
  }, [currentLat, currentLng])
  
  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
    })
  }, [])

  useEffect(() => {
    if (loggedIn) {
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('userSaved').get().then(querySnapshot => {
        const savedPostData = []
        setSavedPosts('')
        querySnapshot.forEach(doc => {
          firebase.firestore().collection('projects').doc(doc.id).get().then(allData => {
            savedPostData.push({ ...allData.data(), id: allData.id })
          }).then(() => {
              setSavedPosts(savedPostData)
              setPostsLoaded(true)
          })
        })

      })
      setPostsLoaded(true)
    }
  }, [loggedIn, loaded, toggleUnsaved])

  const tryToClose = () => {
    if (!doNotClose) {
      setOpenedPosting('')
    } else if (isHoveringClose) {
      setOpenedPosting('')
      setDoNotClose(false)
      setIsHoveringClose(false)
    }
  }

  const unsave = toBeUnsaved => {
    firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('userSaved').doc(toBeUnsaved).delete().then(() => {
      setToggleUnsaved(toggleUnsaved + 1)
    })
  }

  const getSavedPosts = from => {
    //on click of an element it makes it almost full screen and you can see all the details and sign up!
    if ((savedPosts.length === 0 || savedPosts == undefined || savedPosts === null) && postsLoaded) {
      return (
        <div style={{ height: 120, backgroundColor: 'rgba(255, 0, 0, .08)', width: `${76}%`, padding: 0, marginBottom: 200, borderRadius: 10 }}>
          <p style={{ fontFamily: 'Inter', fontWeight: 600, color: 'rgba(255,0,0,.8)', fontSize: 20, padding: 0, margin: 0, lineHeight: '120px', textAlign: 'center'}}>{'You have no saved posts.'}</p>
        </div>
      )
    }

    return Array.from(savedPosts).map(item => (
    <div>
        {openedPosting === item.id ? (
          <div>
            <div onClick={() => tryToClose()} style={{ position: 'fixed', padding: 0, margin: 0, top: 0, height: '100%', left: 0, width: '100%', background: 'rgba(0,0,0,.4)', zIndex: 4 }}>
        <div style={{ marginRight: 30, width: screenSize === 'Large' ? '40vw' : screenSize === 'Medium' ? '50vw' : screenSize === 'Small' ? '70vw' : '80vw', position: 'absolute', backgroundColor: 'white', top: '15vh', left: screenSize === 'Large' ? '32.5vw' : screenSize === 'Medium' ? '25vw' : screenSize === 'Small' ? '12vw' : '9vw', borderRadius: 15, zIndex: 6 }} onMouseEnter={() => setDoNotClose(true)} onMouseLeave={() => setDoNotClose(false)} onClick={() => setOpenedPosting(item.id)}> 
          {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
                  <div style={{ width: '100%', backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center', height: 225, borderTopLeftRadius: 15, borderTopRightRadius: 15}} />
          ):
            <div style={{ width: '100%', height: 225, borderTopLeftRadius: 15, borderTopRightRadius: 15, backgroundColor: '#ededed' }} />
                }
                <Icon path={mdiClose} onMouseEnter={() => setIsHoveringClose(true)} onMouseLeave={() => setIsHoveringClose(false)} onClick={() => { setOpenedPosting(''); setDoNotClose(false)}} title='Close' size={1} style={{ padding: 3, color: 'black', backgroundColor: isHoveringClose ? 'lightgrey' : 'white', cursor: isHoveringClose ? 'pointer' : 'cursor', borderRadius: 24, position: 'absolute', right: 10, top: 10}} />
                <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0, marginLeft: `8%` }}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, marginLeft: `8%` }}>${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
                {item.description === '' ? (<div />) : <p style={{ fontFamily: 'Inter', fontWeight: 400, marginLeft: `8%`, marginRight: `8%`, marginBottom: 10 }}>{item.description}</p>}

                <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0, marginLeft: `8%` }}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, marginLeft: `8%` }}>${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`} &#183; {item.address}</p>
                {item.description === '' ? (<div />) : <p style={{ fontFamily: 'Inter', fontWeight: 400, marginLeft: `8%`, marginRight: `8%`, marginBottom: 10 }}>Job Description: {item.description}</p>}

                <div style={{ width: screenSize === 'Large' ? '34vw' : screenSize === 'Medium' ? '42vw' : screenSize === 'Small' ? '60vw' : '68vw', backgroundColor: 'white', marginLeft: `8%`, marginTop: 0, display: 'flex', flexDirection: (item.businessCity === '' && item.businessState === '' && item.businessStreet === '' && item.businessZip === '') ? 'column' : 'row', justifyContent: 'space-between' }}>

                <div style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 12, lineHeight: 1, marginTop: 0, color: 'rgba(0,0,0,.4)', textAlign: 'left'}}>
                  <p>{item.contactName}</p>
                    <p>{item.contactPhone}</p>
                    <p>{item.contactEmail}</p>
                    <p>Employment: {item.fullTime}</p>
                    {item.tips && (<p>Tips</p>)}
                  <br></br>
                  </div>
                </div>
                
                {item.user === firebase.auth().currentUser.uid ? (
                  <div style={{ position: 'absolute', zIndex: 15, backgroundColor: 'white', width: 30, height: 30, top: 240, right: '6%' }} onClick={() => firebase.firestore().collection('projects').doc(item.id).delete().then(() => setRecentlyDeleted(recentlyDeleted + 1))}>
                    <Icon path={mdiDelete} onMouseEnter={() => setIsHoveringClose(true)} onMouseLeave={() => setIsHoveringClose(false)} title='Delete' size={'24px'} style={{ padding: 3, color: 'black', backgroundColor: 'white', cursor: isHoveringClose ? 'pointer' : 'cursor', borderRadius: 24, }} />
                  </div>
                ) :
                  <Icon title="Unsave" path={mdiBookmark} size='26px' style={{ color: item.id === bookmarkHovering ? 'black' : 'rgba(0,0,0,.3)', cursor: item.id === bookmarkHovering ? 'pointer' : 'cursor', position: 'absolute', zIndex: 15, top: 240, right: '6%' }} onMouseEnter={() => setBookmarkHovering(item.id)} onMouseLeave={() => setBookmarkHovering('')} onClick={() => unsave(item.id)}/>
                }

                <p style={{ marginLeft: '8%', fontFamily: 'Inter', fontWeight: 800, color: 'rgba(0,0,0,.13)', marginBottom: 30 }}>Posting #: <span style={{ color: 'rgba(0,0,0,.13)', fontWeight: 600 }}>THW{item.id}</span></p>
                
              </div >
            </div>
            {/* Copied below so that all the postings don't shift when you open one up */}
            
      <div style={{ marginRight: 30 }} onClick={() => setOpenedPosting(item.id)}> 
        {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
          <div style={{  width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center', }} />
        ):
          <div style={{ width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundColor: '#ededed' }} />
        }
        <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0}}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, paddingBottom: 30 }}>{haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) === 'NaN' ? item.contactName : haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) + "mi"} &#183; ${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
     </div >
        </div>
      ) :
          <div style={{ marginRight: 30, position: 'relative', backgroundColor: (isHoveringPost === item.id && sectionHovering === from) ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,0)', cursor: isHoveringPost === item.id ? 'pointer' : 'default', borderRadius: 5 }} onMouseEnter={() => { setIsHoveringPost(item.id); setSectionHovering(from) }} onMouseLeave={() => { setIsHoveringPost(false);   setSectionHovering('')}}
            onClick={() => {setOpenedPosting(item.id); setIsHoveringPost(false);}}> 
            {(item.thumbnail != undefined && item.thumbnail !== null && item.thumnail !== '') ? (
              <div style={{  width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundImage: `url(${item.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center',}} />
        ):
          <div style={{ width: screenSize === 'Large' ? '22vw' : screenSize === 'Medium' ? '34vw' : screenSize === 'Small' ? '32vw' : '76vw', height: 225, borderRadius: 5, backgroundColor: '#ededed' }} />
            }
        <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: 18, marginBottom: 0, paddingBottom: 0}}>{item.jobTitle} {(item.businessName === '' || item.businessName === 'none') ? '' : '-'} {item.businessName}</p>
            <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 16, marginTop: 0, paddingTop: 10, paddingBottom: 30 }}>{haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) === 'NaN' ? item.contactName : haversine({latitude: currentLat, longitude: currentLng }, {latitude: item.lat, longitude: item.lng}, {unit: 'mile'}).toFixed(1) + "mi"} &#183; ${item.hourlyWage === '' ? item.oneTimePayment : `${item.hourlyWage}/hr`}</p>
         </div >
        }
    </div>
    ))}

  if (!loaded) return null
  
  if (loaded && !loggedIn) {
    return <Redirect to='/signup' />
  }

  return (
    <body>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com"/><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
      </head>
      <div style={{ height: '100vh', width: '100vw', paddingLeft: '12%', paddingRight: '12%' }}>
        <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 25 }}>Saved Jobs</p>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          {getSavedPosts('recent')}
        </div>
        
      </div>
      <Footer/>
    </body>
  )
}
