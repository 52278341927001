import React, { useState, useEffect } from 'react'
import aboutUs from '../../images/aboutUs.png'
import firebase from 'firebase'
import 'firebase/firestore'
import Icon from '@mdi/react'
import { mdiChevronDoubleDown } from '@mdi/js';
import { Link } from 'react-router-dom'
import { animateScroll } from 'react-scroll';
import russ from '../../images/russ.png'
import vasili from '../../images/vasili.png'
import Footer from '../layout/Footer'

export default function About() {

  const [outerMargins, setOuterMargins] = useState('12')
  const [isHovering, setIsHovering] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])


  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0
    });
  }, [window.location.pathname]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
    })
  }, [])

  return (
    <body>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com"/><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
      </head>
      <div style={{ width: '100%', height: 450, marginBottom: 0, backgroundImage: `url(${aboutUs})`, backgroundSize: 'cover', position: 'relative', backgroundPosition: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        
        <p style={{ fontFamily: 'Inter', fontWeight: 800, fontSize: 40, color: 'white', marginBottom: 0, textAlign: 'center' }}>Connecting Workers with Work</p>
        <p style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 24, color: 'white', marginTop: 20, textAlign: 'center' }}>Never have an empty shift again.</p>
        <a href="#1">
          <Icon path={mdiChevronDoubleDown} size={'40px'} style={{ color: 'white', position: 'absolute', bottom: '20' }} />
        </a>
      </div>
      <div style={{ width: `${100 - outerMargins*2}%`, marginTop: -20, paddingLeft: `${outerMargins}%`, paddingRight: `${outerMargins}%`, paddingTop: 40, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <a name="1" style={{ height: 0, margin: 0 }}></a>
        <p style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: 20, lineHeight: 1.5, maxWidth: 900, }}>As a high school teacher for over 20 years, I have witnessed countless students who wanted to get a part-time job, but had no easy way to find a suitable position.  Their only option was to travel from business to business to see if there was a “Help Wanted” sign in the window.  It was a tedious and inefficient process that often led to frustration or the acceptance of a position that they would quickly regret and then quit.
          <br></br><br></br>
          Punching Clock was created with the help of one of my former students to solve this fundamental problem.  Here is one location to find all types of jobs, in locations near and far, and can be utilized from the comfort of your own home.  
          <br></br><br></br>
          Although the original design was to help students, it quickly evolved into a useful tool for anyone looking for hourly work.
          <br></br><br></br>
          The process is simple. 
          <br></br><br></br>
          Employers can post a position in less than 5 minutes and reach thousands of potential employees through our user-friendly website and mobile app. 
          <br></br><br></br>
          Employees can easily search for jobs that fit their needs and interests by filtering the type of position, hourly pay, and much more.
          <br></br><br></br>
          It couldn’t be any easier.
          <br></br><br></br>
          Employers, say goodbye to all the lost revenue from empty shifts that weren’t filled.
          <br></br><br></br>
          Employees, welcome to a much better way to find all your job options.
          <br></br><br></br>
          Thank you for joining us and allowing us to be the solution to all your employment needs. 
          <br></br><br></br>
        </p>
        {(screenSize === 'Large' || screenSize == 'Medium') ? (
          <div style={{ display: 'flex', width: screenSize === 'Medium' ? `${100 - outerMargins}%`  : `${100 - outerMargins * 2}%`, maxWidth: 900, justifyContent: screenSize === 'Large' ? 'space-around' : 'space-between', marginBottom: 60 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={russ} style={{ width: 100, height: 100, borderRadius: 200 }} />
              <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 24, margin: 0 }}>Russ Grier</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 20, margin: 0 }}>Founder</p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={vasili} style={{ width: 100, height: 100, borderRadius: 200 }} />
              <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 24, margin: 0 }}>Vasili Karalewich</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 20, margin: 0 }}>Founder</p>
              </div>
            </div>
          </div>
        ) : 

          <div style={{ width: `${100 - outerMargins}%`, marginBottom: 60 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={russ} style={{ width: 100, height: 100, borderRadius: 200 }} />
              <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 24, margin: 0 }}>Russ Grier</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 20, margin: 0 }}>Founder</p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
              <img src={vasili} style={{ width: 100, height: 100, borderRadius: 200 }} />
              <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', fontWeight: 700, fontSize: 24, margin: 0 }}>Vasili Karalewich</p>
                <p style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 20, margin: 0 }}>Founder</p>
              </div>
            </div>
          </div>
        
        }

        
        <Link to={loggedIn ? '/post' : '/signup' }>
          <button onMouseEnter={() => setIsHovering('post a job')} onMouseLeave={() => setIsHovering(false)} style={{ backgroundColor: '#378805', padding: '10px 15px 10px 15px', borderRadius: 100, borderWidth: 0, marginBottom: 50, cursor: isHovering === 'post a job' ? 'pointer' : 'default', alignSelf: 'center'}}>
            <p style={{ color: 'white', fontSize: 20, padding: 0, fontFamily: 'Inter', fontWeight: 500, margin: 0}}>Post a Job</p>
          </button>
        </Link>

      </div>
      <Footer/>
    </body>
  )
}
