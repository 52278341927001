import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import 'firebase/firestore'
import { Redirect } from 'react-router-dom'
import logo from '../../logo.png'
import Icon from '@mdi/react'
import { mdiMenu, mdiChevronDown, mdiMagnify, mdiClose } from '@mdi/js';
import { Link } from 'react-router-dom'
import googleSignIn from '../../images/signin_google_logo.png'
import Footer from '../layout/Footer'


export default function Login() {

  let provider = new firebase.auth.GoogleAuthProvider();

  const [hover, setHover] = useState('')
  const [loggedIn, setLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isHoveringCreateAccount, setIsHoveringCreateAccount] = useState(false)
    const [isHoveringMyAccount, setIsHoveringMyAccount] = useState(false)
  const [isHoveringLogout, setIsHoveringLogout] = useState(false)
    const [isHoveringPay, setIsHoveringPay] = useState(false)
  const [isHoveringWork, setIsHoveringWork] = useState(false)
  const [isHoveringLogo, setIsHoveringLogo] = useState(false)
  const [error, setError] = useState('')
  const [currentScreen, setCurrentScreen] = useState('work')

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
    })
  }, [])

  const handleSubmit = () => {
    console.log('HI')
    firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
      console.log('signedin')
    }).catch(err => {
      setError(err.message)
    })
  }

  useEffect(() => { //without this the button stays greyed out when you correct your error
    setError('')
  }, [email, password])

  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])

  // useEffect(() => {
  //   try {
  //     if (firebase.auth().currentUser.uid) {
  //       setLoggedIn(false)
  //       setLoaded(true)
  //     } else {
  //       setLoggedIn(false)
  //       setLoaded(true)
  //     }
  //   } catch (err) {
  //     setLoggedIn(false)
  //     setLoaded(true)
  //   }
  //   console.log('loggedIn', loggedIn)
  // }, [])

  if (!loaded) return null
  
  if (!loggedIn) {
    return (
      <body style={{ backgroundColor: '#f3f3f3', minHeight: '70vh'}}>
        <head>
          <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet" />
        </head>

              
        
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 40, flexDirection: 'column' }}>
          <p style={{ fontFamily: 'Inter', fontWeight: 800, color: '#378805', fontSize: 28, marginBottom: 0, paddingBottom: 0}}>Login</p>
          <p style={{ fontFamily: 'Inter', fontWeight: 500, color: 'black', marginTop: 20, paddingTop: 0, marginBottom: 30}}>Post jobs or track your work</p>
        <div style={{ backgroundColor: 'white', boxShadow: '2px 2px 9px rgba(0,0,0,.1)', width: screenSize !== 'Extra small' ? 500 : 400, height:340, borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center',  }}>
            <form style={{ marginTop: 15, paddingTop: 0, width: screenSize !== 'Extra small' ? 420 : 320 }}>
          <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 14, margin: '0 0 10px 0'}}>Email</p>
          <input value={email} style={{ height: 40, width: screenSize !== 'Extra small' ? 400 : 300, marginBottom: 20, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="email" id="email" name="email" onChange={text => setEmail(text.target.value)} />
          <p style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 14, margin: '0 0 10px 0'}}>Password</p>
          <input value={password} style={{ height: 40, width: screenSize !== 'Extra small' ? 400 : 300, marginBottom: 15, paddingLeft: 8, border: 0, backgroundColor: '#f8f8f8', fontFamily: 'Inter', fontWeight: 400, fontSize: 20, }} type="password" id="password" name="password" onChange={text => setPassword(text.target.value)} /> <br></br>
              <div style={{ width: screenSize !== 'Extra small' ? 420 : 320, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p style={{ fontFamily: 'Inter', fontWeight: 500, color: 'red', height: 40, margin: 0, padding: 0, textAlign: 'center'}}>{error}</p>
              <input onMouseEnter={() => setIsHoveringCreateAccount(true)} onMouseLeave={() => setIsHoveringCreateAccount(false)} style={{ backgroundColor: ((email === '' || password === '') || error !== '') ? 'grey' : (isHoveringCreateAccount ? 'black' : '#378805'), width: 84, height: 35, borderRadius: 100, borderWidth: 0, marginRight: 10, marginTop: 15, color: 'white', fontFamily: 'Inter', cursor: ((email === '' || password === '') || error !== '') ? 'default' : (isHoveringCreateAccount ? 'pointer' : 'defualt'), fontWeight: 600, fontSize: 16 }} type='button' value='Login' onClick={handleSubmit} />
                 
                <Link to='/signup' style={{ textDecoration: 'none'}}>
                  <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#378805', fontSize: 14, marginTop: (screenSize === 'Extra small' || screenSize === 'Small') ? 40 : 14 }}>Create an account</p>
                </Link>
                
          </div>

            </form>
            
        </div>
          </div>
      <Footer/>
      </body>

  )}

    return (
      <div>
        <Redirect to='/' />
        <p>Logged In</p>
        <p>Hi</p>
        <button onClick={() => firebase.auth().signOut()}><p>Logout</p></button>
      </div>
    )
}
