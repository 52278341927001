import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import 'firebase/firestore'
import { Redirect } from 'react-router-dom'
import logo from '../../logo.png'
import Icon from '@mdi/react'
import { mdiClose, mdiDelete, mdiPencil, mdiCheckBold  } from '@mdi/js';
import { Link } from 'react-router-dom'
import Footer from '../layout/Footer'


export default function Login() {

  const haversine = require('haversine')

  const [loggedIn, setLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState(false)
  const [isHoveringPost, setIsHoveringPost] = useState(false)
  const [posts, setPosts] = useState('')
  const [isHoveringClose, setIsHoveringClose] = useState(false)
  const [openedPosting, setOpenedPosting] = useState('')
  const [outerMargins, setOuterMargins] = useState('12')
  const [recentlyDeleted, setRecentlyDeleted] = useState(0)
  const [isHovering, setIsHovering] = useState('')
  const [accountInformation, setAccountInformation] = useState({})
  const [editName, setEditName] = useState(false)
  const [contactName, setContactName] = useState('Account Name')
  const [showRepostOptions, setShowRepostOptions] = useState(false)
  const [doNotClose, setDoNotClose] = useState(false)
  const [hoveringRepost, setHoveringRepost] = useState('')

  const [currentLat, setCurrentLat] = useState('no-location-permissions')
  const [currentLng, setCurrentLng] = useState('no-location-permissions')

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        setCurrentLat(position.coords.latitude)
        setCurrentLng(position.coords.longitude)
      }, (error) => {
        setCurrentLat('error-latitude')
        setCurrentLng('error-longitude')
      })
    }
  }, [loaded])

  const [screenSize, setScreenSize] = useState('Extra small')
  const checkScreenSize = () => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
    console.log(screenSize)
  }
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }) 
  useEffect(() => {
    if (window.innerWidth < 751) {
      setScreenSize('Extra small')
    } else if (window.innerWidth < 992 && window.innerWidth >= 751) {
      setScreenSize('Small')
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setScreenSize('Medium')
    } else {
      setScreenSize('Large')
    }
  }, [])

  useEffect(() => {
    try {
      if (loaded) {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get().then(doc => {
          setAccountInformation({ ...doc.data(), id: doc.id })
        }).then(() => console.log('ACCOUNT: ', accountInformation))
      }
    } catch (err) {
      console.log(err)
    }
  }, [loaded])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user || user === 'null') { setLoggedIn(false); setLoaded(true); }
      else { setLoggedIn(true); setLoaded(true); }
      console.log('user', user)
    })
  }, [])

  const [topBarShown, setTopBarShown] = useState(false)

  const checkTopBar = () => {
    firebase.firestore().collection('spotlights').doc('topBar').get().then((doc) => {
      if (doc.data().toggle) {
        setTopBarShown(true)
      } else {
        setTopBarShown(false)
      }
    })
  }

  useEffect(() => { checkTopBar() }, [])

  const toggleTopBar = () => {
    firebase.firestore().collection('spotlights').doc('topBar').update({ toggle: !topBarShown }).then(() => {
      window.location.reload();
    })
  }



  // useEffect(() => {
  //   try {
  //     if (firebase.auth().currentUser.uid) {
  //       setLoggedIn(false)
  //       setLoaded(true)
  //     } else {
  //       setLoggedIn(false)
  //       setLoaded(true)
  //     }
  //   } catch (err) {
  //     setLoggedIn(false)
  //     setLoaded(true)
  //   }
  //   console.log('loggedIn', loggedIn)
  // }, [])

  if (!loaded || (accountInformation.id !== 'zULDpJ4K0rSY6wOl7SYhcVCdna03' && accountInformation.id !== 'Wl8py7QTVYXeeZhZtFZnKuN000F2') ) return null
  
  if (!loggedIn) {
    return (
      <div>
        <Redirect to='/' />
        <p>Not logged in</p>

      </div>
    )
  }

    return ( 
      <div>
              <head>
                  <link rel="preconnect" href="https://fonts.googleapis.com"/><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap" rel="stylesheet"/>
      </head>
        
        {/* Page Content: */}
        <div style={{ marginLeft: `${outerMargins}%`, marginRight: `${outerMargins}%`, display: 'flex', flexDirection: 'column' }}>
          <p>Admin Page</p>
          <button onClick={() => toggleTopBar()}><p>{topBarShown ? 'Turn off' : 'Turn on'} Top Bar</p></button>
        </div>
      
       <Footer/>
            </div>
    )
}
