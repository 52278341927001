  import firebase from "firebase/app";
  import 'firebase/firestore'
import 'firebase/auth'
  import 'firebase/storage'
  
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyAraIqLvh5QZON36THpgJuMT77OogUTMys",
    authDomain: "thehourlyworkerdatabase.firebaseapp.com",
    projectId: "thehourlyworkerdatabase",
    storageBucket: "thehourlyworkerdatabase.appspot.com",
    messagingSenderId: "652100621190",
    appId: "1:652100621190:web:72d721948ceb856ee11207"
  };

  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  const storage = firebase.storage()
  firebase.firestore().settings({ timestampsInSnapshots: true })


export  {
   storage, firebase as default
 }